import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Navigate } from 'react-router-dom';
import loginActionCreator, { resetLoginErrorAction } from '../../redux/actions/login';
import { setLoginScreenAction, resetUIAction } from '../../redux/actions/ui';
import LoginView from './LoginView';
import MfaLogin from './MfaLogin';

const mapStateToProps = (state) => {
  const {
    login,
    user: { jwt, partialLoginToken, forcedLogout, userAuthLevel },
  } = state;
  const loggedIn = jwt !== undefined;
  return {
    isLoading: login.isFetching,
    loggedIn,
    mfaRequired: partialLoginToken !== undefined,
    isError: login.errMessage !== undefined,
    isNetworkError: !!login.networkError,
    forcedLogout,
    userAuthLevel,
  };
};

const mapDispatchToProps = (dispatch) => ({
  resetUI: () => dispatch(resetUIAction()),
  submitLogin: (email, pw, remember) => dispatch(loginActionCreator(email, pw, remember)),
  resetError: () => dispatch(resetLoginErrorAction()),
  setLoginScreen: (isLoginScreen) => dispatch(setLoginScreenAction(isLoginScreen)),
});

class LoginContainer extends PureComponent {
  state = {
    email: '',
    password: '',
    remember: true,
  };

  componentDidMount() {
    this.props.resetUI();
    this.props.setLoginScreen(true);
  }

  componentWillUnmount() {
    this.props.resetError();
    this.props.setLoginScreen(false);
  }

  onLoginFieldChange = (e) => {
    const {
      target: { name, value },
    } = e;
    this.setState({ [name]: value });
  };

  onRememberChange = (e) => this.setState({ remember: e.target.checked });

  onEnter = (e) => {
    const {
      key,
      target: { name, value },
    } = e;
    if (key === 'Enter') {
      e.preventDefault();
      this.setState({ [name]: value }, () => this.handleLoginClick(e));
    }
  };

  handleLoginClick = (e) => {
    e.preventDefault();
    const { email, password, remember } = this.state;
    if (email === '' || password === '') return;
    this.props.submitLogin(email, password, remember);
  };

  render() {
    if (this.props.loggedIn) {
      return <Navigate to={this.props.successRedirect} replace />;
    }
    if (this.props.mfaRequired) {
      return <MfaLogin />;
    }
    return (
      <LoginView
        onLoginFieldChange={this.onLoginFieldChange}
        remember={this.state.remember}
        onRememberChange={this.onRememberChange}
        onLoginSubmit={this.handleLoginClick}
        onEnter={this.onEnter}
        isLoading={this.props.isLoading}
        showError={this.props.isError}
        networkError={this.props.isNetworkError}
        forcedLogout={this.props.forcedLogout}
      />
    );
  }
}

LoginContainer.propTypes = {
  submitLogin: PropTypes.func.isRequired,
  resetError: PropTypes.func.isRequired,
  loggedIn: PropTypes.bool.isRequired,
  isLoading: PropTypes.bool.isRequired,
  isError: PropTypes.bool.isRequired,
  setLoginScreen: PropTypes.func.isRequired,
  isNetworkError: PropTypes.bool.isRequired,
  mfaRequired: PropTypes.bool.isRequired,
  successRedirect: PropTypes.string,
};

LoginContainer.defaultProps = {
  successRedirect: '/dashboard',
};

export default connect(mapStateToProps, mapDispatchToProps)(LoginContainer);
