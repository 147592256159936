import React from 'react';
import { connect } from 'react-redux';
import { Typography } from '@mui/material';
import { compareByNumericProp } from '../../../utils/comparators';
import { parseStringToInt } from '../../../utils/parsing';
import { getSelected } from '../ClassNavigation';
import { PointTypesViewerUnconnected } from './attrs/PointTypesViewer';

const PointList = ({ queryParams, base, pointTypes }) => {
  const tags = getSelected(queryParams.tag);
  const measurements = getSelected(queryParams.measurement)
    .map(parseStringToInt)
    .filter((m) => m != null);
  const filteredPts = pointTypes
    // multiple tags narrow the filtering
    .filter((pt) => tags.filter((t) => pt.tags.indexOf(t) !== -1).length === tags.length)
    // multiple measurements widen the filtering
    .filter((pt) => measurements.length === 0 || measurements.indexOf(pt.measurementId) !== -1);
  return (
    <PointTypesViewerUnconnected
      base={base}
      pointTypes={filteredPts}
      emptyComponent={
        <Typography variant="h4">No point types match the selected set of tags</Typography>
      }
    />
  );
};

export default connect(
  ({
    entities: {
      pointTypes: { byId },
    },
  }) => {
    const pointTypes = Object.values(byId);
    pointTypes.sort(compareByNumericProp('name'));
    return {
      pointTypes,
    };
  }
)(PointList);
