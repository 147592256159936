import React from 'react';
import { connect } from 'react-redux';
import { Typography, Button, TextField } from '@mui/material';
import { createTotpActionCreator, confirmTotpActionCreator } from '../../redux/actions/mfa';
import TotpField from '../login/TotpField';
import SecretDisplay from './SecretDisplay';

const INITIAL_STATE = {
  creating: false,
  registerUri: undefined,
  name: '',
  errorMessage: '',
};

/**
 * Component for registering and confirming a new time-based one-time password device
 * like Google Authenticator or Authy
 */
class TotpCreator extends React.PureComponent {
  state = {
    ...INITIAL_STATE,
  };

  reset = () => {
    this.setState(INITIAL_STATE);
  };

  create = () =>
    this.props
      .createTotp(this.state.name)
      .then((res) => {
        if (res.uri) {
          this.setState({ registerUri: res.uri, errorMessage: '' });
        } else {
          this.setState({ errorMessage: 'Something went wrong, please try again later' });
        }
      })
      .catch((e) => this.setState({ errorMessage: `${e}` }));

  confirm = (pw) =>
    this.props
      .confirmTotp(this.state.name, pw)
      .then(() => this.reset())
      .catch((e) => this.setState({ errorMessage: `${e}` }));

  renderError() {
    const { errorMessage } = this.state;
    return errorMessage ? (
      <Typography color="error" variant="h6">
        {errorMessage}
      </Typography>
    ) : null;
  }

  render() {
    const { creating, registerUri, name } = this.state;
    if (!creating) {
      return (
        <Button
          variant="contained"
          color="primary"
          onClick={() => this.setState({ creating: true })}
        >
          Configure a new authenticator app
        </Button>
      );
    }
    if (registerUri) {
      return (
        <div>
          <SecretDisplay secretUri={registerUri} />
          <br />
          <Typography variant="h6">And enter a code generated by your new device</Typography>
          <TotpField onSubmit={this.confirm} />
          {this.renderError()}
        </div>
      );
    }
    return (
      <div>
        <Typography variant="h6">Give your new device a name</Typography>
        <TextField
          value={name}
          label="device name"
          onChange={(e) => this.setState({ name: e.target.value })}
          style={{ width: '256px' }}
        />
        <br />
        <br />
        <Button variant="contained" color="primary" onClick={this.create}>
          Create device
        </Button>
        {this.renderError()}
      </div>
    );
  }
}

export default connect(null, (dispatch) => ({
  createTotp: (name) => dispatch(createTotpActionCreator(name)),
  confirmTotp: (name, password) => dispatch(confirmTotpActionCreator(name, password)),
}))(TotpCreator);
