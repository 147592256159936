import React from 'react';
import { connect } from 'react-redux';
import Selector from '../util/Selector';

import './scope-selector.css';

const ScopeSelector = ({ scopes, selected, onChange, disabled }) => (
  <Selector
    className="ob-selector scopes"
    options={scopes.map((scope) => ({
      id: scope,
      key: `${scope}`,
      display: `${scope}`,
    }))}
    selected={selected}
    onChange={onChange}
    multiple
    disabled={disabled}
  />
);

export default connect(({ apiKeys: { scopes } }) => ({ scopes }))(ScopeSelector);
