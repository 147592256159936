import {
  UPDATE_USER_INFO,
  CLEAR_USER_INFO,
  SET_FORCED_LOGOUT,
  PARTIAL_LOGIN,
  UPDATE_MFA,
} from '../actions/actiontypes';

const DEFAULT_INFO = {
  jwt: undefined,
  jwtIssuedAt: undefined,
  partialLoginToken: undefined,
  userID: undefined,
  userAuthLevel: 10000,
  userName: undefined,
  userFullName: undefined,
  orgShortName: undefined,
  orgLogoUrl: undefined,
  orgId: undefined,
  mfaRequired: false,
  mfa: {
    totp: [],
    fido2: [],
  },
  mfaLoaded: {
    totp: false,
    fido2: false,
  },
};

// A reducer function for the user 'slice' of the tree
export default function user(
  state = {
    ...DEFAULT_INFO,
    forcedLogout: false,
  },
  action
) {
  switch (action.type) {
    case SET_FORCED_LOGOUT:
      return { ...state, forcedLogout: action.forcedLogout };
    // multi-factor auth required to complete login
    case PARTIAL_LOGIN:
      return { ...state, partialLoginToken: action.partialLoginToken };
    // full login completed
    case UPDATE_USER_INFO:
      return {
        ...state,
        ...action,
        partialLoginToken: undefined,
      };
    case CLEAR_USER_INFO:
      return { ...state, ...DEFAULT_INFO };
    case UPDATE_MFA:
      return {
        ...state,
        mfa: {
          ...state.mfa,
          [action.mfaType]: action.devices,
        },
        mfaLoaded: {
          ...state.mfaLoaded,
          [action.mfaType]: true,
        },
      };
    default:
      return state;
  }
}
