import { logError } from '../api/Api';

const getGAID = () => {
  switch (window.location.host) {
    case 'portal.onboarddata.io':
      return 'UA-129333612-1';
    case 'devportal.onboarddata.io':
      return 'UA-129333612-2';
    case 'ny-rtem.com':
      return 'UA-129333612-3';
    default: {
      logError(`'${window.location.host}' has no Google Analytics ID`);
      return '';
    }
  }
};

export default getGAID;
