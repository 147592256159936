import React from 'react';
import { connect } from 'react-redux';
import { Grid, Typography } from '@mui/material';
import AttrGrid from './Grid';

const MeasurementUnitsViewer = ({ defaultUnit, measurementUnits, convertible }) => (
  <AttrGrid>
    <Grid item xs={5}>
      <Typography variant="h6">Units:</Typography>
    </Grid>
    <Grid item xs={5}>
      <Typography variant="h6">Data type:</Typography>
    </Grid>

    {defaultUnit ? (
      <>
        <Grid item xs={5}>
          Default: {defaultUnit.name_long} ({defaultUnit.name_abbr})
        </Grid>
        <Grid item xs={5}>
          {defaultUnit.data_type}
        </Grid>
      </>
    ) : null}

    {measurementUnits
      .filter((u) => defaultUnit == null || u.id !== defaultUnit.id)
      .map((unit) => (
        <React.Fragment key={unit.id}>
          <Grid item xs={5}>
            {unit.name_long} ({unit.name_abbr})
          </Grid>
          <Grid item xs={5}>
            {unit.data_type}
          </Grid>
        </React.Fragment>
      ))}

    {!convertible && (
      <Grid>
        <p>Note: data for this measurement is not converted to the default unit.</p>
      </Grid>
    )}
  </AttrGrid>
);

export default connect(
  (
    {
      entities: {
        units: { byId: unitsById },
        measurements: { byId: mById },
      },
    },
    { defaultId, measurementId }
  ) => ({
    defaultUnit: unitsById[defaultId],
    measurementUnits: mById[measurementId]?.units || [],
    convertible: mById[measurementId]?.units_convertible,
  })
)(MeasurementUnitsViewer);
