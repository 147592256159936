import React from 'react';
import PropTypes from 'prop-types';
import { Button } from '@mui/material';

const styles = {
  uploadButton: {
    verticalAlign: 'middle',
  },
  uploadInput: {
    cursor: 'pointer',
    position: 'absolute',
    top: 0,
    bottom: 0,
    right: 0,
    left: 0,
    width: '100%',
    opacity: 0,
  },
};

const FilePicker = ({ buttonText, disabled, onFileSelection, selectedFileName, startIcon }) => (
  <div>
    <span>
      <Button
        disabled={disabled}
        startIcon={startIcon}
        color="primary"
        variant="contained"
        style={styles.uploadButton}
      >
        {buttonText}
        <input
          disabled={disabled}
          type="file"
          style={styles.uploadInput}
          onChange={onFileSelection}
        />
      </Button>
      {selectedFileName}
    </span>
  </div>
);

FilePicker.propTypes = {
  disabled: PropTypes.bool,
  buttonText: PropTypes.string,
  selectedFileName: PropTypes.string.isRequired,
  onFileSelection: PropTypes.func.isRequired,
};

FilePicker.defaultProps = {
  disabled: false,
  buttonText: 'Choose File',
};

export default FilePicker;
