import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Button, Typography } from '@mui/material';

import { AUTH_LEVELS } from '../auth/Authorized';
import UserEdit from './UserEdit';
import RoleEdit from './roles/RoleEdit';
import OrgEdit from './orgs/OrgEdit';

import { resetEditorAction, updateEditorAction, saveEditorAction } from '../../redux/actions/users';

import './editor.css';

const EditorSwitch = connect(({ user: { userAuthLevel } }) => ({ userAuthLevel }))(
  ({ editType, onChange, userAuthLevel }) => {
    const variant = userAuthLevel === AUTH_LEVELS.SUPER_ADMIN ? 'ADMIN' : 'ORG';
    switch (editType) {
      case 'USER':
        return <UserEdit variant={variant} onChange={onChange} />;
      case 'ROLE':
        return <RoleEdit onChange={onChange} />;
      case 'ORG':
        return <OrgEdit onChange={onChange} />;
      case 'NONE':
      default:
        return <span>Select an item to view details and edit</span>;
    }
  }
);

const Editor = ({ editType, id, resetEditor, update, save, error }) => (
  <div className="mgmt-editor">
    <div className="ed-body">
      <EditorSwitch editType={editType} onChange={(data) => update({ editType, id, data })} />
    </div>
    <div className="validation-error">
      <Typography className="msg">
        {`${error}`.split('\\n').map((l) => (
          <React.Fragment key={l}>
            {l}
            <br />
          </React.Fragment>
        ))}
      </Typography>
    </div>
    <div className="actions">
      {editType !== 'NONE' ? (
        <>
          <Button color="secondary" variant="contained" onClick={() => resetEditor()}>
            Cancel
          </Button>
          <Button color="primary" variant="contained" onClick={save}>
            Save
          </Button>
        </>
      ) : null}
    </div>
  </div>
);

Editor.propTypes = {
  editType: PropTypes.string.isRequired,
  id: PropTypes.number,
  error: PropTypes.string.isRequired,
  resetEditor: PropTypes.func.isRequired,
  save: PropTypes.func.isRequired,
  update: PropTypes.func.isRequired,
};

Editor.defaultProps = {
  id: null,
};

export default connect(
  ({ users: { editing } }) => ({ ...editing }),
  (dispatch) => ({
    resetEditor: () => dispatch(resetEditorAction()),
    update: ({ editType, id, data }) => dispatch(updateEditorAction(editType, id, data)),
    save: () => dispatch(saveEditorAction()),
  })
)(Editor);
