import React from 'react';
import { connect } from 'react-redux';
import { Typography, Button } from '@mui/material';
import {
  startFido2SetupActionCreator,
  completeFido2SetupActionCreator,
} from '../../redux/actions/mfa';
import {
  transformCredentialCreateOptions,
  transformNewAssertionForServer,
  createPublicKey,
} from './fido2';

const INITIAL_STATE = {
  creating: false,
  challengeData: undefined,
  loading: false,
  errorMessage: '',
};

/**
 * Component for registering and confirming a new FIDO2 device like a yubikey
 */
class Fido2Creator extends React.PureComponent {
  state = {
    ...INITIAL_STATE,
  };

  reset = () => {
    this.setState(INITIAL_STATE);
  };

  create = () => {
    this.setState({ creating: true, loading: true });
    this.props
      .startSetup()
      .then(transformCredentialCreateOptions)
      .then(createPublicKey)
      .then(transformNewAssertionForServer)
      .then(this.props.completeSetup)
      .then(this.reset)
      .catch((e) => this.setState({ errorMessage: `${e}`, loading: false }));
  };

  renderError() {
    const { errorMessage } = this.state;
    return errorMessage ? (
      <Typography color="error" variant="h6">
        {errorMessage}
      </Typography>
    ) : null;
  }

  render() {
    const { creating, loading } = this.state;
    if (!creating) {
      return (
        <>
          <Button variant="contained" color="primary" onClick={this.create}>
            Configure a new FIDO2 device
          </Button>
          {this.renderError()}
        </>
      );
    }
    if (loading) {
      return <div>Loading...</div>;
    }
    return this.renderError();
  }
}

export default connect(null, (dispatch) => ({
  startSetup: () => dispatch(startFido2SetupActionCreator()),
  completeSetup: (data) => dispatch(completeFido2SetupActionCreator(data)),
}))(Fido2Creator);
