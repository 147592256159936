import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { TextField } from '@mui/material';

import './site-creator.css';

const YEARS_MIN = 1;
const YEARS_MAX = 5;

/**
 * Hook that provides site editor state and checks validity
 */
export const useSiteEdit = ({ currentFolder = '', currentYears = 1 } = {}) => {
  const [folder, setFolder] = useState(currentFolder);
  const [years, setYears] = useState(currentYears);
  const yearsValid = years >= YEARS_MIN && years <= YEARS_MAX;
  const valid = folder && yearsValid;
  return {
    folder,
    setFolder,
    years,
    setYears,
    minYears: Math.max(YEARS_MIN, currentYears),
    reset: () => {
      setFolder(currentFolder);
      setYears(currentYears);
    },
    valid: { yearsValid, valid },
  };
};

/**
 * Component for modifying the site fields exposed by the 'useSiteEdit' hook
 */
const SiteEditFields = ({ siteEdit }) => {
  const {
    folder,
    setFolder,
    minYears,
    years,
    setYears,
    valid: { yearsValid },
  } = siteEdit;
  return (
    <>
      <TextField
        className="spaced-field"
        label="Site Folder"
        name="site-folder"
        helperText="Entire site folder"
        value={folder}
        onChange={(e) => setFolder(e.target.value)}
      />
      <TextField
        className="years-field"
        type="number"
        label="Service Years"
        name="years"
        helperText="One to five years"
        value={years}
        onChange={(e) => setYears(e.target.value)}
        inputProps={{
          min: minYears,
          max: YEARS_MAX,
        }}
        error={!yearsValid}
      />
    </>
  );
};

SiteEditFields.propTypes = {
  siteEdit: PropTypes.shape({}).isRequired,
};

export default SiteEditFields;
