import React from 'react';
import { connect } from 'react-redux';
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Button,
  IconButton,
} from '@mui/material';
import { Edit, Add } from '@mui/icons-material';

import { beginEditingUser, beginNewUser } from '../../../redux/actions/users';

import '../users-table.css';
import './users-table.css';

const NyserdaUsersTable = ({ userData, beginEditing, newUser, editingId, editType, scrollTop }) => (
  <div className="user-table">
    <div className="new-user">
      <Button variant="contained" color="primary" onClick={() => newUser()}>
        <Add />
        Create new User
      </Button>
    </div>
    <Table>
      <TableHead>
        <TableRow>
          <TableCell>Edit</TableCell>
          <TableCell>Email</TableCell>
          <TableCell>Name</TableCell>
          <TableCell>Affiliation</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {userData.map((user) => (
          <TableRow
            className={`user-row ${!user.active ? 'inactive' : ''}`}
            key={user.id}
            hover
            selected={editType === 'USER' && editingId === user.id}
            classes={{ selected: 'selected' }}
          >
            <TableCell>
              <IconButton
                onClick={() => {
                  scrollTop();
                  beginEditing(user.id);
                }}
                size="large"
              >
                <Edit />
              </IconButton>
            </TableCell>
            <TableCell>
              <span className="email">{user.email}</span>
            </TableCell>
            <TableCell>
              {user.first_name} {user.last_name}
            </TableCell>
            <TableCell>{user.attrs?.affiliation}</TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  </div>
);

export default connect(
  ({
    users: {
      users,
      editing: { editType, id },
    },
  }) => ({
    userData: users?.filter((u) => u.org_name === 'RTEM Submission') || [],
    editType,
    editingId: id,
  }),
  (dispatch) => ({
    beginEditing: (id) => dispatch(beginEditingUser(id)),
    newUser: () => dispatch(beginNewUser()),
  })
)(NyserdaUsersTable);
