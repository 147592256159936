import React from 'react';
import { logError } from '../../api/Api';

/**
 * Catches and handles any errors during rendering, logs errors up to
 * the API so we can keep tabs on them
 *
 * https://reactjs.org/docs/error-boundaries.html
 */
class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false, lastError: null };
  }

  // Update state so the next render will show the fallback UI.
  static getDerivedStateFromError(error) {
    return { hasError: true, lastError: error };
  }

  componentDidCatch(error, errorInfo) {
    const { name, message, stack } = error;
    const { componentStack } = errorInfo;
    const agent = window.navigator.userAgent;
    const page = window.location.href;
    const hash = process.env.REACT_APP_GIT_HEAD;
    const errorFields = {
      name,
      message,
      stack,
      componentStack,
      agent,
      page,
      hash,
    };
    try {
      logError(errorFields);
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error(`Exploded recording an error: ${e}`);
    }
  }

  render() {
    const { hasError, lastError } = this.state;
    if (hasError) {
      return (
        <div style={{ margin: '50px' }}>
          <div style={{ textAlign: 'center' }}>
            <h1>Ack, something went wrong!</h1>
            <p>We&apos;ve already been notified. Please reload the page and try again</p>
            <p style={{ color: 'red' }}>
              {lastError.name} - {lastError.message}
            </p>
          </div>
          <div>
            <p style={{ color: 'red' }}>
              {lastError.stack.split('\n').map((l, i) => (
                // eslint-disable-next-line react/no-array-index-key
                <React.Fragment key={`${l}-${i}`}>
                  {l}
                  <br />
                </React.Fragment>
              ))}
            </p>
          </div>
        </div>
      );
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
