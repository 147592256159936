import { createTheme, alpha } from '@mui/material/styles';
import { grey } from '@mui/material/colors';
import { DEFAULT_PALETTE } from './redux/reducers/ui';

const getTheme = (palette) => {
  const theme = createTheme({
    palette: {
      ...palette,
      grey: {
        main: grey[300],
        dark: grey[400],
      },
    },
  });
  return createTheme(theme, {
    components: {
      // from https://codesandbox.io/s/mimic-v4-button-default-color-bklx8?file=/src/Demo.tsx:488-621
      MuiButton: {
        defaultProps: {
          color: 'grey',
        },
        variants: [
          {
            props: { variant: 'contained', color: 'grey' },
            style: {
              color: theme.palette.getContrastText(theme.palette.grey[300]),
            },
          },
          {
            props: { variant: 'outlined', color: 'grey' },
            style: {
              color: theme.palette.text.primary,
              borderColor:
                theme.palette.mode === 'light'
                  ? 'rgba(0, 0, 0, 0.23)'
                  : 'rgba(255, 255, 255, 0.23)',
              '&.Mui-disabled': {
                border: `1px solid ${theme.palette.action.disabledBackground}`,
              },
              '&:hover': {
                borderColor:
                  theme.palette.mode === 'light'
                    ? 'rgba(0, 0, 0, 0.23)'
                    : 'rgba(255, 255, 255, 0.23)',
                backgroundColor: alpha(
                  theme.palette.text.primary,
                  theme.palette.action.hoverOpacity
                ),
              },
            },
          },
          {
            props: { color: 'grey', variant: 'text' },
            style: {
              color: theme.palette.text.primary,
              '&:hover': {
                backgroundColor: alpha(
                  theme.palette.text.primary,
                  theme.palette.action.hoverOpacity
                ),
              },
            },
          },
        ],
      },
      MuiCheckbox: {
        defaultProps: {
          color: 'secondary',
        },
      },
      MuiRadio: {
        defaultProps: {
          color: 'secondary',
        },
      },
      MuiSelect: {
        defaultProps: {
          variant: 'standard',
        },
      },
      MuiTableCell: {
        root: {
          padding: '4px 20px 4px 20px',
        },
      },
      MuiTextField: {
        defaultProps: {
          variant: 'standard',
        },
      },
      MuiCssBaseline: {
        styleOverrides: {
          body: {
            fontSize: '0.875rem',
            lineHeight: 1.43,
            letterSpacing: '0.01071em',
          },
        },
      },
    },
    palette,
  });
};

export const DEFAULT_THEME = getTheme(DEFAULT_PALETTE);

export default getTheme;
