import React from 'react';
import { Grid, Typography } from '@mui/material';
import { Link } from 'react-router-dom';

const Child = ({ base }) => (
  <div className="leaf parent">
    <Typography variant="h5" className="name">
      Child Equipment
    </Typography>
    <Grid item xs={5}>
      <Typography variant="h6">Value:</Typography>
    </Grid>
    <Grid item xs={5} className="static-attr">
      List of child equipment ids
    </Grid>

    <Grid item xs={2}>
      <Typography variant="h6">Description:</Typography>
    </Grid>
    <Grid item xs={8} className="static-attr">
      <p>
        This attribute is the inverse of a{' '}
        <Link to={`${base}/relationships/parent`}>Parent Equipment</Link>.
      </p>
    </Grid>
  </div>
);

export default Child;
