import { SET_ALERTS, REPLACE_ALERT } from '../actions/actiontypes';
import { alphabetical } from '../../utils/collections';

const replaceInList = (val, list) => {
  const newList = list.slice();
  for (let i = 0; i < list.length; i += 1) {
    const ele = list[i];
    if (val.key === ele.key && val.timestamp === ele.timestamp) {
      newList[i] = val;
    }
  }
  return newList;
};

const getLatestOnly = (list) => {
  const latest = {};
  list.forEach((a) => {
    if (!latest[a.key] || a.timestamp > latest[a.key].timestamp) latest[a.key] = a;
  });
  const latestAlerts = Object.values(latest);
  return alphabetical('timestamp', latestAlerts).reverse();
};

export default function alerts(
  state = {
    all: [],
    latestOnly: [],
  },
  action
) {
  switch (action.type) {
    case SET_ALERTS:
      return {
        all: action.data,
        latestOnly: getLatestOnly(action.data),
      };
    case REPLACE_ALERT:
      return {
        all: replaceInList(action.data, state.all),
        latestOnly: replaceInList(action.data, state.latestOnly),
      };
    default:
      return state;
  }
}
