import { combineReducers } from 'redux';
import { SET_UNIT_PREFS, SET_PAGE_UNIT_PREF } from '../actions/actiontypes';

const page = (state = [], action) => {
  switch (action.type) {
    case SET_PAGE_UNIT_PREF:
      return action.data;
    default:
      return state;
  }
};

const serverUnitPref =
  (field) =>
  (state = [], action) => {
    switch (action.type) {
      case SET_UNIT_PREFS:
        return action.data[field];
      default:
        return state;
    }
  };

const loaded = (state = false, action) => {
  switch (action.type) {
    case SET_UNIT_PREFS:
      return true;
    default:
      return state;
  }
};

const org = serverUnitPref('org');
const user = serverUnitPref('user');

const unitPrefsReducer = combineReducers({
  page,
  org,
  user,
  loaded,
});

export default unitPrefsReducer;
