import base64js from 'base64-js';

/**
 * FIDO2 implementation glue code
 *
 * Adapted from https://github.com/duo-labs/py_webauthn/blob/master/flask_demo/static/js/webauthn.js
 */

const b64enc = (buf) =>
  base64js.fromByteArray(buf).replace(/\+/g, '-').replace(/\//g, '_').replace(/=/g, '');

const b64RawEnc = (buf) => base64js.fromByteArray(buf).replace(/\+/g, '-').replace(/\//g, '_');

const hexEncode = (buf) =>
  Array.from(buf)
    .map((x) => `0${x.toString(16)}`.substr(-2))
    .join('');

const strToBytes = (str) => Uint8Array.from(atob(str), (c) => c.charCodeAt(0));

/**
 * Format the JS object returned by the server so we can hand it off to `navigator.credentials.get()`
 */
export const transformCredentialRequestOptions = (credentialRequestOptionsFromServer) => {
  const { challenge, allowCredentials } = credentialRequestOptionsFromServer;

  const transformedAllowCredentials = allowCredentials.map((credentialDescriptor) => {
    let { id } = credentialDescriptor;
    id = id.replace(/_/g, '/').replace(/-/g, '+');
    id = Uint8Array.from(atob(id), (c) => c.charCodeAt(0));
    return { ...credentialDescriptor, id };
  });

  return {
    ...credentialRequestOptionsFromServer,
    challenge: strToBytes(challenge),
    allowCredentials: transformedAllowCredentials,
  };
};

/**
 * Transforms items in the credentialCreateOptions generated on the server
 * into byte arrays expected by the `navigator.credentials.create()` call
 */
export const transformCredentialCreateOptions = (credentialCreateOptions) => {
  const { user, challenge } = credentialCreateOptions;
  return {
    ...credentialCreateOptions,
    challenge: strToBytes(challenge),
    user: {
      ...user,
      id: strToBytes(user.id),
    },
  };
};

/**
 * Transforms the binary data in the credential into base64 strings
 * for posting to the server.
 * @param {PublicKeyCredential} newAssertion
 */
export const transformNewAssertionForServer = (newAssertion) => {
  const attObj = new Uint8Array(newAssertion.response.attestationObject);
  const clientDataJSON = new Uint8Array(newAssertion.response.clientDataJSON);
  const rawId = new Uint8Array(newAssertion.rawId);

  const registrationClientExtensions = newAssertion.getClientExtensionResults();

  return {
    id: newAssertion.id,
    rawId: b64enc(rawId),
    type: newAssertion.type,
    attObj: b64enc(attObj),
    clientData: b64enc(clientDataJSON),
    registrationClientExtensions: JSON.stringify(registrationClientExtensions),
  };
};

/**
 * Encodes the binary data in the assertion into strings for posting to the server.
 * @param {PublicKeyCredential} newAssertion
 */
export const transformAssertionForServer = (newAssertion) => {
  const authData = new Uint8Array(newAssertion.response.authenticatorData);
  const clientDataJSON = new Uint8Array(newAssertion.response.clientDataJSON);
  const rawId = new Uint8Array(newAssertion.rawId);
  const sig = new Uint8Array(newAssertion.response.signature);
  const assertionClientExtensions = newAssertion.getClientExtensionResults();

  return {
    id: newAssertion.id,
    rawId: b64enc(rawId),
    type: newAssertion.type,
    authData: b64RawEnc(authData),
    clientData: b64RawEnc(clientDataJSON),
    signature: hexEncode(sig),
    assertionClientExtensions: JSON.stringify(assertionClientExtensions),
  };
};

/**
 * Returns a future which resolves to the credentials package
 */
export const createPublicKey = (publicKey) =>
  navigator.credentials.create({
    publicKey,
  });

/**
 * Returns a future which resolves to the credentials package
 */
export const getPublicKey = (publicKey) =>
  navigator.credentials.get({
    publicKey,
  });
