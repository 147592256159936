export const LOGIN_POST_REQUEST = 'LOGIN_POST_REQUEST';
export const LOGIN_POST_SUCCESS = 'LOGIN_POST_SUCCESS';
export const LOGIN_POST_ERROR = 'LOGIN_POST_ERROR';
export const LOGIN_RESET_ERROR = 'LOGIN_RESET_ERROR';

export const UPDATE_USER_INFO = 'UPDATE_USER_INFO';
export const CLEAR_USER_INFO = 'CLEAR_USER_INFO';
export const SET_FORCED_LOGOUT = 'SET_FORCED_LOGOUT';
export const PARTIAL_LOGIN = 'PARTIAL_LOGIN';
export const UPDATE_MFA = 'UPDATE_MFA';

export const ADD_BUILDINGS = 'ADD_BUILDINGS';
export const INVALIDATE_BUILDINGS = 'INVALIDATE_BUILDGINGS';
export const INVALIDATE_BUILDING = 'INVALIDATE_BUILDING';
export const DELETE_ALL_BUILDINGS = 'DELETE_ALL_BUILDINGS';
export const START_BUILDINGS_LOAD = 'START_BUILDINGS_LOAD';

export const ADD_MULTIPLE_EQUIPMENT_AND_POINTS = 'ADD_MULTIPLE_EQUIPMENT_AND_POINTS';
export const DELETE_ALL_EQUIPMENT = 'DELETE_ALL_EQUIPMENT';

export const ADD_MULTIPLE_POINTS = 'ADD_MULTIPLE_POINTS';
export const DELETE_ALL_POINTS = 'DELETE_ALL_POINTS';
export const ADD_POINT_UNITS = 'ADD_POINT_UNITS';

// ui
export const RESET_UI = 'RESET_UI';
export const SET_LOGIN_SCREEN = 'SET_LOGIN_SCREEN';

export const SET_BUILDING_IS_LOADING = 'SET_BUILDING_IS_LOADING';
export const SET_BUILDING_NOT_LOADING = 'SET_BUILDING_NOT_LOADING';
export const SET_UPLOAD_STATE = 'SET_UPLOAD_STATE';
export const SET_BACK_BUTTON_LABEL = 'SET_BACK_BUTTON_LABEL';
export const SET_BACK_BUTTON_LINK = 'SET_BACK_BUTTON_LINK';
export const SET_LOC_DISPLAY_OPTION = 'SET_LOC_DISPLAY_OPTION';
export const SET_RESULTS_VIEW_TYPE = 'SET_RESULTS_VIEW_TYPE';
export const SET_EQUIP_DISPLAY_OPTION = 'SET_EQUIP_DISPLAY_OPTION';

export const SET_FEATURES = 'SET_FEATURES';

// equiptypes
export const ADD_MULTIPLE_EQUIP_TYPES = 'ADD_MULTIPLE_EQUIP_TYPES';

// pointtypes
export const ADD_MULTIPLE_POINT_TYPES = 'ADD_MULTIPLE_POINT_TYPES';

// tags
export const ADD_MULTIPLE_TAGS = 'ADD_MULTIPLE_TAGS';

// timeseries
export const SET_SERIES = 'SET_SERIES';

// attachments
export const INVALIDATE_ATTACHMENTS = 'INVALIDATE_ATTACHMENTS';
export const SET_BUILDING_ATTACHMENTS = 'SET_BUILDING_ATTACHMENTS';

// user management

export const SET_USERS = 'SET_USERS';
export const SET_ROLES = 'SET_ROLES';
export const SET_ROLE_TYPES = 'SET_ROLE_TYPES';
export const SET_ORGS = 'SET_ORGS';

export const SET_EDIT = 'SET_EDIT';
export const RESET_EDIT = 'RESET_EDIT';
export const SET_EDITOR_ERROR = 'SET_EDITOR_ERROR';

// units

export const SET_UNITS = 'SET_UNITS';

export const SET_SCOPES = 'SET_SCOPES';
export const SET_USER_KEYS = 'SET_USER_KEYS';
export const SET_API_VERSIONS = 'SET_API_VERSIONS';

export const SET_BUILDING_INGEST_STATS = 'SET_BUILDING_INGEST_STATS';
export const SET_POINT_INGEST_MESSAGES = 'SET_POINT_INGEST_MESSAGES';
export const SET_POINT_SAMPLE_RATES = 'SET_POINT_SAMPLE_RATES';

export const SET_ALERTS = 'SET_ALERTS';
export const REPLACE_ALERT = 'REPLACE_ALERT';

// action log

export const SET_ACTION_LOG_ITEMS = 'SET_ACTION_LOG_ITEMS';
export const SET_ACTION_LOG_QUERY = 'SET_ACTION_LOG_QUERY';
export const SET_ACTION_TYPES = 'SET_ACTION_TYPES';
export const SET_ACTION_LOG_NAME = 'SET_ACTION_LOG_NAME';

export const SET_SSH_KEYS = 'SET_SSH_KEYS';

export const RTEM_FILES_FETCH_START = 'RTEM_FILES_FETCH_START';
export const RTEM_FILES_FETCH_END = 'RTEM_FILES_FETCH_END';
export const RTEM_FILES_FETCH_ERROR = 'RTEM_FILES_FETCH_ERROR';
export const RTEM_FILES_OPEN_DIR = 'RTEM_FILES_OPEN_DIR';
export const RTEM_FILES_SELECT = 'RTEM_FILES_SELECT';
export const SET_RTEM_NOTIFICATIONS = 'SET_RTEM_NOTIFICATIONS';
export const SET_SUBMISSION_STATUS = 'RTEM_SUBMISSION_STATUS';

export const SET_MEASUREMENTS = 'SET_MEASUREMENTS';

export const SET_CHANGELOG = 'SET_BUILDING_CHANGELOG';
export const SET_BULK_CHANGELOGS = 'SET_BULK_CHANGELOGS';

export const SET_DATA_REQUESTS = 'SET_DATA_REQUESTS';

export const SET_UNIT_PREFS = 'SET_UNIT_PREFS';
export const SET_PAGE_UNIT_PREF = 'SET_PAGE_UNIT_PREF';

export const SET_DEPLOYMENTS = 'SET_DEPLOYMENTS';
export const SET_HEARTBEATS = 'SET_HEARTBEATS';
export const SET_DEPLOYMENT_CONFIG = 'SET_DEPLOYMENT_CONFIG';

export const SET_STAGING = 'SET_BID_STAGING';
export const MARK_STAGING_LOADING = 'MARK_STAGING_LOADING';
export const SET_VALIDATION = 'SET_VALIDATION';
export const UPDATE_STAGING_EQUIP = 'UPDATE_STAGING_EQUIP';
export const SET_STAGING_FIELDS = 'SET_STAGING_FIELDS';

export const SET_EXPORTS = 'SET_EXPORTS';
export const SET_EXPORT = 'SET_EXPORT';

export const SET_DATA_ALERTS = 'SET_DATA_ALERTS';
export const SET_DATA_ALERT = 'SET_DATA_ALERT';
export const SET_DATA_ALERT_ACTION = 'SET_DATA_ALERT_ACTION';
