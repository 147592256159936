import React from 'react';
import { connect } from 'react-redux';
import { Typography } from '@mui/material';
import { submitTotpActionCreator } from '../../../redux/actions/login';
import TotpField from '../TotpField';

const TotpLogin = ({ submitTotp }) => (
  <>
    <div>
      <Typography variant="h4">Enter a one-time code to finish logging in</Typography>
    </div>
    <br />
    <div>
      <TotpField onSubmit={submitTotp} />
    </div>
  </>
);

export default connect(null, (dispatch) => ({
  submitTotp: (pw) => dispatch(submitTotpActionCreator(pw)),
}))(TotpLogin);
