import React from 'react';
import PropTypes from 'prop-types';
import { NavLink } from 'react-router-dom';
import { Button, TextField, Typography, IconButton } from '@mui/material';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import { checkPasswordReset, updatePasswordViaReset } from '../../api/Api';

import './LoginView.css';

class ChangePwViaReset extends React.PureComponent {
  state = {
    password: '',
    confirmPassword: '',
    error: '',
    showPassword: false,
    resetValid: null,
    submitting: false,
    succeeded: false,
  };

  componentDidMount() {
    const { email, token } = this.props;
    checkPasswordReset(email, token)
      .then(() => this.setState({ resetValid: true }))
      .catch(() => this.setState({ resetValid: false }));
  }

  onSubmit() {
    const { password, confirmPassword } = this.state;
    if (password.length < 8) {
      this.setState({ error: 'Password must be at least 8 characters' });
      return;
    }
    if (password !== confirmPassword) {
      this.setState({ error: 'Passwords must match' });
      return;
    }
    const { email, token } = this.props;
    this.setState({ submitting: true }, () =>
      updatePasswordViaReset(email, token, password)
        .then(() => this.setState({ submitting: false, succeeded: true }))
        .catch(() =>
          this.setState({ submitting: false, error: 'Something went wrong, please try again' })
        )
    );
  }

  renderInvalid() {
    const { email } = this.props;
    return (
      <div className="LoginView-center">
        <div className="LoginView-col">
          <Typography variant="h4">
            Could not find a matching password reset request for &apos;{email}&apos;
          </Typography>
          <p>
            Please double check the link in your email and try again or&nbsp;
            <NavLink to="/password-reset">start a new request</NavLink>
          </p>
        </div>
      </div>
    );
  }

  renderSucceeded() {
    const { email } = this.props;
    return (
      <div className="LoginView-center">
        <div className="LoginView-col">
          <Typography variant="h4">Successfully reset password for &apos;{email}&apos;</Typography>
          <p>
            You may now <NavLink to="/login">log in</NavLink> using your new credentials
          </p>
        </div>
      </div>
    );
  }

  render() {
    const { resetValid, showPassword, password, confirmPassword, submitting, error, succeeded } =
      this.state;

    if (resetValid != null && !resetValid) return this.renderInvalid();
    if (succeeded) return this.renderSucceeded();

    const { email } = this.props;
    const pwType = showPassword ? 'text' : 'password';

    return (
      <div className="LoginView-center">
        <div className="LoginView-col">
          <div>
            <Typography variant="h4">Reset your password</Typography>
            <br />
          </div>
          <div>
            <TextField
              name="email"
              label="email address"
              value={email}
              style={{ width: '256px' }}
              disabled
            />
          </div>
          <br />
          <div>
            <TextField
              label="new password"
              value={password}
              style={{ width: '256px' }}
              type={pwType}
              autoComplete="new-password" // magic value to disable autocomplete
              onChange={(e) => this.setState({ password: e.target.value })}
            />
            <br />
            <TextField
              label="confirm password"
              value={confirmPassword}
              style={{ width: '256px' }}
              type={pwType}
              autoComplete="new-password" // magic value to disable autocomplete
              onChange={(e) => this.setState({ confirmPassword: e.target.value })}
            />
          </div>
          <br />
          <div>
            <Button
              color="primary"
              variant="contained"
              onClick={() => this.onSubmit()}
              disabled={submitting}
              label="change password"
            >
              Update password
            </Button>
            <IconButton
              onClick={() => this.setState({ showPassword: !showPassword })}
              label="show passwords"
              size="large"
            >
              {showPassword ? <VisibilityOff /> : <Visibility />}
            </IconButton>
          </div>
          <div>
            <Typography variant="h6" color="error">
              {error}
            </Typography>
          </div>
        </div>
      </div>
    );
  }
}

ChangePwViaReset.propTypes = {
  email: PropTypes.string.isRequired,
  token: PropTypes.string.isRequired,
};

export default ChangePwViaReset;
