import { SET_SCOPES, SET_USER_KEYS, SET_API_VERSIONS } from './actiontypes';
import {
  getScopes,
  getApiKeys,
  createApiKey,
  editApiKey,
  deleteApiKey,
  getApiVersions,
} from '../../api/Api';

const sendData = (type, data) => ({
  type,
  data,
});

export const getKeysAndScopesAction = () => (dispatch, getState) => {
  const {
    user: { jwt },
  } = getState();
  getScopes(jwt).then((data) => dispatch(sendData(SET_SCOPES, data)));
  getApiVersions(jwt).then((data) => dispatch(sendData(SET_API_VERSIONS, data)));
  return getApiKeys(jwt).then((data) => dispatch(sendData(SET_USER_KEYS, data)));
};

export const createApiKeyAction = (name, scopes) => (dispatch, getState) => {
  const {
    user: { jwt },
  } = getState();
  return createApiKey(name, scopes, jwt).then((newKey) => {
    dispatch(getKeysAndScopesAction());
    return newKey;
  });
};

export const editApiKeyAction =
  ({ hash, name, scopes, active, api_version }) =>
  (dispatch, getState) => {
    const {
      user: { jwt },
    } = getState();
    return editApiKey(hash, name, scopes, active, api_version, jwt).then(() =>
      dispatch(getKeysAndScopesAction())
    );
  };

export const deleteApiKeyAction = (hash) => (dispatch, getState) => {
  const {
    user: { jwt },
  } = getState();
  return deleteApiKey(hash, jwt).then(() => dispatch(getKeysAndScopesAction()));
};
