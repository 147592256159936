export function getSelectedBuildingSummary(state, bid) {
  const { selectedBuildingId } = state.ui;
  if (selectedBuildingId <= 0) return null;
  return state.entities.buildings.byId[bid];
}

// function to compare buildings for ordering
function compareBuildingsByName(a, b) {
  const aName = a.name.toLowerCase();
  const bName = b.name.toLowerCase();
  if (aName < bName) return -1;
  if (aName > bName) return 1;
  return 0;
}

const getBuildingsOrderedByName = (state) =>
  Object.values(state.entities.buildings.byId).sort(compareBuildingsByName);

export default getBuildingsOrderedByName;

export const getBuildingField = (state, bid, field) => state.entities.buildings.byId[bid]?.[field];

export const getBuildingName = (state, bid) => getBuildingField(state, bid, 'name');

export const getOrgsBuildings = (state, org) =>
  Object.values(state.entities.buildings.byId).filter((b) => !org || b.orgId === org);

export const filterBuildings = (
  buildingsById,
  { orgId: filterOrgId, buildings: buildingIds = [] }
) =>
  Object.values(buildingsById)
    .filter(({ orgId }) => filterOrgId == null || filterOrgId === orgId)
    .filter(({ id }) => !buildingIds.length || buildingIds.indexOf(id) !== -1)
    .map(({ id }) => id);
