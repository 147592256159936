import React, { useState } from 'react';
import { connect } from 'react-redux';
import { Button, Tooltip, IconButton } from '@mui/material';
import { Business, Done, Clear } from '@mui/icons-material';
import { sendCommandAction, openDirAction } from '../../../redux/actions/rtemFiles';
import { mkdir } from './commands';
import SiteEditFields, { useSiteEdit } from './SiteEditFields';
import { folders } from '../siteCreator/SiteCreator';

import './site-creator.css';

/**
 * Inline site folder creator
 */
const SiteCreator = ({ path, loading, command, openDir }) => {
  // Get & validate the directory name
  const [creating, setCreating] = useState(false);
  const siteEdit = useSiteEdit();
  const {
    years,
    folder,
    reset: siteReset,
    valid: { valid },
  } = siteEdit;
  const reset = () => {
    setCreating(false);
    siteReset();
  };
  const makeServiceYear = (base, year) => {
    const [first, second] = folders(base, year);
    return command(mkdir(first), false)
      .then(() => command(mkdir(second)))
      .catch(() => {});
  };
  // text input for directory name, confirm and cancel buttons
  if (creating) {
    return (
      <div className="dir-actions creating">
        <SiteEditFields siteEdit={siteEdit} />
        <Tooltip title="Cancel">
          <IconButton onClick={reset} disabled={loading} size="large">
            <Clear className="icon" />
          </IconButton>
        </Tooltip>
        <Tooltip title={valid ? 'Create site' : 'Site id and name both required'}>
          <span>
            <IconButton
              aria-label="create-site"
              disabled={!valid || loading}
              onClick={() => {
                const siteDir = `${path}${folder}`;
                const f = [];
                for (let year = 1; year <= years; year += 1) {
                  f.push(makeServiceYear(siteDir, year));
                }
                Promise.all(f)
                  .then(() => {
                    openDir(path);
                    openDir(`${siteDir}/`);
                  })
                  .then(reset);
              }}
              size="large"
            >
              <Done className="icon" color="secondary" />
            </IconButton>
          </span>
        </Tooltip>
      </div>
    );
  }
  // Button to start site creation
  return (
    <div className="dir-actions button">
      <Button
        variant="outlined"
        aria-label="create-site-start"
        onClick={() => setCreating(true)}
        disabled={loading}
      >
        <Business className="left-icon" color="secondary" />
        New site
      </Button>
    </div>
  );
};

export default connect(
  ({ rtemFiles: { fetching } }) => ({ loading: fetching }),
  (dispatch) => ({
    command: (cmd, refetch = true) => dispatch(sendCommandAction(cmd, refetch)),
    openDir: (path) => dispatch(openDirAction(path, true)),
  })
)(SiteCreator);
