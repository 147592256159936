import React from 'react';
import { connect } from 'react-redux';
import { Navigate, useNavigate, useLocation } from 'react-router-dom';
import { checkFeatureEnabled } from '../../redux/selectors/ui';
import MfaRegistrationRequired from '../login/MfaRegistrationRequired';
import { TotpLoader, Fido2Loader } from '../loaders/mfa';
import { AUTH_LEVELS } from '../../redux/constants/roles';

const mapStateToProps = (state) => {
  const {
    user: {
      userAuthLevel,
      orgShortName,
      mfa,
      jwt,
      mfaRequired: serverMfaRequired,
      mfaLoaded: { totp, fido2 },
    },
  } = state;
  const userLoggedIn = jwt !== undefined;
  const hasMfa = mfa.totp?.length > 0 || mfa.fido2?.length > 0;
  const mfaLoaded = totp && fido2;
  // TODO: maybe roll these checks into the server-side logic?
  const mfaRequired =
    serverMfaRequired ||
    (userAuthLevel === AUTH_LEVELS.SUPER_ADMIN &&
      checkFeatureEnabled(state, 'require_mfa_super_admin')) ||
    (userAuthLevel === AUTH_LEVELS.ORG_ADMIN &&
      checkFeatureEnabled(state, 'require_mfa_org_admin'));
  return {
    userLoggedIn,
    userAuthLevel,
    userDenied: process.env.REACT_APP_WHICH !== 'nyserda' && orgShortName === 'RTEM Submission',
    mfaRegistrationRequiredNow: userLoggedIn && mfaRequired && mfaLoaded && !hasMfa,
    mfaRequired,
  };
};

const UNAUTHORIZED_REDIRECT = process.env.REACT_APP_WHICH === 'nyserda' ? '/' : '/sandbox-request';

const Authorized = (LoginComponent) =>
  connect(mapStateToProps)(
    ({
      // passed in props
      child: WrappedComponent,
      level: authLevelRequired,
      componentProps = {},
      // mapped props
      userLoggedIn,
      userAuthLevel,
      mfaRequired,
      mfaRegistrationRequiredNow,
      userDenied,
    }) => {
      const navigate = useNavigate();
      const location = useLocation();
      if (!userLoggedIn) {
        return <LoginComponent />;
      }

      if (mfaRegistrationRequiredNow) {
        return <MfaRegistrationRequired />;
      }

      const isAuthorized =
        !userDenied && Number.isInteger(userAuthLevel) && userAuthLevel <= authLevelRequired;
      const content = isAuthorized ? (
        // eslint-disable-next-line react/jsx-props-no-spreading
        <WrappedComponent {...componentProps} navigate={navigate} location={location} />
      ) : (
        <Navigate to={UNAUTHORIZED_REDIRECT} replace />
      );
      return (
        <>
          {mfaRequired && (
            <>
              <TotpLoader />
              <Fido2Loader />
            </>
          )}
          {content}
        </>
      );
    }
  );

export default Authorized;

export { AUTH_LEVELS };
