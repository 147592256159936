import React from 'react';
import { Typography } from '@mui/material';
import { UserActionsLoader } from '../loaders/actionLog';
import ActionTable from '../actionLog/ActionTable';

const MyActions = () => (
  <div>
    <UserActionsLoader />
    <Typography variant="h4">Actions by or affecting you</Typography>
    <ActionTable />
  </div>
);

export default MyActions;
