import React from 'react';
import { connect } from 'react-redux';
import {
  Checkbox,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import Delete from '@mui/icons-material/Delete';
import { useConfirm } from 'material-ui-confirm';
import { browserTime } from '../../utils/parsing';
import { editApiKeyAction, deleteApiKeyAction } from '../../redux/actions/apiKeys';
import { AUTH_LEVELS } from '../auth/Authorized';
import ScopeSelector from './ScopeSelector';
import ApiVersionSelector from './ApiVersionSelector';

/**
 * Component that displays a users API keys and allows for inline editing of scopes
 * and the active flag
 */
const UserApiKeys = ({ userKeys, organizations, showOrgColumn, editApiKey, deleteApiKey }) => {
  const confirm = useConfirm();
  return (
    <div className="user-api-keys">
      <Typography variant="h5">API keys</Typography>
      <Table className="keys-table">
        <TableHead>
          <TableRow>
            <TableCell />
            <TableCell>Enabled?</TableCell>
            <TableCell>Created</TableCell>
            <TableCell>Name</TableCell>
            <TableCell>API Version</TableCell>
            {showOrgColumn && <TableCell>Organization</TableCell>}
            <TableCell>Scopes</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {userKeys.map((k) => (
            <TableRow key={k.hash}>
              <TableCell className="delete">
                <IconButton
                  onClick={() =>
                    confirm({
                      description: 'This action cannot be undone!',
                      confirmationText: 'Delete key',
                    })
                      .then(() => deleteApiKey(k.hash))
                      .catch(() => {})
                  }
                  size="large"
                >
                  <Delete />
                </IconButton>
              </TableCell>
              <TableCell>
                <Checkbox
                  checked={k.active}
                  onChange={({ target: { checked } }) => editApiKey(k, { active: checked })}
                />
              </TableCell>
              <TableCell>{browserTime(k.created)}</TableCell>
              <TableCell>{k.name}</TableCell>
              <TableCell>
                <ApiVersionSelector
                  selected={k.api_version}
                  onValueChange={(version) => editApiKey(k, { api_version: version })}
                />
              </TableCell>
              {showOrgColumn && (
                <TableCell>
                  {(organizations[k.org_id] && organizations[k.org_id].name) || k.org_id}
                </TableCell>
              )}
              <TableCell className="scopes">
                <ScopeSelector
                  selected={k.scopes}
                  onChange={({ target: { value } }) =>
                    value.length > 0 && editApiKey(k, { scopes: value })
                  }
                />
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </div>
  );
};

export default connect(
  ({ apiKeys: { userKeys }, users: { orgsById }, user: { userAuthLevel } }) => ({
    userKeys,
    organizations: orgsById,
    showOrgColumn: userAuthLevel === AUTH_LEVELS.SUPER_ADMIN,
  }),
  (dispatch) => ({
    deleteApiKey: (hash) => dispatch(deleteApiKeyAction(hash)),
    editApiKey: (key, changes) => {
      const merged = { ...key, ...changes };
      dispatch(editApiKeyAction(merged));
    },
  })
)(UserApiKeys);
