import { combineReducers } from 'redux';
import {
  SET_ACTION_LOG_ITEMS,
  SET_ACTION_LOG_QUERY,
  SET_ACTION_TYPES,
  SET_ACTION_LOG_NAME,
} from '../actions/actiontypes';

const query = (
  state = {
    action: '',
    target_type: null,
    target_id: '',
    actor: '',
    org_id: null,
    limit: 500,
  },
  action
) => {
  if (action.type === SET_ACTION_LOG_QUERY) {
    return {
      ...state,
      ...action.data,
    };
  }
  return state;
};

const logItems = (state = [], action) => {
  if (action.type === SET_ACTION_LOG_ITEMS) {
    return action.data;
  }
  return state;
};

const actionTypes = (state = [], action) => {
  if (action.type === SET_ACTION_TYPES) {
    return action.data;
  }
  return state;
};

const queryName = (state = '', action) => {
  if (action.type === SET_ACTION_LOG_NAME) {
    return action.data;
  }
  return state;
};

export default combineReducers({
  query,
  queryName,
  logItems,
  actionTypes,
});
