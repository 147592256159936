import React from 'react';
import { capitalize } from '../../utils/strings';
import Selector from '../util/Selector';

const TARGETS = ['user', 'building', 's3_file'];

const TargetSelector = ({ selected, onChange }) => (
  <Selector
    options={TARGETS.map((t) => ({
      id: t,
      key: t,
      display: capitalize(t).replace('_', ' '),
    }))}
    showNullOption
    selected={`${selected}`}
    onChange={onChange}
  />
);

export default TargetSelector;
