import React, { useState } from 'react';
import { connect } from 'react-redux';
import { Button, Tooltip, IconButton } from '@mui/material';
import { Edit, Done, Clear } from '@mui/icons-material';
import { sendCommandAction, openDirAction } from '../../../redux/actions/rtemFiles';
import { mkdir, mv } from './commands';
import SiteEditFields, { useSiteEdit } from './SiteEditFields';

import './site-creator.css';

/**
 * Inline site folder editor
 */
const SiteEditor = ({ path, childDirs, loading, command, openDir }) => {
  const [creating, setCreating] = useState(false);
  const makeServiceYear = (base, year) =>
    command(mkdir(`${base}Service Year ${year} Report 1`)).then(() =>
      command(mkdir(`${base}Service Year ${year} Report 2`))
    );
  const paths = path.split('/');
  const currentFolder = paths[1];
  const currentYears = childDirs / 2;
  const siteEdit = useSiteEdit({ currentFolder, currentYears });
  const {
    years,
    folder,
    reset: siteReset,
    valid: { valid },
  } = siteEdit;
  const reset = () => {
    setCreating(false);
    siteReset();
  };
  // text input for directory name, confirm and cancel buttons
  if (creating) {
    return (
      <div className="dir-actions creating">
        <SiteEditFields siteEdit={siteEdit} />
        <Tooltip title="Cancel">
          <IconButton onClick={reset} disabled={loading} size="large">
            <Clear className="icon" />
          </IconButton>
        </Tooltip>
        <Tooltip title={valid ? 'Edit site' : 'Valid Site id and name both required'}>
          <span>
            <IconButton
              aria-label="edit-site"
              disabled={!valid || loading}
              onClick={() => {
                const siteDir = `${paths[0]}/${folder}/`;
                const f = [];
                if (siteDir !== path) {
                  f.push(command(mv(path, siteDir)));
                }
                for (let y = currentYears + 1; y <= years; y += 1) {
                  f.push(makeServiceYear(siteDir, y));
                }
                Promise.all(f)
                  .then(() => {
                    openDir(path);
                    openDir(siteDir);
                  })
                  .then(reset);
              }}
              size="large"
            >
              <Done className="icon" color="secondary" />
            </IconButton>
          </span>
        </Tooltip>
      </div>
    );
  }
  // Button to edit site
  return (
    <div className="dir-actions button">
      <Button
        variant="outlined"
        aria-label="create-site-start"
        onClick={() => setCreating(true)}
        disabled={loading}
      >
        <Edit className="left-icon" color="secondary" />
        Edit site
      </Button>
    </div>
  );
};

export default connect(
  ({ rtemFiles: { fetching, filesystem } }, { path }) => ({
    loading: fetching,
    childDirs: filesystem[path]?.childDirs?.length || 0,
  }),
  (dispatch) => ({
    command: (cmd) => dispatch(sendCommandAction(cmd)),
    openDir: (path) => dispatch(openDirAction(path, true)),
  })
)(SiteEditor);
