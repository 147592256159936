import { SET_SCOPES, SET_USER_KEYS, SET_API_VERSIONS } from '../actions/actiontypes';
import { compareByNumericProp } from '../../utils/comparators';

export default function apiKeys(
  state = {
    scopes: [],
    userKeys: [],
    versions: [],
  },
  action
) {
  switch (action.type) {
    case SET_SCOPES: {
      action.data.sort();
      return {
        ...state,
        scopes: action.data,
      };
    }
    case SET_USER_KEYS: {
      action.data.sort(compareByNumericProp('created', 'desc'));
      return {
        ...state,
        userKeys: action.data,
      };
    }
    case SET_API_VERSIONS: {
      action.data.sort(compareByNumericProp('timestamp', 'desc'));
      return {
        ...state,
        versions: action.data,
      };
    }
    default:
      return state;
  }
}
