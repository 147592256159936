import React from 'react';
import FilesLoader from './files/FilesLoader';
import FileViewer from './files/FileViewer';
import UserSubmissionsLoader from './submissions/UserSubmissionsLoader';
import './files/files.css';

const Files = () => (
  <div className="row grid-container-lg files">
    <FilesLoader />
    <UserSubmissionsLoader />
    <div className="full">
      <FileViewer />
    </div>
  </div>
);

export default Files;
