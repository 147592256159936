import React from 'react';
import { connect } from 'react-redux';
import getApiBaseUrl from '../../api/urls';
import SwaggerUi from '../lazy/SwaggerUi';

const SWAGGER_URL = `${getApiBaseUrl()}/swagger`;

const AuthorizedSwagger = ({ jwt }) => {
  const requestInterceptor = (req) => {
    req.headers.authorization = `Bearer ${jwt}`;
    return req;
  };
  return <SwaggerUi url={SWAGGER_URL} requestInterceptor={requestInterceptor} />;
};

export default connect(({ user: { jwt } }) => ({ jwt }))(AuthorizedSwagger);
