import React from 'react';
import { connect } from 'react-redux';
import TotpCreator from './TotpCreator';
import Fido2Creator from './Fido2Creator';

const MfaCreators = ({ fido2Enabled }) => (
  <>
    <TotpCreator />
    <p>For example: Authy or Google Authenticator</p>
    {fido2Enabled && (
      <>
        <br />
        <Fido2Creator />
        <p>For example: a YubiKey or Solo USB key</p>
      </>
    )}
  </>
);

export default connect(({ appVariant }) => ({
  fido2Enabled: appVariant !== 'nyserda',
}))(MfaCreators);
