import React from 'react';
import { connect } from 'react-redux';
import { compareByNumericProp } from '../../../utils/comparators';
import Selector from '../../util/Selector';

/* eslint-disable camelcase */

const getUnitName = (unit) => {
  if (unit == null) return '';
  return ` (${unit.name_abbr})`;
};

const MeasurementSelector = ({
  measurements,
  unitsById,
  selected,
  onChange,
  disabled,
  filter = () => true,
}) => (
  <Selector
    options={measurements.filter(filter).map(({ id, name, default_unit_id }) => ({
      id,
      key: `${id}`,
      display: `${name}${getUnitName(unitsById[default_unit_id])}`,
    }))}
    selected={selected ? `${selected}` : ''}
    onChange={({ target: { value } }) => onChange(value)}
    disabled={disabled}
  />
);

export default connect(
  ({
    entities: {
      measurements: { byId },
      units: { byId: unitsById },
    },
  }) => {
    const measurements = Object.values(byId);
    measurements.sort(compareByNumericProp('name'));
    return { measurements, unitsById };
  }
)(MeasurementSelector);
