import { SET_MEASUREMENTS } from './actiontypes';
import { getMeasurements } from '../../api/Api';

// eslint-disable-next-line import/prefer-default-export
export const getMeasurementsAction = () => (dispatch, getState) => {
  const {
    user: { jwt },
    entities: { measurements: allIds },
  } = getState();

  if (allIds.length) return;

  getMeasurements(jwt).then((data) => dispatch({ type: SET_MEASUREMENTS, data }));
};
