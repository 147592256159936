import { INVALIDATE_ATTACHMENTS, SET_BUILDING_ATTACHMENTS } from '../actions/actiontypes';

// Attachments are stored as an array in a dictionary keyed by building id:
// attachments = { 1: ['foo.pdf', 'bar'pdf] }
const attachmentReducer = (state = {}, action) => {
  switch (action.type) {
    case INVALIDATE_ATTACHMENTS:
      return { ...state, [action.buildingId]: [] };
    case SET_BUILDING_ATTACHMENTS:
      return { ...state, [action.buildingId]: action.attachments };
    default:
      return state;
  }
};

export default attachmentReducer;
