import { combineReducers } from 'redux';
import {
  SET_STAGING,
  MARK_STAGING_LOADING,
  SET_VALIDATION,
  UPDATE_STAGING_EQUIP,
  SET_STAGING_FIELDS,
} from '../actions/actiontypes';

const loaded = (state = {}, action) => {
  if (action.type === SET_STAGING) {
    return { ...state, [action.buildingId]: true };
  }
  if (action.type === MARK_STAGING_LOADING) {
    return { ...state, [action.buildingId]: false };
  }
  return state;
};

const equipmentByBuilding = (state = {}, action) => {
  if (action.type === SET_STAGING) {
    return { ...state, [action.buildingId]: action.equipment };
  }
  if (action.type === UPDATE_STAGING_EQUIP) {
    const newState = { ...state };
    if (!newState[action.buildingId]) {
      newState[action.buildingId] = action.equipment;
    } else {
      const updates = action.equipment.reduce((o, e) => {
        // eslint-disable-next-line no-param-reassign
        o[e.equip_id] = e;
        return o;
      }, {});
      newState[action.buildingId].forEach((e, i) => {
        const update = updates[e.equip_id];
        if (update != null) {
          newState[action.buildingId][i] = update;
        }
      });
    }
    return newState;
  }
  return state;
};

const fieldsByBuilding = (state = {}, action) => {
  if (action.type === SET_STAGING_FIELDS) {
    return { ...state, [action.buildingId]: action.data };
  }
  return state;
};

const pointsByEquipId = (state = {}, action) => {
  if (action.type === SET_STAGING) {
    return { ...state, [action.buildingId]: action.points };
  }
  if (action.type === UPDATE_STAGING_EQUIP) {
    const { equipId, points } = action;
    return {
      ...state,
      [action.buildingId]: {
        ...state[action.buildingId],
        [equipId]: points[equipId] ?? [],
      },
    };
  }
  return state;
};

// validation reducers

const points = (state = {}, action) => {
  if (action.type === SET_VALIDATION) {
    return { ...state, [action.buildingId]: action.points };
  }
  if (action.type === SET_STAGING) {
    return { ...state, [action.buildingId]: {} };
  }
  return state;
};

const equipment = (state = {}, action) => {
  if (action.type === SET_VALIDATION) {
    return { ...state, [action.buildingId]: action.equipment };
  }
  if (action.type === SET_STAGING) {
    return { ...state, [action.buildingId]: {} };
  }
  return state;
};

export default combineReducers({
  loaded,
  equipmentByBuilding,
  pointsByEquipId,
  validation: combineReducers({ points, equipment }),
  fieldsByBuilding,
});
