// function to compare based on a numeric property
export function compareByNumericProp(prop, dir = 'asc') {
  const direction = dir === 'asc' ? 1 : -1;
  return (a, b) => {
    if (a[prop] < b[prop]) return -1 * direction;
    if (a[prop] > b[prop]) return 1 * direction;
    return 0;
  };
}

export function compareByProps(props, dir = 'asc') {
  const funcs = props.map((p) => compareByNumericProp(p, dir));
  return (a, b) => {
    for (let i = 0; i < funcs.length; i += 1) {
      const c = funcs[i](a, b);
      if (c !== 0) return c;
    }
    return 0;
  };
}

export function compareEquipTypes(type1, type2) {
  if (type1.flowOrder > type2.flowOrder) {
    return 1;
  }
  if (type1.flowOrder < type2.flowOrder) {
    return -1;
  }
  if (type1.instances.length === type2.instances.length) {
    return type1.name > type2.name ? 1 : -1;
  }
  return type1.instances.length < type2.instances.length ? 1 : -1;
}

// function to compare point types for ordering
export function comparePointTypes(a, b) {
  if (a.critical - b.critical === 1) return -1;
  if (b.critical - a.critical === 1) return 1;
  return a.pointTypeName.localeCompare(b.pointTypeName);
}

// Sort that breaks ties using the index of the array items
export function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}
