import { combineReducers } from 'redux';
import { SET_DEPLOYMENTS, SET_HEARTBEATS, SET_DEPLOYMENT_CONFIG } from '../actions/actiontypes';
import { compareByNumericProp } from '../../utils/comparators';
import { groupListByProperty } from '../../utils/collections';

const deployments = (state = [], action) => {
  if (action.type !== SET_DEPLOYMENTS) return state;
  const deps = action.data.slice();
  deps.sort(compareByNumericProp('site'));
  return deps;
};

const configs = (state = {}, action) => {
  if (action.type !== SET_DEPLOYMENT_CONFIG) return state;
  return {
    ...state,
    [action.data.site]: action.data,
  };
};

const heartbeats = (state = {}, action) => {
  if (action.type !== SET_HEARTBEATS) return state;
  return groupListByProperty('site', action.data);
};

const latestHeartbeats = (state = {}, action) => {
  if (action.type !== SET_HEARTBEATS) return state;
  return action.data.reduce((o, hb) => {
    const { site, timestamp } = hb;
    if (o[site] == null || timestamp > o[site].timestamp) {
      // eslint-disable-next-line no-param-reassign
      o[site] = hb;
    }
    return o;
  }, {});
};

const deploymentsReducer = combineReducers({
  deployments,
  configs,
  heartbeats,
  latestHeartbeats,
});

export default deploymentsReducer;
