import { combineReducers } from 'redux';
import { groupByProperty } from '../../utils/collections';

const reducerBuilder = (name) => {
  const byId = (state = {}, action) => {
    switch (action.type) {
      case `SET_${name}`: {
        // TODO: save rest of information from page object
        return {
          ...state,
          ...groupByProperty('id', action.data.items),
        };
      }
      case `REPLACE_${name}`:
        return {
          ...state,
          [action.data.id]: action.data,
        };
      default:
        return state;
    }
  };

  const allIds = (state = [], action) => {
    switch (action.type) {
      case `SET_${name}`:
        return Array.from(new Set(state.concat(action.data.items.map((u) => u.id))));
      default:
        return state;
    }
  };

  const nextPage = (state = null, action) => {
    switch (action.type) {
      case `SET_${name}`:
        return action.data.next_num;
      default:
        return state;
    }
  };

  return combineReducers({ byId, allIds, nextPage });
};

const byExecutionId =
  (name) =>
  (state = {}, action) => {
    switch (action.type) {
      case `SET_EXEC_${name}`:
        return {
          ...state,
          [action.execId]: action.data,
        };
      default:
        return state;
    }
  };

export default combineReducers({
  codes: reducerBuilder('CODES'),
  schedules: reducerBuilder('SCHEDULES'),
  executions: reducerBuilder('EXECUTIONS'),
  logs: byExecutionId('LOGS'),
  results: byExecutionId('RESULTS'),
});
