import React from 'react';
import { connect } from 'react-redux';
import { Button, TextField, Typography } from '@mui/material';
import { beginPasswordReset } from '../../api/Api';

import './LoginView.css';

/**
 * Component to initiate account recovery via password reset
 */
class BeginReset extends React.PureComponent {
  state = {
    email: '',
    error: '',
    processing: false,
    complete: false,
  };

  onSubmit() {
    const { email } = this.state;
    if (!email) return;
    this.setState({ processing: true, complete: false, error: '' }, () =>
      beginPasswordReset(this.state.email, 'SELF', this.props.appVariant)
        .then(() => this.setState({ processing: false, complete: true }))
        .catch((e) => this.setState({ processing: false, error: e.message }))
    );
  }

  onLoginFieldChange(e) {
    this.setState({ email: e.target.value });
  }

  onEnter(e) {
    if (e.key === 'Enter') {
      e.preventDefault();
      this.onSubmit();
    } else {
      this.onLoginFieldChange(e);
    }
  }

  render() {
    const { processing, complete, email, error } = this.state;
    return (
      <div className="LoginView-center">
        <div className="LoginView-col">
          {complete && (
            <div>
              <Typography variant="h4">Thanks, your request has been received</Typography>
              <p>
                If there is a user account matching &apos;{email}&apos; we will send password reset
                instructions to it now
              </p>
            </div>
          )}
          {!complete && (
            <>
              <div>
                <Typography variant="h4">
                  Enter an email address to begin account recovery
                </Typography>
                <TextField
                  name="email"
                  label="email address"
                  onChange={(e) => this.onLoginFieldChange(e)}
                  onKeyPress={(e) => this.onEnter(e)}
                  style={{ width: '256px' }}
                  value={email}
                />
              </div>
              <br />
              <div>
                <Button
                  color="primary"
                  variant="contained"
                  onClick={() => this.onSubmit()}
                  disabled={processing}
                >
                  Reset password
                </Button>
              </div>
            </>
          )}
          {error && (
            <div>
              <Typography color="error" variant="h4">
                Sorry, we were unable to complete your request. Please try again later.
              </Typography>
              <p>{error}</p>
            </div>
          )}
        </div>
      </div>
    );
  }
}

export default connect(({ appVariant }) => ({ appVariant }))(BeginReset);
