import {
  SET_USERS,
  SET_ROLES,
  SET_ROLE_TYPES,
  SET_ORGS,
  SET_EDIT,
  RESET_EDIT,
  SET_EDITOR_ERROR,
} from '../actions/actiontypes';
import { groupByProperty } from '../../utils/collections';
import { stableSort, compareByNumericProp } from '../../utils/comparators';

const EMPTY_EDIT = {
  editType: 'NONE',
  data: {},
  id: null,
  error: '',
};

export default function users(
  state = {
    users: [],
    byId: {},
    roles: [],
    roleTypes: [],
    organizations: [],
    orgsById: {},
    editing: EMPTY_EDIT,
  },
  action
) {
  switch (action.type) {
    case SET_USERS:
      return {
        ...state,
        byId: groupByProperty('id', action.data),
        users: action.data,
      };
    case SET_ROLES:
      return {
        ...state,
        roles: action.data,
      };
    case SET_ROLE_TYPES:
      return {
        ...state,
        roleTypes: action.data,
      };
    case SET_ORGS:
      return {
        ...state,
        organizations: stableSort(action.data, compareByNumericProp('name')),
        orgsById: groupByProperty('id', action.data),
      };
    case RESET_EDIT:
      return {
        ...state,
        editing: EMPTY_EDIT,
      };
    case SET_EDIT:
      return {
        ...state,
        editing: {
          ...action.edit,
          error: state.editing.error,
        },
      };
    case SET_EDITOR_ERROR:
      return {
        ...state,
        editing: {
          ...state.editing,
          error: action.message,
        },
      };
    default:
      return state;
  }
}
