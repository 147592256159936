import React, { useState } from 'react';
import { connect } from 'react-redux';
import { useConfirm } from 'material-ui-confirm';
import prettyBytes from 'pretty-bytes';
import { IconButton, Tooltip, TextField } from '@mui/material';
import { Edit, Done, Clear } from '@mui/icons-material';
import { sendCommandAction } from '../../../redux/actions/rtemFiles';
import { mv } from './commands';

const filename = (file) => (
  <span className="selectable info">
    {file.name} {file.size != null && `(${prettyBytes(file.size)})`}
  </span>
);

/**
 * Shows a filename, allows for rename if requested
 */
const Filename = ({ file, canRename, isSelected, setParentRenaming, command }) => {
  const [isRenaming, setLocalRenaming] = useState(false);
  const setRenaming = (b) => {
    setParentRenaming(b);
    setLocalRenaming(b);
  };
  const [renameTo, setRenameTo] = useState(file.name);
  const filenameChanged = renameTo !== file.name;
  const reset = () => {
    setRenaming(false);
    setRenameTo(file.name);
  };
  const confirm = useConfirm();
  if (!canRename) return filename(file);
  const isRenameVisible = isSelected && isRenaming;
  return (
    // eslint-disable-next-line jsx-a11y/no-static-element-interactions
    <span
      // Need to stop these from bubbling up to the directory (will select/unselect)
      onClick={(e) => isRenameVisible && e.stopPropagation()}
      onKeyPress={(e) => isRenameVisible && e.stopPropagation()}
    >
      {isRenameVisible ? (
        <TextField
          style={{ width: '400px' }}
          className="info"
          name="new-name"
          value={renameTo}
          onChange={(e) => setRenameTo(e.target.value)}
        />
      ) : (
        filename(file)
      )}

      {isSelected && (
        // eslint-disable-next-line react/jsx-no-useless-fragment
        <>
          {isRenaming ? (
            <>
              <Tooltip title="Cancel">
                <IconButton
                  aria-label="cancel"
                  onClick={() => {
                    reset();
                  }}
                  size="large"
                >
                  <Clear className="icon" />
                </IconButton>
              </Tooltip>
              <Tooltip title="Rename">
                <span>
                  <IconButton
                    aria-label="rename-confirm"
                    disabled={!filenameChanged}
                    onClick={() => {
                      confirm({
                        title: `Rename '${file.path}' ?`,
                        description: `This action will rename '${file.name}' to '${renameTo}'`,
                        confirmationText: `Rename ${file.name}`,
                      })
                        .then(() => command(mv(file.path, file.rename(renameTo))).then(reset))
                        .catch(() => {});
                    }}
                    size="large"
                  >
                    <Done className="icon" color="secondary" />
                  </IconButton>
                </span>
              </Tooltip>
            </>
          ) : (
            <Tooltip title="Rename">
              <IconButton
                aria-label="rename"
                onClick={(e) => {
                  e.stopPropagation();
                  setRenaming(true);
                }}
                size="large"
              >
                <Edit className="icon" />
              </IconButton>
            </Tooltip>
          )}
        </>
      )}
    </span>
  );
};

Filename.defaultProps = {
  canRename: true,
  setParentRenaming: () => {},
};

export default connect(null, (dispatch) => ({
  command: (cmd) => dispatch(sendCommandAction(cmd)),
}))(Filename);
