/* eslint-disable max-classes-per-file */

const SEP = '/';
const removeTrailingSlash = (path) =>
  path.endsWith(SEP) ? path.substring(0, path.length - 1) : path;
const isFile = ({ key }) => !key.endsWith(SEP);

class FilesystemItem {
  constructor(path) {
    this.path = path;
    const parts = removeTrailingSlash(this.path).split(SEP);
    [this.name] = parts.slice(-1);
    this.parents = parts.slice(0, -1).join(SEP) + SEP;
    this.level = parts.length;
  }

  rename(name) {
    // rename without re-parenting
    return `${this.parents}${name}`;
  }
}

class Folder extends FilesystemItem {
  files = [];

  childDirs = [];

  rename(name) {
    // directories need to preserve the trailing separator in their key name
    return super.rename(name) + SEP;
  }
}

class Root extends Folder {
  constructor() {
    super('/');
    this.level = 0;
    this.parents = null;
    this.name = '/';
  }
}

class File extends FilesystemItem {
  constructor(objSummary) {
    const { key, size, md5, last_modified } = objSummary;
    super(key);
    this.md5 = md5;
    this.size = size;
    // eslint-disable-next-line camelcase
    this.lastModified = last_modified;
  }
}

/**
 * Take a list of object summary results from S3 and turn it into a filesystem
 * dictionary where folders are keyed by their complete path and all files are
 * in a folder
 *
 * Note: Since these are AWS keys, they don't begin with a trailing / like a unix path would
 */
const parseFilesystem = ({ results }) => {
  const filesystem = {};

  const ensureFolder = (path) => {
    if (filesystem[path]) return filesystem[path];

    const folder = path === '/' ? new Root() : new Folder(path);
    filesystem[path] = folder;
    if (folder.level > 0) {
      ensureFolder(folder.parents).childDirs.push(path);
    }
    return folder;
  };

  (results || []).forEach((summary) => {
    if (isFile(summary)) {
      const file = new File(summary);
      ensureFolder(file.parents).files.push(file);
    } else {
      ensureFolder(summary.key);
    }
  });
  const topDirs = Object.values(filesystem)
    .filter((f) => f.level === 1)
    .map((f) => f.path)
    .sort();
  return { topDirs, filesystem };
};

export default parseFilesystem;
