import React from 'react';
import { connect } from 'react-redux';
import { TableBody, TableCell, TableRow } from '@mui/material';

import { compareByNumericProp } from '../../../utils/comparators';
import { measurementFilter } from './UnitPrefsTable';

/* eslint-disable camelcase */

/**
 * A table of unit preferences for convertible measurements. We can convert between any units that share the
 * same 'unit_type' field (from QUDT). This table is used to show org or user-level preferences.
 */
const MeasurementDefaultPrefs = ({
  measurements,
  units,
  headerText,
  parentPreferences,
  displayInactive,
}) => {
  const parPrefIds = new Set(parentPreferences.map((pref) => pref.measurement_id));
  const defaultUnitNameLong = (measurement) => {
    const defaultUnitId = measurement.default_unit_id;
    if (defaultUnitId === null) {
      return `No default unit for ${measurement.name}`;
    }
    const defaultUnit = units[defaultUnitId];
    return defaultUnit?.name_long ?? '';
  };
  return (
    <TableBody>
      <TableRow>
        <th colSpan={4} style={{ textAlign: 'center', height: '80px' }}>
          {headerText}
        </th>
      </TableRow>
      <TableRow>
        <th aria-label="empty-add" />
        <th>Measurement Type</th>
        <th>Default Unit</th>
        <th aria-label="empty-preference" />
      </TableRow>
      {Object.values(measurements)
        .sort(compareByNumericProp('name'))
        .filter((measurement) => !parPrefIds.has(measurement.id) || displayInactive)
        .filter((measurement) => measurementFilter(units)(measurement))
        .map((measurement) => (
          <TableRow
            key={measurement.id}
            className={parPrefIds.has(measurement.id) ? 'overridden' : ''}
          >
            <TableCell />
            <TableCell>{measurement.name}</TableCell>
            <TableCell>{defaultUnitNameLong(measurement)}</TableCell>
            <TableCell />
          </TableRow>
        ))}
    </TableBody>
  );
};

export default connect(
  ({
    entities: {
      unitPrefs: { org, user },
      measurements: { byId },
      units: { byId: units },
    },
  }) => ({
    headerText: "These are Onboard's defaults",
    parentPreferences: user.concat(org),
    measurements: byId,
    units,
  })
)(MeasurementDefaultPrefs);
