import { connect } from 'react-redux';
import UnitPrefsTable from './UnitPrefsTable';

export default connect(
  ({
    entities: {
      unitPrefs: { user },
    },
  }) => ({
    preferences: user,
    headerText:
      'These preferences apply to your account only, and can override organization-level preferences',
    editable: true,
    type: 'USER',
    parentPreferences: [],
  })
)(UnitPrefsTable);
