import { SET_UNIT_PREFS } from './actiontypes';
import { getUnitPreferences, setUnitPreference, deleteUnitPreference } from '../../api/Api';

const sendData = (type, data) => ({
  type,
  data,
});

export const getUnitPreferencesAction = () => (dispatch, getState) => {
  const {
    user: { jwt },
  } = getState();
  return getUnitPreferences(jwt).then((prefs) =>
    dispatch(
      sendData(SET_UNIT_PREFS, {
        org: prefs?.filter(({ type }) => type === 'ORG') || [],
        user: prefs?.filter(({ type }) => type === 'USER') || [],
      })
    )
  );
};

export const setUnitPreferenceAction = (measurementId, unitId, type) => (dispatch, getState) => {
  const {
    user: { jwt },
  } = getState();
  return setUnitPreference({ type, measurement_id: measurementId, unit_id: unitId }, jwt).then(() =>
    dispatch(getUnitPreferencesAction())
  );
};

export const deleteUnitPreferenceAction = (measurementId, type) => (dispatch, getState) => {
  const {
    user: { jwt },
  } = getState();
  return deleteUnitPreference({ type, measurement_id: measurementId }, jwt).then(() =>
    dispatch(getUnitPreferencesAction())
  );
};
