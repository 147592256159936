import { combineReducers } from 'redux';
import {
  SET_BUILDING_INGEST_STATS,
  SET_POINT_INGEST_MESSAGES,
  SET_POINT_SAMPLE_RATES,
} from '../actions/actiontypes';
import { groupByProperty, groupListByProperty } from '../../utils/collections';
import { compareByNumericProp } from '../../utils/comparators';

const buildingStats = (state = {}, action) => {
  switch (action.type) {
    case SET_BUILDING_INGEST_STATS:
      return groupListByProperty('building_id', action.data, compareByNumericProp('timestamp'));
    default:
      return state;
  }
};

const pointMessages = (state = [], action) => {
  switch (action.type) {
    case SET_POINT_INGEST_MESSAGES:
      return action.data;
    default:
      return state;
  }
};

const pointMessagesById = (state = {}, action) => {
  switch (action.type) {
    case SET_POINT_INGEST_MESSAGES:
      return groupByProperty('point_id', action.data);
    default:
      return state;
  }
};

const latestTimestamps = (state = {}, action) => {
  switch (action.type) {
    case SET_BUILDING_INGEST_STATS:
      return action.data.reduce((rv, b) => {
        const current = rv[b.building_id];
        if (current == null || b.timestamp > current) {
          // eslint-disable-next-line no-param-reassign
          rv[b.building_id] = b.timestamp;
        }
        return rv;
      }, {});
    default:
      return state;
  }
};

const pointSampleRates = (state = {}, action) => {
  if (action.type !== SET_POINT_SAMPLE_RATES) return state;
  return { ...state, ...action.data };
};

const pointSampleRatesLoaded = (state = 0, action) => {
  if (action.type !== SET_POINT_SAMPLE_RATES) return state;
  return state + 1;
};

const loaded = (state = false, action) => {
  if (action.type !== SET_BUILDING_INGEST_STATS) return state;
  return true;
};

const ingestStats = combineReducers({
  buildingStats,
  pointMessages,
  pointMessagesById,
  latestTimestamps,
  pointSampleRates,
  pointSampleRatesLoaded,
  loaded,
});

export default ingestStats;
