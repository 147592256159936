import React from 'react';
import { connect } from 'react-redux';
import Selector from '../util/Selector';

const ApiVersionSelector = ({ versions, selected, onValueChange, disabled }) => (
  <Selector
    options={versions.map((version) => ({
      id: version.timestamp,
      key: `${version.timestamp}`,
      display: `${version.timestamp} (${version.changelog})`,
    }))}
    selected={selected}
    onChange={({ target: { value } }) => onValueChange(value)}
    disabled={disabled}
  />
);

export default connect(({ apiKeys: { versions } }) => ({ versions }))(ApiVersionSelector);
