import Bowser from 'bowser';
import React from 'react';

const CACHE = {};

const parse = (userAgent) => {
  if (CACHE[userAgent]) return CACHE[userAgent];
  const val = Bowser.parse(userAgent);
  CACHE[userAgent] = val;
  return val;
};

const UserAgentDevice = ({ userAgent, ipAddress }) => {
  const {
    browser: { name: browserName },
    os: { name: osName },
  } = parse(userAgent);
  const agent = browserName && osName ? `${browserName} on ${osName}` : userAgent;
  return (
    <div>
      {agent}
      {ipAddress && ` @ ${ipAddress}`}
    </div>
  );
};

export default UserAgentDevice;
