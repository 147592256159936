import {
  LOGIN_POST_REQUEST,
  LOGIN_POST_SUCCESS,
  LOGIN_POST_ERROR,
  LOGIN_RESET_ERROR,
} from './actiontypes';
import {
  submitLogin,
  getAll,
  restoreLogin,
  submitTotp,
  startFido2Login,
  completeFido2Login,
} from '../../api/Api';
import { setLoginCookie, getLoginCookie } from '../../api/loginCookie';
import {
  updateUserInfoAction,
  clearUserInfoAction,
  setForcedLogoutAction,
  partialUserLogin,
} from './user';
import { setFeatures } from './ui';

// an action that describes which state to update when user initiates
// request to login
function requestLoginAction() {
  return {
    type: LOGIN_POST_REQUEST,
  };
}

// an action that describes which state to update when successful
// response received back from login network request
function loginSuccessAction() {
  return {
    type: LOGIN_POST_SUCCESS,
    errMessage: undefined,
  };
}

export function loginErrorAction(error, networkError = false) {
  return {
    type: LOGIN_POST_ERROR,
    errMessage: error.message || 'Something went wrong.',
    networkError,
  };
}

export function resetLoginErrorAction() {
  return {
    type: LOGIN_RESET_ERROR,
  };
}

export const fetchFeatures = () => (dispatch, getState) => {
  const {
    user: { jwt },
    ui: { features },
  } = getState();
  if (Object.keys(features).length) return Promise.resolve();
  return getAll(jwt, 'features')
    .then((response) => {
      dispatch(setFeatures(response));
    })
    .catch(() => {}); // not ever worth crashing for features
};

export function loginSuccess(dispatch, userInfo, authLevel, useCookies = true) {
  if (useCookies) {
    setLoginCookie(userInfo.token);
  }
  dispatch(updateUserInfoAction(userInfo, authLevel));
  dispatch(loginSuccessAction());
  dispatch(fetchFeatures());
}

export function restoreLoginActionCreator() {
  return (dispatch) => {
    const token = getLoginCookie();
    if (token) {
      // this call only works on full logins, don't need to handle MFA here
      restoreLogin(token).then((response) => {
        if (response.result === 'ok') {
          // repopulate userInfo.token if unset
          // This is only really relevent when operating through IAP
          // If this becomes an issue for non-gcp environments, we should modify LoginPersistence to execute a different path
          if (!response.userInfo.token) response.userInfo.token = token;
          loginSuccess(dispatch, response.userInfo, response.authLevel);
        } else {
          dispatch(clearUserInfoAction());
        }
      });
    }
  };
}

export function oauthLoginActionCreator(token) {
  return (dispatch) => {
    if (token) {
      // this call only works on full logins, don't need to handle MFA here
      restoreLogin(token).then((response) => {
        if (response.result === 'ok') {
          if (!response.userInfo.token) response.userInfo.token = token;
          loginSuccess(dispatch, response.userInfo, response.authLevel, false);
        } else {
          dispatch(clearUserInfoAction());
        }
      });
    }
  };
}

export const submitTotpActionCreator = (password) => (dispatch, getState) => {
  const {
    user: { partialLoginToken },
  } = getState();
  return submitTotp(password, partialLoginToken).then(
    (response) => {
      if (response.result === 'ok' && response.complete) {
        loginSuccess(dispatch, response.userInfo);
      } else {
        dispatch(loginErrorAction({ message: 'Invalid MFA response, please try again' }));
      }
    },
    (error) => dispatch(loginErrorAction(error, true))
  );
};

export const startFido2LoginActionCreator = () => (dispatch, getState) => {
  const {
    user: { partialLoginToken },
  } = getState();
  return startFido2Login(partialLoginToken);
};

export const completeFido2LoginActionCreator = (data) => (dispatch, getState) => {
  const {
    user: { partialLoginToken },
  } = getState();
  return completeFido2Login(data, partialLoginToken).then(
    (response) => {
      if (response.result === 'ok' && response.complete) {
        loginSuccess(dispatch, response.userInfo);
      } else {
        dispatch(loginErrorAction({ message: 'Invalid FIDO2 challenge, please try again' }));
      }
    },
    (error) => dispatch(loginErrorAction(error, true))
  );
};

// an action creator which handles making the login network request
// and dispatching an action once network response/error returns
export default function loginActionCreator(email, pw, remember) {
  return (dispatch) => {
    // dispatch action that informs store that API call starting
    dispatch(requestLoginAction());

    // reset forced logout message
    dispatch(setForcedLogoutAction(false));

    return submitLogin(email, pw, remember).then(
      (response) => {
        if (response.result === 'ok') {
          if (response.complete) {
            loginSuccess(dispatch, response.userInfo);
          } else {
            dispatch(partialUserLogin(response));
          }
        } else {
          dispatch(clearUserInfoAction());
          dispatch(loginErrorAction(response, response.status > 499));
        }
      },
      (error) => dispatch(loginErrorAction(error, true))
    );
  };
}
