import React from 'react';
import { connect } from 'react-redux';
import { Typography } from '@mui/material';
import { AUTH_LEVELS } from '../../auth/Authorized';
import UsersLoader from '../../loaders/UsersLoader';
import UserSubmissionsLoader from './UserSubmissionsLoader';
import FilesLoader from '../files/FilesLoader';
import SubmissionsTable from './SubmissionsTable';

import './submissions.css';

/**
 * Page on which users/admins can review s3 file data submissions
 */
const Submissions = ({ variant }) => (
  <div className="row grid-container-lg submissions">
    <UserSubmissionsLoader />
    <FilesLoader />
    {variant === 'ADMIN' && <UsersLoader />}
    <div className="full">
      <Typography className="title" variant="h4">
        Data Submissions
      </Typography>
      <SubmissionsTable variant={variant} />
    </div>
  </div>
);

export default connect(({ user: { userAuthLevel } }) => ({
  variant: userAuthLevel <= AUTH_LEVELS.ORG_ADMIN ? 'ADMIN' : 'USER',
}))(Submissions);
