import React from 'react';
import { connect } from 'react-redux';
import { Typography } from '@mui/material';
import UnitsViewer from './attrs/UnitsViewer';
import PointTypesViewer from './attrs/PointTypesViewer';

const Measurement = ({ measurement, base }) =>
  measurement ? (
    <div className="leaf measurement">
      <Typography variant="h5" className="name">
        {measurement.name}
      </Typography>
      <UnitsViewer defaultId={measurement.default_unit_id} measurementId={measurement.id} />
      <PointTypesViewer measurementId={measurement.id} base={base} />
    </div>
  ) : null;

export default connect(
  (
    {
      entities: {
        measurements: { byId },
      },
    },
    { measurement: { id } }
  ) => ({
    measurement: byId[id],
  })
)(Measurement);
