export function chunkArray(arr, chunkSize) {
  const chunks = [];
  for (let i = 0, len = arr.length; i < len; i += chunkSize) {
    chunks.push(arr.slice(i, i + chunkSize));
  }
  return chunks;
}

export const groupByProperty = (key, list) =>
  list.reduce((rv, i) => {
    // eslint-disable-next-line no-param-reassign
    rv[i[key]] = i;
    return rv;
  }, {});

export const groupListByProperty = (keyProp, list, comparator = undefined) => {
  const grouped = list.reduce((rv, i) => {
    const key = i[keyProp];
    // eslint-disable-next-line no-param-reassign
    (rv[key] = rv[key] || []).push(i);
    return rv;
  }, {});
  if (comparator) {
    Object.values(grouped).forEach((l) => l.sort(comparator));
  }
  return grouped;
};

export const max = (arr) =>
  arr.reduce((m, v) => {
    if (m == null) return v;
    if (v == null) return m;
    return v > m ? v : m;
  }, null);

export const zip = (arr1, arr2) => arr1.map((k, i) => [k, arr2[i]]);

// eslint-disable-next-line no-nested-ternary
const compare = (a, b) => (a === b ? 0 : a > b ? 1 : -1);

export const alphabetical = (key, list) => list.sort((a, b) => compare(a[key], b[key]));

export const mapValues = (obj, valueMapper) =>
  Object.entries(obj)
    .map(([key, val]) => [key, valueMapper(val)])
    .reduce((o, [k, v]) => {
      // eslint-disable-next-line no-param-reassign
      o[k] = v;
      return o;
    }, {});
