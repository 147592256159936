import React from 'react';
import { connect } from 'react-redux';
import { Typography, IconButton } from '@mui/material';
import { Delete, PhoneIphone, VpnKey } from '@mui/icons-material';
import { useConfirm } from 'material-ui-confirm';
import { browserTime } from '../../utils/parsing';
import Truncated from '../util/Truncated';
import { deleteTotpActionCreator, deleteFido2ActionCreator } from '../../redux/actions/mfa';
import { TotpLoader, Fido2Loader } from '../loaders/mfa';
import MfaCreators from './MfaCreators';

import './account.css';

export const Fido2 = ({ name, created, deleteFido2 }) => {
  const confirm = useConfirm();
  return (
    <div>
      <VpnKey />
      <span>
        with key{' '}
        <span className="device-name">
          <Truncated value={name} />
        </span>{' '}
        and created {browserTime(created)}
      </span>
      <IconButton
        onClick={() =>
          confirm({
            title: `Delete FIDO2 device '${name}'?`,
            description: 'This will remove this authentication device and cannot be undone.',
            confirmationText: `Delete FIDO2 device`,
          })
            .then(() => deleteFido2(name))
            .catch(() => {})
        }
        size="large"
      >
        <Delete />
      </IconButton>
    </div>
  );
};

export const Totp = ({ name, created, deleteTotp }) => {
  const confirm = useConfirm();
  return (
    <div key={name}>
      <PhoneIphone />
      <span>
        named <span className="device-name">{name}</span> and created {browserTime(created)}
      </span>
      <IconButton
        onClick={() =>
          confirm({
            title: `Delete MFA app '${name}'?`,
            description: 'This will remove this authentication app and cannot be undone.',
            confirmationText: `Delete ${name}`,
          })
            .then(() => deleteTotp(name))
            .catch(() => {})
        }
        size="large"
      >
        <Delete />
      </IconButton>
    </div>
  );
};

const MfaDevices = ({ totp, fido2, noDevices, deleteTotp, deleteFido2 }) => (
  <div className="row grid-container-lg mfa">
    <TotpLoader />
    <Fido2Loader />
    <div className="row">
      <Typography variant="h4">Your multi-factor devices</Typography>
    </div>
    <div className="row">
      {totp.map(({ name, created }) => (
        <Totp key={name} name={name} created={created} deleteTotp={deleteTotp} />
      ))}
      {fido2.map(({ key, created }) => (
        <Fido2 key={key} name={key} created={created} deleteFido2={deleteFido2} />
      ))}
      {noDevices && <Typography variant="h6">None configured yet</Typography>}
    </div>

    <div className="row">
      <MfaCreators />
    </div>
  </div>
);

export default connect(
  ({
    user: {
      mfa: { totp, fido2 },
    },
  }) => ({ totp, fido2 }),
  (dispatch) => ({
    deleteTotp: (name) => dispatch(deleteTotpActionCreator(name)),
    deleteFido2: (key) => dispatch(deleteFido2ActionCreator(key)),
  })
)(MfaDevices);
