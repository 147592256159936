export const AUTH_LEVELS = {
  SUPER_ADMIN: 0,
  ORG_ADMIN: 1,
  ADMIN: 2,
  ORG_USER: 3,
  USER: 4,
  PORTAL_USER: 4,
  NO_PORTAL_ACCESS: 5,
  ALL_USER: 1000,
};
