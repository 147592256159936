import qs from 'qs';

export const getSearchParams = (search) => {
  const searchString = search ? search.substr(1) : '';
  return qs.parse(searchString);
};

/**
 * Navigate to the current page with new query string parameters
 */
export const updateCurrentPath = (navigate, newParams) => {
  const { location } = window;
  const currentParams = getSearchParams(location.search);
  const params = new URLSearchParams({ ...currentParams, ...newParams });
  navigate(`${location.pathname}?${params.toString()}`);
};

export default getSearchParams;
