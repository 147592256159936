import React from 'react';
import { connect } from 'react-redux';
import { useNavigate, useLocation } from 'react-router-dom';
import { Tabs, Tab } from '@mui/material';
import { updateCurrentPath, getSearchParams } from '../../utils/url';
import { AUTH_LEVELS } from '../auth/Authorized';
import ProfileEdit from './ProfileEdit';
import MfaDevices from './MfaDevices';
import MyActions from './MyActions';
import ApiManagement from '../apiMgmt/ApiManagement';
import Notifications from './Notifications';
import UnitPreferences from './unitPrefs/UnitPreferences';

import './account.css';

const content = (tab) => {
  switch (tab) {
    case 'actions':
      return (
        <div className="full">
          <MyActions />
        </div>
      );
    case 'api':
      return (
        <div className="full">
          <ApiManagement />
        </div>
      );
    case 'notifications':
      return (
        <div className="full">
          <Notifications />
        </div>
      );
    case 'unitPrefs':
      return <UnitPreferences />;
    case 'profile':
    default:
      return (
        <>
          <div className="left">
            <ProfileEdit />
          </div>
          <div className="right">
            <MfaDevices />
          </div>
        </>
      );
  }
};

const TABS = ['profile', 'actions', 'api', 'notifications', 'unitPrefs'];
const TAB_NAMES = {
  profile: 'Profile',
  actions: 'Actions',
  api: 'API Keys',
  notifications: 'Notification Preferences',
  unitPrefs: 'Unit Preferences',
};

const Account = ({ showNotificationsTab }) => {
  const location = useLocation();
  const navigate = useNavigate();
  const params = getSearchParams(location.search);
  const tabs = TABS.filter((n) => showNotificationsTab || n !== 'notifications');
  const tab = params.tab || tabs[0];
  const tabIndex = Math.max(tabs.indexOf(tab), 0);
  return (
    <>
      <div className="account-tabs">
        <Tabs
          indicatorColor="secondary"
          textColor="inherit"
          value={tabIndex}
          onChange={(e, val) => updateCurrentPath(navigate, { tab: tabs[val] })}
        >
          {tabs.map((t) => (
            <Tab key={t} label={TAB_NAMES[t]} />
          ))}
        </Tabs>
      </div>
      <div className="row grid-container-lg account">{content(tabs[tabIndex])}</div>
    </>
  );
};

export default connect(({ appVariant, user: { userAuthLevel } }) => ({
  showNotificationsTab: appVariant === 'nyserda' && userAuthLevel <= AUTH_LEVELS.ORG_ADMIN,
}))(Account);
