import React from 'react';
import PropTypes from 'prop-types';
import Selector from '../util/Selector';

const VARIANTS = ['portal', 'sandbox', 'nyserda', 'hackathon'];

const WelcomeVariantSelector = ({ selected = 'portal', onChange }) => (
  <Selector
    options={VARIANTS.map((v) => ({ id: v, key: v, display: v }))}
    selected={selected ? `${selected}` : ''}
    onChange={onChange}
  />
);

WelcomeVariantSelector.propTypes = {
  selected: PropTypes.oneOf(VARIANTS).isRequired,
  onChange: PropTypes.func.isRequired,
};

export default WelcomeVariantSelector;
