import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import {
  Button,
  Typography,
  TextField,
  FormLabel,
  FormGroup,
  IconButton,
  InputAdornment,
} from '@mui/material';
import { Link } from 'react-router-dom';
import { FilterNone } from '@mui/icons-material';
import { createApiKeyAction } from '../../redux/actions/apiKeys';
import ScopeSelector from './ScopeSelector';

import './key-creator.css';

const INITIAL_STATE = {
  name: '',
  scopes: [],
  privateKey: '',
  copyMessage: false,
};

class KeyCreator extends PureComponent {
  state = INITIAL_STATE;

  constructor(props) {
    super(props);
    this.textInput = React.createRef();
  }

  clear = () => {
    this.setState(INITIAL_STATE);
  };

  copyKeyValue = () => {
    const ti = this.textInput.current;
    ti.select();
    ti.setSelectionRange(0, 9999);
    const copied = document.execCommand('copy');
    // and deselect the text again
    ti.blur();
    ti.setSelectionRange(0, 0);

    if (!copied) return;

    this.setState({ copyMessage: true });
    setTimeout(() => this.setState({ copyMessage: false }), 2000);
  };

  renderPrivateKeyValue() {
    const { copyMessage, privateKey } = this.state;
    if (privateKey === '') {
      return null;
    }
    return (
      <div>
        <Typography variant="h6">Success! Your new API key is:</Typography>
        <TextField
          className="private-key"
          color="secondary"
          readOnly
          fullWidth
          inputRef={this.textInput}
          type="text"
          value={privateKey}
          InputProps={{
            disableUnderline: true,
            endAdornment: (
              <InputAdornment position="end">
                <IconButton onClick={this.copyKeyValue} aria-label="copy key" size="large">
                  <FilterNone />
                  {copyMessage ? 'Copied to clipboard!' : null}
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
        <Typography variant="h6" color="error">
          Please store this value as it will not be displayed again
        </Typography>
        <Button
          onClick={this.clear}
          variant="outlined"
          color="secondary"
          aria-label="reset key form"
        >
          Clear form
        </Button>
      </div>
    );
  }

  render() {
    const { name, scopes, privateKey } = this.state;
    const { createKey } = this.props;
    const formComplete = privateKey !== '';
    const createDisabled = scopes.length === 0;
    return (
      <div className="key-creator">
        <Typography variant="h5">Create a new API key</Typography>
        <div>
          <p>
            API keys allow secure, automated access to the Onboard portal. They are granted explicit
            access to API endpoints and data by attaching one or more scopes to the key. The key is
            linked to its creator&apos;s user account, and can only access information that is
            already visible in the UI. This means that an API key which has been granted all scopes
            is equivalent to a logged in user.
          </p>
          <p>
            For more information about which API endpoints are in each scope please refer to the{' '}
            <Link to="/docs">online API explorer</Link>.
          </p>
        </div>
        <div>
          <FormGroup>
            <TextField
              label="Friendly name"
              value={name}
              disabled={formComplete}
              onChange={(e) => this.setState({ name: e.target.value })}
            />
            <FormLabel>Granted scopes</FormLabel>
            <ScopeSelector
              selected={scopes}
              disabled={formComplete}
              onChange={(e) => this.setState({ scopes: e.target.value })}
            />
          </FormGroup>
        </div>
        {formComplete ? null : (
          <div>
            <Button
              disabled={createDisabled}
              color="primary"
              variant="contained"
              aria-label="create api key"
              onClick={() =>
                createKey(name, scopes).then(({ key }) => this.setState({ privateKey: key }))
              }
            >
              Create key
            </Button>
          </div>
        )}
        {this.renderPrivateKeyValue()}
      </div>
    );
  }
}

export default connect(null, (dispatch) => ({
  createKey: (name, scopes) => dispatch(createApiKeyAction(name, scopes)),
}))(KeyCreator);
