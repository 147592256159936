import React from 'react';
import { ConfirmProvider } from 'material-ui-confirm';

const ConfirmOptions = ({ children }) => (
  <ConfirmProvider
    defaultOptions={{
      confirmationButtonProps: { color: 'secondary', 'aria-label': 'confirm-ok' },
      cancellationButtonProps: { 'aria-label': 'confirm-cancel' },
    }}
  >
    {children}
  </ConfirmProvider>
);

export default ConfirmOptions;
