import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { ThemeProvider, StyledEngineProvider, Toolbar, Typography, Button } from '@mui/material';
import { Link, useNavigate } from 'react-router-dom';
import getTheme from '../../../theme';
import SettingsMenu from './SettingsMenu';
import CenterMenu from './CenterMenu';
import { RtemRoleLevels } from './roles';

import './AppHeader.css';

const NYSERDA_HEADER = {
  primary: {
    main: '#3a86ff',
  },
  secondary: {
    main: '#ffffff',
  },
};

const onLoginScreen = () => window.location.href.endsWith('/login');

const AppHeader = ({ isLoggedIn, isLoginScreen, userAuthLevel, userName, orgLogoUrl }) => {
  const toolbarStyle = {
    height: '100%',
    background: NYSERDA_HEADER.primary.main,
    minHeight: '0',
  };
  const theme = getTheme(NYSERDA_HEADER);
  const navigate = useNavigate();
  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={theme}>
        <Toolbar style={toolbarStyle} disableGutters>
          <div className="AppHeader-left desktop-only-block-display">
            {orgLogoUrl && (
              <div className="AppHeader-logo">
                <img src={orgLogoUrl} alt="organization logo" />
              </div>
            )}
            <div className="AppHeader-orgName">
              <Typography
                className="desktop-only-block-display"
                variant="h6"
                color="secondary"
                onClick={() => navigate('/')}
              >
                RTEM Portal
              </Typography>
            </div>
          </div>
          {isLoggedIn ? (
            <div className="AppHeader-center">
              {userAuthLevel !== RtemRoleLevels.ApiUser &&
              userAuthLevel !== RtemRoleLevels.HackathonUser ? (
                <CenterMenu />
              ) : null}
            </div>
          ) : null}
          <div className="AppHeader-right">
            {isLoggedIn && (
              <div>
                <div className="desktop-only-block-display">
                  <Typography variant="h6" color="secondary">
                    {`Welcome, ${userName}`}
                  </Typography>
                </div>
                <SettingsMenu />
              </div>
            )}
            {!isLoggedIn && !onLoginScreen() && !isLoginScreen && (
              <div>
                <Button color="secondary" component={Link} to="/login">
                  Login
                </Button>
              </div>
            )}
          </div>
        </Toolbar>
      </ThemeProvider>
    </StyledEngineProvider>
  );
};

AppHeader.defaultProps = {
  userName: '',
};

AppHeader.propTypes = {
  isLoggedIn: PropTypes.bool.isRequired,
  userName: PropTypes.string,
  isLoginScreen: PropTypes.bool.isRequired,
};

export default connect(
  ({ user: { userName, jwt, userAuthLevel, orgLogoUrl }, ui: { isLoginScreen } }) => ({
    isLoggedIn: jwt !== undefined,
    userName,
    isLoginScreen,
    userAuthLevel,
    orgLogoUrl,
  })
)(AppHeader);
