import React from 'react';
import { connect } from 'react-redux';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { Typography, LinearProgress } from '@mui/material';
import Directory from './Directory';

/**
 * Top level of the file viewer - handles loading states and displaying error messages
 */
const FileViewer = ({ fetching, hasFetched, error, topDirs }) => (
  <DndProvider backend={HTML5Backend}>
    <div className="directories grid">
      {topDirs.map((dir) => (
        <Directory name={dir} key={dir} />
      ))}
      <div className="errors">
        {fetching && (
          <div className="progress">
            <LinearProgress color="secondary" />
          </div>
        )}
        {!error && hasFetched && !topDirs.length && (
          <Typography variant="h4">No files present</Typography>
        )}
        {error && (
          <>
            <Typography variant="h4">There was an error loading your files</Typography>
            <Typography variant="h6" color="error">
              {error}
            </Typography>
            <p>Apologies, please try again later</p>
          </>
        )}
      </div>
    </div>
  </DndProvider>
);

export default connect(({ rtemFiles: { fetching, hasFetched, error, topDirs } }) => ({
  fetching,
  hasFetched,
  error,
  topDirs,
}))(FileViewer);
