import React, { useState } from 'react';
import { connect } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import {
  Button,
  Table,
  TableBody,
  TableCell,
  TableSortLabel,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import { Visibility } from '@mui/icons-material';
import { stableSort, compareByNumericProp } from '../../../utils/comparators';
import { browserTime } from '../../../utils/parsing';
import { focusDirAction } from '../../../redux/actions/rtemFiles';
import Target from '../../actionLog/Target';
import SubmissionStatus from './SubmissionStatus';

/* eslint-disable camelcase */

const getStatus = (statuses) => {
  // statuses is a map of status to timestamp (see rtemSubmissions)
  if (!statuses) return 'SUBMITTED';
  const { SUBMITTED, APPROVED, REJECTED, DELETED } = statuses;
  if (DELETED && DELETED > SUBMITTED) return 'DELETED';
  const latest = Math.max(SUBMITTED || null, APPROVED || null, REJECTED || null);
  if (APPROVED && latest === APPROVED) return 'APPROVED';
  if (REJECTED && latest === REJECTED) return 'REJECTED';
  return 'SUBMITTED';
};

// note: scroll-to works if the files have already been loaded but not otherwise
const scrollToPath = (path) => {
  try {
    // eslint-disable-next-line no-unused-expressions
    document.querySelector(`[data-path="${path}"`)?.scrollIntoView({ block: 'center' });
  } catch (e) {
    // browser compatibility
  }
};

const extractSubmission = (action, statusTimes) => {
  const { id, timestamp, user_id, details } = action;
  const path = details?.path;
  const splits = path?.split('/') || [];
  const site = splits[1];
  const report = splits[2];
  const status = getStatus(statusTimes[path]);
  return { id, timestamp, user_id, path, site, report, status };
};

const SubmissionsTable = ({ submissions, variant, openDir }) => {
  const navigate = useNavigate();
  const [{ order, orderBy }, setSort] = useState({ orderBy: 'timestamp', order: 'desc' });
  const comparator = compareByNumericProp(orderBy, order);
  const createSortHandler = (sortColumn) => () => {
    // first click on a column sort label sorts it by 'ascending' regardless of last order
    const isAsc = order === 'asc' && orderBy === sortColumn;
    setSort({ orderBy: sortColumn, order: isAsc ? 'desc' : 'asc' });
  };
  const sortLabel = (key, text) => {
    const isSort = orderBy === key;
    return (
      <TableSortLabel
        active={isSort}
        direction={isSort ? order : 'asc'}
        onClick={createSortHandler(key)}
      >
        {text}
      </TableSortLabel>
    );
  };
  return (
    <div className="action-table">
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>{sortLabel('timestamp', 'Submitted at')}</TableCell>
            {variant === 'ADMIN' && (
              <>
                <TableCell>Submitted by</TableCell>
                <TableCell>{sortLabel('status', 'Status')}</TableCell>
              </>
            )}
            <TableCell>{sortLabel('site', 'Site')}</TableCell>
            <TableCell>Report</TableCell>
            <TableCell />
          </TableRow>
        </TableHead>
        <TableBody>
          {stableSort(submissions, comparator).map(
            ({ id, timestamp, status, user_id, path, site, report }) => (
              <TableRow key={id} hover>
                <TableCell>{browserTime(timestamp)}</TableCell>
                {variant === 'ADMIN' && (
                  <>
                    <TableCell>
                      <Target type="user" id={user_id} />
                    </TableCell>
                    <TableCell>
                      <SubmissionStatus status={status} />
                    </TableCell>
                  </>
                )}
                <TableCell>{site}</TableCell>
                <TableCell>{report}</TableCell>
                <TableCell>
                  <Button
                    onClick={() => {
                      openDir(path);
                      navigate('/explore/files');
                      setTimeout(() => scrollToPath(path), 200);
                    }}
                    variant="outlined"
                  >
                    <Visibility className="left-icon" color="secondary" />
                    View files
                  </Button>
                </TableCell>
              </TableRow>
            )
          )}
          {submissions.length === 0 && (
            <TableRow key="none">
              <TableCell colSpan={5}>
                <Typography variant="h5">No submissions yet</Typography>
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
    </div>
  );
};

SubmissionsTable.defaultProps = {
  variant: 'USER',
};

export default connect(
  ({ actionLog: { logItems }, rtemSubmissions: { statusTimes } }) => ({
    submissions: logItems
      .filter((i) => i.action === 's3file.submit')
      .map((i) => extractSubmission(i, statusTimes))
      .filter((a) => !!a.site),
  }),
  (dispatch) => ({
    openDir: (path) => dispatch(focusDirAction(path)),
  })
)(SubmissionsTable);
