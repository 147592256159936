import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import Delete from '@mui/icons-material/Delete';

import { getDisplayRoles } from '../../../redux/selectors/users';
import { RoleSelector } from './Selectors';

import './user-roles.css';

const add = (roles, id) => {
  const ids = new Set(roles.map((r) => r.id));
  ids.add(id);
  return Array.from(ids);
};
const remove = (roles, id) => roles.filter((r) => r.id !== id).map((r) => r.id);

const UserRoles = ({ roles, onChange }) => (
  <div className="user-roles">
    <div className="roles-list">
      <Typography>Active roles</Typography>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Revoke</TableCell>
            <TableCell>Role</TableCell>
            <TableCell>Type</TableCell>
            <TableCell>Description</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {roles.map((r) => (
            <TableRow key={r.id}>
              <TableCell>
                <IconButton onClick={() => onChange(remove(roles, r.id))} size="large">
                  <Delete />
                </IconButton>
              </TableCell>
              <TableCell>{r.name}</TableCell>
              <TableCell>{r.type.name}</TableCell>
              <TableCell>{r.description}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </div>
    <div className="new-roles">
      <Typography>Add a role</Typography>
      <RoleSelector onChange={(e) => onChange(add(roles, e.target.value))} />
    </div>
  </div>
);

UserRoles.propTypes = {
  onChange: PropTypes.func.isRequired,
};

export default connect(({ users: { roles, roleTypes } }, { roleIds }) => ({
  roles: getDisplayRoles(roles, roleTypes, roleIds),
}))(UserRoles);
