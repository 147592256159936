import { connect } from 'react-redux';
import { AUTH_LEVELS } from '../../auth/Authorized';
import UnitPrefsTable from './UnitPrefsTable';

export default connect(
  ({
    entities: {
      unitPrefs: { org, user },
    },
    user: { userAuthLevel },
  }) => ({
    preferences: org,
    headerText:
      'These preferences apply to all users in your organization and can only be edited by organization admins',
    editable: userAuthLevel <= AUTH_LEVELS.ORG_ADMIN,
    type: 'ORG',
    parentPreferences: user,
  })
)(UnitPrefsTable);
