import {
  SET_ACTION_LOG_ITEMS,
  SET_ACTION_LOG_QUERY,
  SET_ACTION_TYPES,
  SET_ACTION_LOG_NAME,
} from './actiontypes';
import { fetchUserActions, fetchActionTypes, queryActions, exportActions } from '../../api/Api';
import { downloadBlob } from '../../utils/downloads';

const sendData = (type, dispatch) => (data) =>
  dispatch({
    type,
    data,
  });

const setNameActionCreator = (name) => (dispatch) => sendData(SET_ACTION_LOG_NAME, dispatch)(name);

export const fetchUserActionsActionCreator = () => (dispatch, getState) => {
  const {
    user: { jwt },
    actionLog: { queryName },
  } = getState();
  if (queryName === 'SELF') return Promise.resolve(queryName);
  return fetchUserActions(jwt)
    .then(sendData(SET_ACTION_LOG_ITEMS, dispatch))
    .then(() => dispatch(setNameActionCreator('SELF')));
};

export const fetchActionTypesActionCreator = () => (dispatch, getState) => {
  const {
    user: { jwt },
  } = getState();
  return fetchActionTypes(jwt).then(sendData(SET_ACTION_TYPES, dispatch));
};

export const queryActionsActionCreator =
  ({ name = null, force = false } = {}) =>
  (dispatch, getState) => {
    const {
      user: { jwt },
      actionLog: { query, queryName },
    } = getState();
    if (name != null && name === queryName && !force) return Promise.resolve(queryName);
    return queryActions(query, jwt)
      .then(sendData(SET_ACTION_LOG_ITEMS, dispatch))
      .then(() => dispatch(setNameActionCreator(name)));
  };

export const exportActionsActionCreator = () => (dispatch, getState) => {
  const {
    user: { jwt },
    actionLog: { query },
  } = getState();
  const allResults = { ...query, limit: null };
  return exportActions(allResults, jwt).then((blob) => downloadBlob(blob, 'action-log-export.csv'));
};

export const setQueryActionCreator = (newQuery) => (dispatch) =>
  sendData(SET_ACTION_LOG_QUERY, dispatch)(newQuery);
