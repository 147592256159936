import React from 'react';
import { connect } from 'react-redux';
import ClassNavigation from './ClassNavigation';
import ClassViewer from './ClassViewer';
import buildDataModel from './models';

import './data-model.css';

/**
 * A two-pane UI to explore the equipment model. Left nav provides links while the right
 * pane provides information about the currently selected class
 */
const ModelExplorer = ({ base, path, queryParams, model }) => (
  <div className="row grid-container-lg model-explorer">
    <div className="nav">
      <ClassNavigation base={base} model={model} path={path} queryParams={queryParams} />
    </div>
    <div className="results">
      <ClassViewer base={base} model={model} path={path} queryParams={queryParams} />
    </div>
  </div>
);

export default connect(
  ({
    entities: {
      tags: { byId: tags, byCategory: tagCategories },
      equipTypes: { byId: equipTypes },
      pointTypes: { byId: pointTypes },
      measurements: { byId: measurements },
    },
  }) => ({
    model: buildDataModel(tags, tagCategories, equipTypes, pointTypes, measurements),
  })
)(ModelExplorer);
