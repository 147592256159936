import React, { useState } from 'react';
import { Checkbox, Typography, Button } from '@mui/material';
import { NavLink, useNavigate, useLocation } from 'react-router-dom';
import { getSearchParams } from '../../utils/url';

/* eslint-disable jsx-a11y/label-has-associated-control */

export const getSelected = (val) => {
  if (val == null) return [];
  const asArray = Array.isArray(val) ? val : [val];
  return asArray.filter((t) => !!t);
};

/**
 * update url query params given a list of new [param, value] pairs
 * like: [['tag', 'air'], ['tag', 'co2']] or [['tag', '']]
 */
const updateCurrentPath = (navigate, location, newParams) => {
  const currentParams = getSearchParams(location.search);
  newParams.forEach(([key]) => delete currentParams[key]);
  const params = new URLSearchParams({ ...currentParams });
  newParams.forEach(([key, val]) => params.append(key, val));
  navigate(`${location.pathname}?${params.toString()}`);
};

/**
 * Render a nav multi-select child. Each child will have a list of options
 * which can be selected via url query params
 */
const NavMultiSelect = ({ model, param, queryParams }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const selected = getSelected(queryParams[param]);
  const [open, setOpen] = useState(true);
  const handleCheckbox = (tag) => (event) => {
    const add = event.target.checked;
    const missing = selected.indexOf(tag) === -1;
    let newSelection;
    if (add) {
      if (missing) newSelection = [tag, ...selected];
      if (model.closeAfterFirst) setOpen(false);
    } else {
      newSelection = selected.filter((t) => t !== tag);
    }
    if (newSelection.length === 0) {
      updateCurrentPath(navigate, location, [[param, '']]);
      setOpen(true);
    } else {
      newSelection.sort();
      updateCurrentPath(
        navigate,
        location,
        newSelection.map((t) => [param, t])
      );
    }
  };
  return (
    <>
      <li className="list-item">
        <Button className="multiselect-name" variant="text" onClick={() => setOpen(!open)}>
          {model.name}
        </Button>
      </li>
      <ul className="class-items">
        {model.children.map((child) => {
          const isSelected = selected.indexOf(child.link) !== -1;
          if (!isSelected && !open) return null;
          return (
            <li key={child.link} className="multiselect-item">
              <label>
                <Checkbox
                  className="multiselect-checkbox"
                  checked={isSelected}
                  onClick={handleCheckbox(child.link)}
                />
                {child.name}
              </label>
            </li>
          );
        })}
      </ul>
    </>
  );
};

/**
 * Recursively renders navigation of our model. Expands links to children if the current
 * section is active (is the first in the list of paths).
 */
const NavSection = ({
  urlBase,
  path: [first, ...rest],
  section,
  model,
  queryParams,
  isTop = false,
}) => {
  const sectionLink = isTop ? urlBase : `${urlBase}/${encodeURIComponent(section)}`;
  const isActive = first === section;
  // if active, link to the parent category (so that a double click opens and then closes a section)
  const link = (
    <NavLink end className="link" to={isActive ? urlBase : sectionLink}>
      {model.name}
    </NavLink>
  );
  return (
    <>
      {isTop ? (
        <div className="class-items top">{link}</div>
      ) : (
        <li className="list-item">{link}</li>
      )}
      {isActive && model.children && (
        <ul className="class-items">
          {model.children.map((child) =>
            child.type === 'multiselect' ? (
              <NavMultiSelect
                key={child.name}
                model={child}
                param={child.param}
                queryParams={queryParams}
              />
            ) : (
              <NavSection
                key={child.link}
                section={child.link}
                urlBase={sectionLink}
                path={rest}
                model={child}
                queryParams={queryParams}
              />
            )
          )}
        </ul>
      )}
    </>
  );
};

/**
 * Sidebar navigation for the data model viewer. 'Base' here is the url prefix of the page as defined
 * by the app router.
 */
const ClassNavigation = ({ base, path, model, queryParams }) => (
  <div className="class-nav-root">
    <Typography variant="h4" className="title">
      Data Model
    </Typography>
    <NavSection
      section="classes"
      path={['classes', ...path]}
      urlBase={base}
      model={model}
      isTop
      queryParams={queryParams}
    />
  </div>
);

export default ClassNavigation;
