import { combineReducers } from 'redux';
import { ADD_MULTIPLE_EQUIPMENT_AND_POINTS, DELETE_ALL_EQUIPMENT } from '../actions/actiontypes';

// eslint-disable-next-line no-unused-vars
function addMultiplePointsToEquipment(state, action) {
  const newState = { ...state };
  for (let i = 0; i < action.points.length; i += 1) {
    // get the point
    const point = action.points[i];
    // get the corresponding equip and add the pointID to its pointIds list
    const equip = newState[point.equipId];
    equip.pointIds.push(point.id);
    equip.pointIds = Array.from(new Set(equip.pointIds));
    newState[equip.id] = equip;
  }
  return newState;
}

function addOrUpdateEquipment(state, equipments) {
  // return the state with the multiple equip added/replaced
  const newState = { ...state };
  for (let i = 0; i < equipments.length; i += 1) {
    const equipment = equipments[i];
    // note: this is brittle, should change the API to always return point ids
    const pointIds = equipment.points
      ? equipment.points.map((p) => p.id)
      : newState[equipment.id]?.pointsIds || [];
    newState[equipment.id] = {
      id: equipment.id,
      buildingId: equipment.buildingId,
      suffix: equipment.suffix,
      typeName: equipment.typeName,
      typeId: equipment.typeId,
      subTypeId: equipment.subTypeId,
      typeAbbr: equipment.typeAbbr,
      flowOrder: equipment.flowOrder,
      floorNumPhys: equipment.floorNumPhys,
      floorNumServed: equipment.floorNumServed,
      parents: equipment.parents,
      children: equipment.children,
      editedEquipType: equipment.typeName || '',
      pointIds,
      tags: equipment.tags,
    };
  }
  return newState;
}

function equipmentById(state = {}, action) {
  switch (action.type) {
    case DELETE_ALL_EQUIPMENT:
      return {};
    case ADD_MULTIPLE_EQUIPMENT_AND_POINTS:
      return addOrUpdateEquipment(state, action.equipment);
    default:
      return state;
  }
}

function allEquipmentIds(state = [], action) {
  switch (action.type) {
    case DELETE_ALL_EQUIPMENT:
      return [];
    case ADD_MULTIPLE_EQUIPMENT_AND_POINTS:
      return state.concat(action.equipment.map((e) => e.id));
    default:
      return state;
  }
}

// A reducer function which describes how the application state tree
// should be updated in response to equipment actions
// (for the equipment 'slice'of the tree)
const equipmentReducer = combineReducers({
  byId: equipmentById,
  allIds: allEquipmentIds,
});

export default equipmentReducer;
