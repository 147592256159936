import React from 'react';
import PropTypes from 'prop-types';
import IconButton from '@mui/material/IconButton';
import ExpandMore from '@mui/icons-material/ExpandMore';
import ExpandLess from '@mui/icons-material/ExpandLess';

import './collapsible.css';

/**
 * Component to expand/collapse a child (probably a long list)
 */
class Collapsible extends React.PureComponent {
  state = {
    expanded: null,
  };

  isExpanded() {
    const { expanded } = this.state;
    return expanded != null ? expanded : this.props.defaultOpen;
  }

  renderToggle() {
    const expanded = this.isExpanded();
    return (
      <IconButton onClick={() => this.setState({ expanded: !expanded })} size="large">
        {expanded ? <ExpandLess /> : <ExpandMore />}
      </IconButton>
    );
  }

  render() {
    return (
      <div className="collapsible">
        <div className="header-content">
          {this.props.headerContent}
          {this.renderToggle()}
        </div>
        <div className={`expanded-content ${this.isExpanded() ? 'shown' : 'hidden'}`}>
          {this.props.children}
        </div>
      </div>
    );
  }
}

Collapsible.propTypes = {
  children: PropTypes.node.isRequired,
  defaultOpen: PropTypes.bool,
  headerContent: PropTypes.node,
};

Collapsible.defaultProps = {
  defaultOpen: true,
  headerContent: '',
};

export default Collapsible;
