import React from 'react';
import { connect } from 'react-redux';
import { Typography, LinearProgress } from '@mui/material';
import MfaCreators from '../account/MfaCreators';
import './LoginView.css';

const MfaRegistrationRequired = ({ loaded }) => (
  <div className="LoginView-center mfa-required-registration">
    <div className="LoginView-col">
      {loaded ? (
        <div>
          <Typography variant="h4">Multi-factor auth is required for your account</Typography>
          <p>Please register a second authentication device from one of the options below</p>
          <MfaCreators />
        </div>
      ) : (
        <div className="LoginView-progress">
          <LinearProgress color="secondary" />
        </div>
      )}
    </div>
  </div>
);

export default connect(
  ({
    user: {
      mfaLoaded: { totp, fido2 },
    },
  }) => ({
    loaded: totp && fido2,
  })
)(MfaRegistrationRequired);
