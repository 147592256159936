import React from 'react';
import { connect } from 'react-redux';
import TextField from '@mui/material/TextField';

import OrganizationSelector from '../OrganizationSelector';
import { RoleTypeSelector } from './Selectors';
import RolePermissions from './RolePermissions';

import './role-edit.css';

/* eslint-disable camelcase */

const RoleEdit = ({ data, onChange }) => {
  const { id, name, description, org_id, role_type_id, editable_permissions } = data;
  const handleChange = (fieldName) => (event) =>
    onChange({ ...data, [fieldName]: event.target.value });
  return (
    <div className="role-edit">
      <form noValidate autoComplete="off">
        <div className="line">
          <TextField label="Role id" value={id < 1 ? 'New Role' : id} disabled />
          <TextField label="Role name" value={name} onChange={handleChange('name')} />
        </div>
        <div className="line">
          <OrganizationSelector allowNulls selected={org_id} onChange={handleChange('org_id')} />
        </div>
        <div className="line">
          <RoleTypeSelector
            disabled={id > 0}
            selected={`${role_type_id}`}
            onChange={handleChange('role_type_id')}
          />
        </div>
        <div className="line">
          <TextField
            className="long-field"
            label="Role description"
            value={description}
            onChange={handleChange('description')}
          />
        </div>
        <div className="line">
          <RolePermissions key={id} role={id} editable={editable_permissions} />
        </div>
      </form>
    </div>
  );
};

export default connect(
  ({
    users: {
      editing: { data },
    },
  }) => ({ data })
)(RoleEdit);
