import React from 'react';
import PropTypes from 'prop-types';
import { FormControl, InputLabel, MenuItem, Select } from '@mui/material';

/**
 * Generic controlled selector component via material-ui
 */
const Selector = ({
  options,
  selected,
  onChange,
  showNullOption,
  multiple,
  disabled,
  renderValue,
  displayEmpty,
  className = 'ob-selector',
  label = '',
}) => (
  <FormControl className={className}>
    {label ? <InputLabel>{label}</InputLabel> : null}
    <Select
      multiple={multiple}
      value={options.length && selected ? selected : ''}
      onChange={onChange}
      disabled={disabled || (!!selected && options.length < 2)}
      renderValue={renderValue}
      displayEmpty={displayEmpty}
    >
      {showNullOption ? (
        <MenuItem key="nullval" value={null}>
          ---
        </MenuItem>
      ) : null}
      {options.map(({ key, id, display }) => (
        <MenuItem key={key} value={id} className={`${className}-option`}>
          {display}
        </MenuItem>
      ))}
    </Select>
  </FormControl>
);

Selector.defaultProps = {
  selected: null,
  showNullOption: false,
  multiple: false,
  disabled: false,
};

Selector.propTypes = {
  onChange: PropTypes.func.isRequired,
  selected: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string,
    PropTypes.arrayOf(PropTypes.string),
    PropTypes.arrayOf(PropTypes.number),
  ]),
  options: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
      id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
      display: PropTypes.string.isRequired,
    })
  ).isRequired,
  showNullOption: PropTypes.bool,
  multiple: PropTypes.bool,
  disabled: PropTypes.bool,
};

export default Selector;
