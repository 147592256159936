import React from 'react';
import { Description } from '@mui/icons-material';
import './support.css';

const Support = () => (
  <div className="row grid-container-lg support">
    <div className="full">
      <p>Please find the user manual linked below:</p>
      <div className="guide-link">
        <span className="icon">
          <Description />
        </span>
        <a href="/static/assets/RTEM_Vendor_Portal_Guide.pdf">RTEM Vendor Portal Guide</a>
      </div>
      <p>
        For support please contact <a href="mailto:support@ny-rtem.com">support@ny-rtem.com</a>
      </p>
      <p>Thanks,</p>
      <p>RTEM Team</p>
    </div>
  </div>
);

export default Support;
