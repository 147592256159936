import React from 'react';
import PropTypes from 'prop-types';
import { QRCodeSVG } from 'qrcode.react';
import { Typography, Button } from '@mui/material';

const SECRET_REG = /secret=([\w]+)/;

class SecretDisplay extends React.PureComponent {
  state = {
    showQr: true,
  };

  secretText = () => {
    const { secretUri } = this.props;
    const match = secretUri.match(SECRET_REG);
    if (!match) return secretUri;
    return match[1];
  };

  render() {
    const { secretUri } = this.props;
    const { showQr } = this.state;
    return (
      <div>
        <Typography variant="h6">Use this secret to set up your app</Typography>
        <Button onClick={() => this.setState({ showQr: !showQr })}>
          {showQr ? 'Show secret as text' : 'Show secret as QR code'}
        </Button>
        <br />
        {showQr ? (
          <QRCodeSVG value={secretUri} />
        ) : (
          <span className="device-name">{this.secretText()}</span>
        )}
      </div>
    );
  }
}

SecretDisplay.propTypes = {
  secretUri: PropTypes.string.isRequired,
};

export default SecretDisplay;
