import React from 'react';
import { connect } from 'react-redux';
import { Typography, Checkbox, Button } from '@mui/material';
import { Unsubscribe, Drafts } from '@mui/icons-material';
import {
  changeNotificationPrefAction,
  subscribeAllAction,
  unsubscribeAllAction,
} from '../../redux/actions/rtemFiles';
import NotificationPrefsLoader from '../loaders/NotificationPrefsLoader';

import './notifications.css';

const Notifications = ({ notificationPreferences, changePref, unsubscribeAll, subscribeAll }) => (
  <div className="account-notifications">
    <NotificationPrefsLoader />
    <Typography variant="h4">Notification Preferences</Typography>
    <p>Receive data submission emails from the following vendor organizations:</p>
    <div className="prefs-list">
      {Object.entries(notificationPreferences).map(([key, value]) => (
        <div key={key}>
          <Checkbox
            name={`receive-${key}`}
            checked={value}
            onChange={() => changePref(key, !value)}
          />
          <span>{key}</span>
        </div>
      ))}
    </div>
    <div className="all-actions">
      <Button variant="outlined" onClick={unsubscribeAll}>
        <Unsubscribe className="left-icon" color="secondary" />
        Unsubscribe from all
      </Button>
      <Button variant="outlined" onClick={subscribeAll}>
        <Drafts className="left-icon" color="secondary" />
        Re-subscribe to all
      </Button>
    </div>
  </div>
);

export default connect(
  ({ entities: { notificationPreferences } }) => ({ notificationPreferences }),
  (dispatch) => ({
    subscribeAll: () => dispatch(subscribeAllAction()),
    unsubscribeAll: () => dispatch(unsubscribeAllAction()),
    changePref: (affiliation, willReceive) =>
      dispatch(changeNotificationPrefAction(affiliation, willReceive)),
  })
)(Notifications);
