import React from 'react';
import { Grid, Typography } from '@mui/material';

const DATA = {
  'floor-num-physical': {
    title: 'Floor Number Physical',
    unit: 'Integer',
    desc: 'This attribute describes the physical location for a given piece of equipment and is expressed as an integer floor number',
  },
  'floor-num-served': {
    title: 'Floor Number Served',
    unit: 'Integer',
    desc: 'This attribute describes the location a given piece of equipment is serving an is expressed as an integer floor number.',
  },
  'area-served-desc': {
    title: 'Area Served Desc',
    unit: 'Text',
    desc: 'This attribute provides a plain text description of the area served by a given piece of equipment.',
  },
};

const Location = ({ attr }) => (
  <div className="leaf location">
    <Typography variant="h5" className="name">
      {DATA[attr].title}
    </Typography>

    <Grid item xs={2}>
      <Typography variant="h6">Units:</Typography>
    </Grid>
    <Grid item xs={8} className="static-attr">
      <p>{DATA[attr].unit}</p>
    </Grid>

    <Grid item xs={2}>
      <Typography variant="h6">Description:</Typography>
    </Grid>
    <Grid item xs={8} className="static-attr">
      <p>{DATA[attr].desc}</p>
    </Grid>
  </div>
);

export default Location;
