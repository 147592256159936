import React from 'react';
import App from './AppSelector';
import ErrorBoundary from './ErrorBoundary';
import ThemedComponent from './ThemedComponent';
import ConfirmOptions from './ConfirmOptions';

const Entrypoint = () => (
  <ThemedComponent>
    <ErrorBoundary>
      <ConfirmOptions>
        <App />
      </ConfirmOptions>
    </ErrorBoundary>
  </ThemedComponent>
);

export default Entrypoint;
