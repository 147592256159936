import React from 'react';
import PropTypes from 'prop-types';
import { NavLink } from 'react-router-dom';
import { Button, TextField, Checkbox, LinearProgress, Typography } from '@mui/material';
import './LoginView.css';

/* eslint-disable jsx-a11y/label-has-associated-control, jsx-a11y/label-has-for */

const LoginImage = ({ children }) =>
  process.env.REACT_APP_WHICH === 'nyserda' ? (
    children
  ) : (
    <div className="login-container">
      {children}
      <div className="login-image-container">
        <img
          className="login-image"
          src="/login_logo_02.svg"
          alt="Shape the world's buildings with data"
        />
      </div>
    </div>
  );

const LoginView = ({
  onLoginFieldChange,
  remember,
  onRememberChange,
  onLoginSubmit,
  onEnter,
  isLoading,
  showError,
  forcedLogout,
  networkError,
}) => (
  <LoginImage>
    <div className="LoginView-center">
      <div className="LoginView-col">
        <div>
          <TextField
            name="email"
            label="Email Address"
            type="email"
            onChange={onLoginFieldChange}
            onKeyPress={onEnter}
            style={{ width: '256px' }}
          />
        </div>
        <br />
        <div>
          <TextField
            name="password"
            type="password"
            label="Password"
            onChange={onLoginFieldChange}
            onKeyPress={onEnter}
            style={{ width: '256px' }}
          />
        </div>
        <br />
        {networkError && (
          <div className="LoginView-error">
            <p>Unable to log in at this time, please try again later.</p>
          </div>
        )}
        {!networkError && showError && (
          <div className="LoginView-error">
            <p>Invalid email or password. Please try again.</p>
          </div>
        )}
        {forcedLogout && <Typography>Your session expired. Please login to continue.</Typography>}
        <br />
        {!isLoading && (
          <>
            <div>
              <label>
                Remember Device?
                <Checkbox name="rememberDevice" checked={remember} onChange={onRememberChange} />
              </label>
            </div>
            <div>
              <Button className="login-button" variant="contained" onClick={onLoginSubmit}>
                Login
              </Button>
            </div>
          </>
        )}
        {isLoading && (
          <div className="LoginView-progress">
            <LinearProgress color="secondary" />
          </div>
        )}
        <div>
          <br />
          <NavLink to="/password-reset">Forgot password?</NavLink>
        </div>
      </div>
    </div>
  </LoginImage>
);

LoginView.propTypes = {
  onEnter: PropTypes.func.isRequired,
  onLoginFieldChange: PropTypes.func.isRequired,
  onRememberChange: PropTypes.func.isRequired,
  remember: PropTypes.bool.isRequired,
  onLoginSubmit: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
  showError: PropTypes.bool.isRequired,
  forcedLogout: PropTypes.bool.isRequired,
  networkError: PropTypes.bool.isRequired,
};

export default LoginView;
