import { buildLoader } from '../util/GenericLoader';
import {
  fetchUserActionsActionCreator,
  fetchActionTypesActionCreator,
  queryActionsActionCreator,
} from '../../redux/actions/actionLog';

export const UserActionsLoader = buildLoader(fetchUserActionsActionCreator);

export const ActionLogTypesLoader = buildLoader(fetchActionTypesActionCreator);

export const ActionSearchLoader = buildLoader(queryActionsActionCreator);
