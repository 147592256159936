import {
  RTEM_FILES_FETCH_START,
  RTEM_FILES_FETCH_END,
  RTEM_FILES_FETCH_ERROR,
  RTEM_FILES_OPEN_DIR,
  RTEM_FILES_SELECT,
} from '../actions/actiontypes';
import parseFilesystem from './filesystem';

const INITIAL_STATE = {
  fetching: false,
  hasFetched: false,
  filesystem: {},
  topDirs: [],
  error: '',
  openDirs: {},
  selected: '',
  homedir: '',
};

/**
 * Initially open any directory that is alone at its level of hierarchy
 */
const initialOpenDirs = (siblingDirs, filesystem, open = {}) => {
  if (siblingDirs.length === 1) {
    const name = siblingDirs[0];
    // eslint-disable-next-line no-param-reassign
    open[name] = true;
    const dir = filesystem[name];
    initialOpenDirs(dir.childDirs, filesystem, open);
  }
  return open;
};

const rtemFiles = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case RTEM_FILES_FETCH_START:
      return {
        ...state,
        fetching: true,
        error: '',
      };
    case RTEM_FILES_FETCH_END: {
      const { topDirs, filesystem } = parseFilesystem(action.data);
      const preserveOpenDirs = state.hasFetched || Object.keys(state.openDirs).length > 0;
      return {
        ...state,
        fetching: false,
        hasFetched: true,
        error: '',
        topDirs,
        filesystem,
        openDirs: preserveOpenDirs ? state.openDirs : initialOpenDirs(topDirs, filesystem),
        homedir: action.data.command.path,
      };
    }
    case RTEM_FILES_FETCH_ERROR:
      return {
        ...state,
        fetching: false,
        hasFetched: true,
        filesystem: {},
        topDirs: [],
        error: action.error,
      };
    case RTEM_FILES_OPEN_DIR:
      return {
        ...state,
        openDirs: {
          ...state.openDirs,
          [action.dir]: action.isOpen,
        },
      };
    case RTEM_FILES_SELECT:
      return {
        ...state,
        selected: action.target,
      };
    default:
      return state;
  }
};

// supports dead-code-elim in the portal app
export default process.env.REACT_APP_WHICH === 'nyserda' ? rtemFiles : (state = null) => state;
