import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

/**
 * Helper for kicking off a redux data load when a certain part of the app
 * is rendered. See AttachmentsLoader or UsersLoader for usage
 */
class GenericLoader extends React.Component {
  componentDidMount() {
    this.props.fetchFunction();
  }

  render() {
    return null;
  }
}

GenericLoader.propTypes = {
  fetchFunction: PropTypes.func.isRequired,
};

export default GenericLoader;

/**
 * Helper function to cut down on boilerplate when making a new loader whose
 * fetch function doesn't take any arguments
 */
export const buildLoader = (fetchActionCreator) =>
  connect(null, (dispatch) => ({
    fetchFunction: () => dispatch(fetchActionCreator()),
  }))(GenericLoader);
