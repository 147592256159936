import React from 'react';
import { connect } from 'react-redux';
import { TextField, Checkbox } from '@mui/material';

import './org-edit.css';

/* eslint-disable camelcase, jsx-a11y/label-has-associated-control */

const OrgEdit = ({ data, onChange }) => {
  const {
    id,
    name,
    short_name,
    name_abbr,
    primary_color,
    secondary_color,
    logo_url,
    mfa_required,
    ingest_alerts,
  } = data;
  const handleChange = (fieldName) => (event) =>
    onChange({ ...data, [fieldName]: event.target.value });
  const handleCheckbox = (fieldName) => (event) =>
    onChange({ ...data, [fieldName]: event.target.checked });
  return (
    <div className="org-edit" key={id}>
      <form noValidate autoComplete="off">
        <div className="line">
          <TextField label="Org id" value={id < 1 ? 'New Org' : id} disabled />
          <TextField label="Org name" defaultValue={name} onChange={handleChange('name')} />
        </div>
        <div className="line">
          <TextField
            label="Short name"
            defaultValue={short_name}
            onChange={handleChange('short_name')}
          />
        </div>
        <div className="line">
          <TextField
            label="Abbreviated name"
            defaultValue={name_abbr}
            onChange={handleChange('name_abbr')}
          />
        </div>
        <div className="line">
          <label>
            MFA required?
            <Checkbox checked={mfa_required} onChange={handleCheckbox('mfa_required')} />
          </label>
        </div>
        <div className="line">
          <label>
            Ingest data alerts enbled?
            <Checkbox checked={ingest_alerts} onChange={handleCheckbox('ingest_alerts')} />
          </label>
        </div>
        <div className="line">
          <TextField
            label="Primary color"
            defaultValue={primary_color}
            onChange={handleChange('primary_color')}
          />
          <TextField
            label="Secondary color"
            defaultValue={secondary_color}
            onChange={handleChange('secondary_color')}
          />
        </div>
        <div className="line">
          <TextField
            className="long-field"
            inputProps={{ maxLength: 64 }}
            label="Logo url"
            defaultValue={logo_url}
            onChange={handleChange('logo_url')}
          />
        </div>
      </form>
    </div>
  );
};

export default connect(
  ({
    users: {
      editing: { data },
    },
  }) => ({ data })
)(OrgEdit);
