import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { TextField, Checkbox, FormLabel, FormGroup, Button, Typography } from '@mui/material';
import { dateTime } from '../../utils/parsing';
import { sendWelcomeEmail as swe } from '../../redux/actions/users';
import { deleteUserFido2ActionCreator, deleteUserTotpActionCreator } from '../../redux/actions/mfa';
import UserRoles from './roles/UserRoles';
import OrganizationSelector from './OrganizationSelector';
import WelcomeVariantSelector from './WelcomeVariantSelector';
import { Fido2, Totp } from '../account/MfaDevices';

import './user-edit.css';

/* eslint-disable camelcase, jsx-a11y/label-has-for */

/**
 * Controlled component for viewing/editing a user in the system. Supports several
 * variants depending on the page it's used on.
 */
const UserEdit = ({ variant, data, onChange, sendWelcomeEmail, deleteTotp, deleteFido2 }) => {
  const {
    id,
    email,
    first_name,
    last_name,
    org_id,
    last_login,
    roles,
    active,
    new_password,
    send_welcome,
    username,
    attrs,
    welcome_variant,
    totp,
    fido2,
  } = data;
  const loginDisplay = last_login ? dateTime(last_login) : 'Unknown';
  const handleChange = (name) => (event) => onChange({ ...data, [name]: event.target.value });
  const handleActive = ({ target: { checked } }) => onChange({ ...data, active: checked });
  const handleWelcome = ({ target: { checked } }) => onChange({ ...data, send_welcome: checked });
  const handleRoles = (newRoles) => onChange({ ...data, roles: newRoles });
  const handleAttr = (key) => (event) =>
    onChange({ ...data, attrs: { ...attrs, [key]: event.target.value } });
  const [attrKey, setNewAttr] = useState('');
  // these are a little bit of a hack, we run the delete through a separate API so to keep the UI
  // in sync we have to filter out the deleted MFA device manually
  const onDeleteTotp = (userId) => (n) => {
    deleteTotp(userId, n);
    onChange({ ...data, totp: totp.filter(({ name }) => name !== n) });
  };
  const onDeleteFido2 = (userId) => (n) => {
    deleteFido2(userId, n);
    onChange({ ...data, fido2: fido2.filter(({ key }) => key !== n) });
  };
  return (
    <div className="user-edit" key={id}>
      <form noValidate autoComplete="off">
        {variant !== 'SELF' ? (
          <div className="line">
            <TextField label="User id" value={id < 1 ? 'New User' : id} disabled />
            <TextField label="Last login" disabled value={loginDisplay} />
            {/*  eslint-disable-next-line jsx-a11y/label-has-associated-control */}
            <label>
              Active?
              <Checkbox checked={active} onChange={handleActive} />
            </label>
          </div>
        ) : null}
        <div className="line">
          <TextField
            className="email-field"
            label="Email"
            type="email"
            defaultValue={email || ''}
            onChange={handleChange('email')}
            disabled={variant === 'SELF'}
            helperText="Used to log in to the Portal"
          />
        </div>
        <div className="line">
          <TextField
            className="first-name-field r-margin"
            label="First name"
            defaultValue={first_name || ''}
            onChange={handleChange('first_name')}
          />
          <TextField
            label="Last name"
            defaultValue={last_name || ''}
            onChange={handleChange('last_name')}
          />
        </div>
        <div className="line">
          <TextField
            className="username-field"
            inputProps={{ maxLength: 32 }}
            label="Username"
            defaultValue={username || ''}
            onChange={handleChange('username')}
          />
        </div>
        {(variant === 'ADMIN' || variant === 'ORG') && (
          <div className="line">
            <FormLabel>Organization</FormLabel>
            <FormGroup>
              <OrganizationSelector
                disabled={variant === 'ORG'}
                selected={`${org_id}`}
                onChange={handleChange('org_id')}
              />
            </FormGroup>
          </div>
        )}
        {id >= 0 && (
          <div className="line">
            <TextField
              className="pw-field"
              label="Change password"
              defaultValue={new_password || ''}
              onChange={handleChange('new_password')}
            />
          </div>
        )}
        {variant === 'ADMIN' && (
          <div className="line">
            <FormLabel>Attributes</FormLabel>
            <FormGroup>
              <div className="line">
                {Object.entries(attrs).map(([key, value]) => (
                  <TextField
                    key={key}
                    className="r-margin"
                    label={key}
                    value={value}
                    onChange={handleAttr(key)}
                  />
                ))}
              </div>
              <div className="line">
                <TextField
                  className="r-margin"
                  label="Attribute name"
                  value={attrKey}
                  onChange={(e) => setNewAttr(e.target.value)}
                />
                <Button
                  color="primary"
                  variant="contained"
                  onClick={() => {
                    handleAttr(attrKey)({ target: { value: '' } });
                    setNewAttr('');
                  }}
                >
                  Add New Attribute
                </Button>
              </div>
            </FormGroup>
          </div>
        )}
      </form>
      {variant !== 'SELF' && <UserRoles roleIds={roles} onChange={handleRoles} />}
      {variant !== 'SELF' && id >= 0 && (
        <div className="line">
          <Typography>MFA Devices</Typography>
          {totp.map(({ name, created }) => (
            <div className="line" key={name}>
              <Totp name={name} created={created} deleteTotp={onDeleteTotp(id)} />
            </div>
          ))}
          {fido2.map(({ key, created }) => (
            <div className="line" key={key}>
              <Fido2 key={key} name={key} created={created} deleteFido2={onDeleteFido2(id)} />
            </div>
          ))}
          {fido2.length === 0 && totp.length === 0 && (
            <div className="line">No active MFA devices</div>
          )}
          <br />
        </div>
      )}
      {variant !== 'SELF' && (
        <div className="line">
          {id < 0 ? (
            // eslint-disable-next-line jsx-a11y/label-has-associated-control
            <label>
              Send welcome email upon creation?
              <Checkbox checked={send_welcome} onChange={handleWelcome} />
            </label>
          ) : (
            <Button
              onClick={() => sendWelcomeEmail(welcome_variant)}
              color="primary"
              variant="contained"
              className="r-margin"
            >
              Send welcome email
            </Button>
          )}
          <FormLabel className="r-margin">Email template:</FormLabel>
          <WelcomeVariantSelector
            selected={welcome_variant}
            onChange={handleChange('welcome_variant')}
          />
        </div>
      )}
    </div>
  );
};

UserEdit.propTypes = {
  variant: PropTypes.oneOf(['ADMIN', 'ORG', 'SELF']).isRequired,
  onChange: PropTypes.func.isRequired,
};

export default connect(
  ({
    users: {
      editing: { data },
    },
  }) => ({ data }),
  (dispatch) => ({
    sendWelcomeEmail: (variant) => dispatch(swe(variant)),
    deleteTotp: (userId, name) => dispatch(deleteUserTotpActionCreator(userId, name)),
    deleteFido2: (userId, key) => dispatch(deleteUserFido2ActionCreator(userId, key)),
  })
)(UserEdit);
