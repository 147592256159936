import { AUTH_LEVELS } from '../../../redux/constants/roles';

// Some major overloading of auth levels for nyserda, but as long as Nyserda doesn't add additional user or
// org_user roles that need to be handled differently, then this is fine
export const RtemRoleLevels = {
  NyserdaEmployee: AUTH_LEVELS.ORG_ADMIN,
  NyserdaVendor: AUTH_LEVELS.NO_PORTAL_ACCESS,
  ApiUser: AUTH_LEVELS.ORG_USER,
  HackathonUser: AUTH_LEVELS.USER,
};
