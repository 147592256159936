import React, { Suspense, lazy } from 'react';
import { LinearProgress } from '@mui/material';

import 'swagger-ui-react/swagger-ui.css';

const ReactSwaggerUi = lazy(() => import('swagger-ui-react'));

/**
 * Helper to lazy-load the swagger-ui in order to facilitate code splitting
 */
const SwaggerUi = (props) => (
  <Suspense fallback={<LinearProgress color="secondary" />}>
    {/* eslint-disable-next-line react/jsx-props-no-spreading */}
    <ReactSwaggerUi {...props} />
  </Suspense>
);

export default SwaggerUi;
