import { combineReducers } from 'redux';
import {
  ADD_BUILDINGS,
  ADD_MULTIPLE_EQUIPMENT_AND_POINTS,
  INVALIDATE_BUILDINGS,
  INVALIDATE_BUILDING,
  DELETE_ALL_BUILDINGS,
  START_BUILDINGS_LOAD,
} from '../actions/actiontypes';

function addMultipleEquipmentToBuildings(state, action) {
  const newState = { ...state };
  const changed = new Set();
  for (let i = 0; i < action.equipment.length; i += 1) {
    const equip = action.equipment[i];
    changed.add(equip.buildingId);
    // get the corresponding building and add the equipmentId to its ids list
    if (!newState[equip.buildingId]) {
      newState[equip.buildingId] = { ids: [] };
    }
    const building = newState[equip.buildingId];
    building.ids.push(equip.id);
    building.isInvalid = false;
    building.receivedAt = Date.now();
  }
  changed.forEach((bid) => {
    newState[bid].ids = Array.from(new Set(newState[bid].ids));
  });
  return newState;
}

const invalidateEquipment = (state, action) => {
  const newState = { ...state };
  newState[action.buildingId].isInvalid = true;
  return newState;
};

const equipment = (state = {}, action) => {
  switch (action.type) {
    case ADD_MULTIPLE_EQUIPMENT_AND_POINTS:
      return addMultipleEquipmentToBuildings(state, action);
    case INVALIDATE_BUILDING:
      return invalidateEquipment(state, action);
    default:
      return state;
  }
};

export function buildingsById(state = {}, action) {
  switch (action.type) {
    case DELETE_ALL_BUILDINGS:
      return {};
    case ADD_BUILDINGS: {
      const newState = { ...state };
      for (let i = 0; i < action.buildings.length; i += 1) {
        const building = action.buildings[i];
        newState[building.id] = {
          id: building.id,
          orgId: building.org_id,
          name: building.name,
          status: building.status,
          address: building.address,
          sqFt: building.sq_ft,
          imgSrc: building.image_src,
          bmsManufacturer: building.bms_manufacturer,
          bmsName: building.bms_product_name,
          bmsVersion: building.bms_version,
          equipCount: building.equip_count,
          pointCount: building.point_count,
          uploadIds: [],
          uploadsIsInvalid: false,
          uploadsReceivedAt: undefined,
          timezone: building.timezone,
          info: building.info,
        };
      }
      return newState;
    }
    default:
      return state;
  }
}

export function allBuildingIds(state = [], action) {
  switch (action.type) {
    case DELETE_ALL_BUILDINGS:
      return [];
    case ADD_BUILDINGS:
      return state.concat(action.buildings.map((b) => b.id));
    default:
      return state;
  }
}

export function buildingsMetaData(
  state = {
    isInvalid: false,
    receivedAt: undefined,
    isLoading: false,
  },
  action
) {
  switch (action.type) {
    case DELETE_ALL_BUILDINGS:
      return {
        isInvalid: true,
        receivedAt: undefined,
        isLoading: false,
      };
    case ADD_BUILDINGS:
      return { ...state, isInvalid: false, receivedAt: action.receivedAt, isLoading: false };
    case INVALIDATE_BUILDINGS:
      return { ...state, isInvalid: true, isLoading: false };
    case START_BUILDINGS_LOAD:
      return { ...state, isLoading: true };
    default:
      return state;
  }
}

// A reducer function which describes how the application state tree
// should be updated in response to building actions
// (for the buildings 'slice'of the tree)
const buildingsReducer = combineReducers({
  byId: buildingsById,
  allIds: allBuildingIds,
  meta: buildingsMetaData,
  equipment,
});

export default buildingsReducer;
