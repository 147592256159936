import { UPDATE_MFA } from './actiontypes';
import {
  fetchTotp,
  createTotp,
  deleteTotp,
  confirmTotp,
  fetchFido2,
  deleteFido2,
  startFido2Setup,
  completeFido2Setup,
  deleteUserFido2,
  deleteUserTotp,
} from '../../api/Api';
import { fetchUserActionsActionCreator } from './actionLog';
import { fetchUsersAction } from './users';

export const fetchTotpActionCreator =
  (passive = false) =>
  (dispatch, getState) => {
    const {
      user: {
        jwt,
        partialLoginToken,
        mfaLoaded: { totp },
      },
    } = getState();
    if (passive && totp) return Promise.resolve('ok');
    return fetchTotp(jwt || partialLoginToken).then((res) => {
      dispatch({
        type: UPDATE_MFA,
        mfaType: 'totp',
        devices: res,
      });
    });
  };

export const deleteTotpActionCreator = (name) => (dispatch, getState) => {
  const {
    user: { jwt },
  } = getState();
  return deleteTotp(name, jwt).then(() => {
    dispatch(fetchTotpActionCreator());
    dispatch(fetchUserActionsActionCreator());
  });
};

export const confirmTotpActionCreator = (name, password) => (dispatch, getState) => {
  const {
    user: { jwt },
  } = getState();
  return confirmTotp(name, password, jwt).then(() => {
    dispatch(fetchTotpActionCreator());
    dispatch(fetchUserActionsActionCreator());
  });
};

export const createTotpActionCreator = (name) => (dispatch, getState) => {
  const {
    user: { jwt },
  } = getState();
  return createTotp(name, jwt);
};

export const fetchFido2ActionCreator =
  (passive = false) =>
  (dispatch, getState) => {
    const {
      user: {
        jwt,
        partialLoginToken,
        mfaLoaded: { fido2 },
      },
    } = getState();
    if (passive && fido2) return Promise.resolve('ok');
    return fetchFido2(jwt || partialLoginToken).then((res) => {
      dispatch({
        type: UPDATE_MFA,
        mfaType: 'fido2',
        devices: res,
      });
    });
  };

export const deleteFido2ActionCreator = (key) => (dispatch, getState) => {
  const {
    user: { jwt },
  } = getState();
  return deleteFido2(key, jwt).then(() => {
    dispatch(fetchFido2ActionCreator());
    dispatch(fetchUserActionsActionCreator());
  });
};

export const startFido2SetupActionCreator = () => (dispatch, getState) => {
  const {
    user: { jwt },
  } = getState();
  return startFido2Setup(jwt);
};

export const completeFido2SetupActionCreator = (data) => (dispatch, getState) => {
  const {
    user: { jwt },
  } = getState();
  return completeFido2Setup(data, jwt).then(() => {
    dispatch(fetchFido2ActionCreator());
    dispatch(fetchUserActionsActionCreator());
  });
};

// Admin actions to modify MFA devices on a user's behalf:

export const deleteUserTotpActionCreator = (userId, name) => (dispatch, getState) => {
  const {
    user: { jwt },
  } = getState();
  return deleteUserTotp(userId, name, jwt).then(() => {
    dispatch(fetchUsersAction());
    dispatch(fetchUserActionsActionCreator());
  });
};

export const deleteUserFido2ActionCreator = (userId, key) => (dispatch, getState) => {
  const {
    user: { jwt },
  } = getState();
  return deleteUserFido2(userId, key, jwt).then(() => {
    dispatch(fetchUsersAction());
    dispatch(fetchUserActionsActionCreator());
  });
};
