import React from 'react';
import { Grid } from '@mui/material';

const AttrGrid = ({ children }) => (
  <Grid container direction="row" spacing={2} alignItems="center" className="attr-grid">
    {children}
  </Grid>
);

export default AttrGrid;
