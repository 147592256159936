import React from 'react';
import { connect } from 'react-redux';
import { Typography } from '@mui/material';
import UnitsViewer from './attrs/UnitsViewer';
import MeasurementViewer from './attrs/MeasurementViewer';
import TagsViewer from './attrs/TagsViewer';
import { restorePtName } from '../models';

const PointType = ({ base, ptName, tags, measurementId, defaultUnitId }) => (
  <div className="leaf point-type">
    <Typography variant="h5" className="name">
      {ptName}
    </Typography>
    <UnitsViewer defaultId={defaultUnitId} measurementId={measurementId} />
    <MeasurementViewer base={base} measurementId={measurementId} />
    <TagsViewer tags={tags} />
  </div>
);

export default connect(
  (
    {
      entities: {
        pointTypes: { byId, idByName },
      },
    },
    { ptName: mangledName }
  ) => {
    const ptName = restorePtName(mangledName);
    const id = idByName[ptName];
    const pt = byId[id];
    const tags = pt?.tags || [];
    tags.sort();
    return {
      tags,
      ptName,
      measurementId: pt?.measurementId,
      defaultUnitId: pt?.defaultUnitId,
    };
  }
)(PointType);
