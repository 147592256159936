import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Checkbox,
  Typography,
  Button,
} from '@mui/material';
import { Link } from 'react-router-dom';
import { getRolePermissions, updateRolePermissions } from '../../../api/Api';
import BuildingSelector from '../../buildingselector/BuildingSelector';
import BuildingsLoader from '../../loaders/BuildingsLoader';

const RolePermissions = ({
  role,
  editable,
  buildingsById,
  fetchPermissions,
  updatePermissions,
}) => {
  const [serverPermissions, setPermissions] = useState([]);
  const [loaded, setLoaded] = useState(false);
  // building id to new allowed operations list: {10: ['READ']}
  const [changes, setChanges] = useState({});
  const numChanges = Object.entries(changes).length;
  const loadPermissions = (sp) => {
    const permissions = sp.reduce((data, bp) => {
      // eslint-disable-next-line no-param-reassign
      data[bp.building_id] = bp.permissions.map((p) => p.operation_name);
      return data;
    }, {});
    setPermissions(permissions);
    setLoaded(true);
  };
  useEffect(() => {
    fetchPermissions().then(loadPermissions);
  }, [role]);

  const permissions = { ...serverPermissions, ...changes };

  const grantedCheckbox = (opName, buildingId, ops) => {
    const checked = ops.filter((op) => opName === op).length > 0;
    const handleChange = (e) => {
      let newOps = ops.slice();
      if (e.target.checked) {
        newOps.push(opName);
      } else {
        newOps = newOps.filter((op) => op !== opName);
      }
      setChanges({ ...changes, [buildingId]: newOps });
    };
    return <Checkbox disabled={!editable} checked={checked} onChange={handleChange} />;
  };

  return (
    <>
      <Typography>Role permissions</Typography>
      <Table>
        <BuildingsLoader />
        <TableHead>
          <TableRow>
            <TableCell>Building</TableCell>
            <TableCell>Create</TableCell>
            <TableCell>Read</TableCell>
            <TableCell>Update</TableCell>
            <TableCell>Delete</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {Object.entries(permissions).map(([buildingId, ops]) => (
            <TableRow key={buildingId}>
              <TableCell>
                <Link to={`/explore/buildings/b?bid=${buildingId}`}>
                  {buildingsById[buildingId]?.name || `Building ${buildingId}`}
                </Link>
              </TableCell>
              <TableCell>{grantedCheckbox('CREATE', buildingId, ops)}</TableCell>
              <TableCell>{grantedCheckbox('READ', buildingId, ops)}</TableCell>
              <TableCell>{grantedCheckbox('UPDATE', buildingId, ops)}</TableCell>
              <TableCell>{grantedCheckbox('DELETE', buildingId, ops)}</TableCell>
            </TableRow>
          ))}
          {loaded && !Object.entries(permissions).length ? (
            <TableRow>
              <TableCell colSpan={5}>No building permissions currently granted</TableCell>
            </TableRow>
          ) : null}
          {editable ? (
            <>
              <TableRow>
                <TableCell colSpan={2}>Authorize an additional building:</TableCell>
                <TableCell colSpan={3}>
                  <BuildingSelector
                    selectedBuildingId={null}
                    handleSelection={(e) => setChanges({ ...changes, [e.target.value]: [] })}
                    filter={(b) => b.id > 0 && permissions[b.id] == null}
                  />
                </TableCell>
              </TableRow>
              {numChanges > 0 ? (
                <TableRow>
                  <TableCell colSpan={2}>
                    <Button
                      variant="contained"
                      onClick={() => {
                        const newPermissions = Object.entries(changes).map(([bid, operations]) => ({
                          role_id: role,
                          building_id: bid,
                          operations,
                        }));
                        updatePermissions({ changes: newPermissions })
                          .then(loadPermissions)
                          .then(() => setChanges({}));
                      }}
                    >
                      Save changes
                    </Button>
                  </TableCell>
                  <TableCell colSpan={3}>
                    <Button onClick={() => setChanges({})}>Clear</Button>
                  </TableCell>
                </TableRow>
              ) : null}
            </>
          ) : (
            <TableRow>
              <TableCell colSpan={5}>
                This role implicitly grants permissions based on its type and organization. It
                cannot be manually modified.
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
    </>
  );
};

export default connect(
  (
    {
      user: { jwt },
      entities: {
        buildings: { byId: buildingsById },
      },
    },
    { role }
  ) => ({
    buildingsById,
    fetchPermissions: () => (role > 0 ? getRolePermissions(jwt, role) : Promise.resolve([])),
    updatePermissions: (changes) => updateRolePermissions(jwt, role, changes),
  })
)(RolePermissions);
