import React from 'react';

const GettingStarted = () => (
  <article className="getting-started">
    <h1>Getting Started</h1>
    <h3>API Clients & Preferences</h3>
    <p>
      We currently provide API bindings for Python 3, which you can view on{' '}
      <a href="https://github.com/onboard-data/client-py" target="_blank" rel="noopener noreferrer">
        GitHub
      </a>{' '}
      and{' '}
      <a href="https://pypi.org/project/onboard.client/" target="_blank" rel="noopener noreferrer">
        PyPi
      </a>{' '}
      or install with pip:
    </p>
    <p>
      <span className="code">$ pip install onboard.client</span>
    </p>
    <p>
      <span className="code">from onboard.client import RtemClient</span>
      <br />
      <br />
      <span className="code">client = RtemClient(api_key=&apos;your-api-key-here&apos;)</span>
    </p>
    <p>
      The client requires an API Key, which you can set up on your{' '}
      <a href="/account?tab=api">Account Page</a>.
    </p>
    <p>
      You will only require and have access to API scopes <span className="code">general</span>
      and <span className="code">buildings:read</span>. If you wish to change your unit preferences,
      for example if your preferred units for Temperature are Celsius instead of Fahrenheit, you can
      set them on your <a href="/account?tab=unitPrefs">Account Page</a>.
    </p>

    <h3>Supporting Documentation & Videos</h3>

    <p>
      Onboard’s Python API Documentation (
      <a
        target="_blank"
        rel="noopener noreferrer"
        href="https://onboard-data-python-client-api.readthedocs.io/en/latest/"
      >
        link
      </a>
      )
    </p>

    <p>
      Tutorial Video Series with Dr. Clayton Miller (
      <a
        target="_blank"
        rel="noopener noreferrer"
        href="https://www.youtube.com/playlist?list=PLsUM_lTo-HdyC6WZ_3w9inFJvGUo0tTxO"
      >
        link
      </a>
      )
    </p>

    <p>
      Kaggle Notebooks Available for Download, you do need a Kaggle Account (
      <a
        target="_blank"
        rel="noopener noreferrer"
        href="https://www.kaggle.com/datasets/ponybiam/onboard-api-intro"
      >
        link
      </a>
      )
    </p>

    <h3>Terms & Conditions</h3>

    <p>
      You will find the Terms & Conditions of the event here (
      <a
        target="_blank"
        rel="noopener noreferrer"
        href="https://www.rtemhackathon.com/terms-and-conditions"
      >
        Terms And Conditions — RTEM HACKATHON
      </a>
      ). You may drop out of the Hackathon at any time, however dropping out of the Hackathon does
      not void the condition of the terms and conditions or your responsibilities. You agree not to
      misuse the site, resources, or data. Your credentials are assigned to you and only you. Your
      credentials cannot be shared with others. If you are a member of a team, each team member must
      request their own credentials. Please email{' '}
      <a href="mailto:signup@rtemhackathon.com">signup@rtemhackathon.com</a> to add members to your
      team. If this site, the resources, or data is misused you and your team’s access will be
      revoked and your participation in the challenge will be terminated.
    </p>
  </article>
);

export default GettingStarted;
