import { ADD_MULTIPLE_POINT_TYPES } from './actiontypes';
import { getAll } from '../../api/Api';

function addMultiplePointTypesAction(pointTypes) {
  return {
    type: ADD_MULTIPLE_POINT_TYPES,
    pointTypes,
  };
}

function fetchAllPointTypesActionCreator() {
  return (dispatch, getState) => {
    const state = getState();
    const pointTypeIds = state.entities.pointTypes.allIds;
    const { jwt } = state.user;

    // if the equiptypes were loaded recently, abort fetch
    if (pointTypeIds && pointTypeIds.length > 0) {
      return Promise.resolve();
    }

    const request = getAll(jwt, 'pointtypes').then(
      (response) => {
        dispatch(addMultiplePointTypesAction(response));
      },
      // eslint-disable-next-line no-console
      (error) => console.log(error)
    );
    return request;
  };
}

export default fetchAllPointTypesActionCreator;
