import { SET_MEASUREMENTS } from '../actions/actiontypes';
import { groupByProperty } from '../../utils/collections';

const measurementReducer = (state = { allIds: [], byId: {} }, action) => {
  switch (action.type) {
    case SET_MEASUREMENTS: {
      const measurements = action.data || [];
      return {
        byId: groupByProperty('id', measurements),
        allIds: measurements.map((m) => m.id),
      };
    }
    default:
      return state;
  }
};

export default measurementReducer;
