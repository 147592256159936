import React from 'react';
import PropTypes from 'prop-types';
import { TextField, Button } from '@mui/material';

const PW_LENGTH = 6;

/**
 * Uncontrolled component to handle TOTP entry and submission
 */
class TotpField extends React.PureComponent {
  state = {
    submitting: false,
    password: '',
  };

  submit = () =>
    this.setState({ submitting: true }, () =>
      this.props.onSubmit(this.state.password).finally(() => this.setState({ submitting: false }))
    );

  pwChange = (e) =>
    this.setState({ password: e.target.value }, () =>
      this.state.password.length === PW_LENGTH ? this.submit() : null
    );

  render() {
    const { password, submitting } = this.state;
    return (
      <div className="totp-field">
        <TextField
          name="otp"
          label="e.g. 123456"
          disabled={submitting}
          value={password}
          onChange={this.pwChange}
          style={{ width: '256px' }}
        />
        <br />
        <br />
        <Button
          variant="contained"
          color="primary"
          disabled={submitting || password.length !== PW_LENGTH}
          onClick={this.submit}
        >
          Continue
        </Button>
      </div>
    );
  }
}

TotpField.propTypes = {
  onSubmit: PropTypes.func.isRequired,
};

export default TotpField;
