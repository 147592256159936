import React from 'react';
import { connect } from 'react-redux';
import { Link as RouterLink } from 'react-router-dom';
import { Button } from '@mui/material';

/**
 * Helper component to build up links to the single-type equipment view from a point or equipment id
 */
const EquipSingleLink = ({ target, text, linkText, color = 'secondary' }) =>
  target ? (
    <Button className="equip-single-link" color={color} component={RouterLink} to={target}>
      {text || linkText}
    </Button>
  ) : (
    linkText
  );

export default connect(({ entities }, { pointId, equipId }) => {
  const linkText = pointId ? `Point ${pointId}` : `Equipment ${equipId}`;
  const point = entities.points.byId[pointId];
  const equipIdResolved = point ? point.equipId : equipId;
  const equipment = entities.equipment.byId[equipIdResolved];
  const building = entities.buildings.byId[equipment?.buildingId];
  const buildingName = building?.name || 'building';
  const buildingId = building?.id;
  const params = new URLSearchParams({ bid: buildingId, eid: equipIdResolved });
  const target =
    equipIdResolved && buildingId
      ? `/explore/buildings/${encodeURIComponent(
          buildingName
        )}/equipment/asset?${params.toString()}`
      : null;
  return { linkText, target };
})(EquipSingleLink);
