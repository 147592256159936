import React from 'react';
import { connect } from 'react-redux';
import { ActionLogTypesLoader, ActionSearchLoader } from '../loaders/actionLog';
import UsersLoader from '../loaders/UsersLoader';
import BuildingsLoader from '../loaders/BuildingsLoader';
import ActionQueryOptions from './ActionQueryOptions';
import ActionTable from './ActionTable';

import './audit.css';

const ActionLogAudit = () => (
  <>
    <ActionLogTypesLoader />
    <UsersLoader />
    <BuildingsLoader />
    <ActionSearchLoader />

    <div className="row grid-container-lg audit">
      <div className="full">
        <ActionQueryOptions />
        <ActionTable variant="ADMIN" />
      </div>
    </div>
  </>
);

export default connect()(ActionLogAudit);
