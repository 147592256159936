import React from 'react';
import {
  Button,
  LinearProgress,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import RowStatus from './RowStatus';

const UploadProgress = ({ filename, rows, isProcessing, onCreate }) => {
  const total = rows.length;
  const numDone = rows.filter((r) => r.status === 'exists').length;
  return (
    <div>
      <div>
        <Typography variant="h4">{filename}</Typography>
        <Button
          variant="contained"
          color="secondary"
          onClick={onCreate}
          disabled={total === 0 || isProcessing}
        >
          Create Sites
        </Button>
        <Typography>
          Progress: ({numDone} / {total})
        </Typography>
      </div>
      {isProcessing && <LinearProgress color="secondary" />}
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Status</TableCell>
            <TableCell>Vendor</TableCell>
            <TableCell>Project Name</TableCell>
            <TableCell>Project Folder</TableCell>
            <TableCell>Service Years</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((r) => (
            <TableRow key={r.path}>
              <TableCell>
                <RowStatus status={r.status} />
              </TableCell>
              <TableCell>{r.vendor}</TableCell>
              <TableCell>{r['Project Name']}</TableCell>
              <TableCell>{r.folder}</TableCell>
              <TableCell>{r['Service Years']}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </div>
  );
};

export default UploadProgress;
