import { combineReducers } from 'redux';
import { SET_EXPORTS, SET_EXPORT } from '../actions/actiontypes';
import { groupByProperty } from '../../utils/collections';

const loaded = (state = false, action) => {
  if (action.type === SET_EXPORTS) return true;
  return state;
};

const byId = (state = {}, action) => {
  if (action.type === SET_EXPORTS) {
    return groupByProperty('id', action.data);
  }
  if (action.type === SET_EXPORT) {
    return { ...state, [action.data.id]: action.data };
  }
  return state;
};

const tsExports = combineReducers({
  loaded,
  byId,
});

export default tsExports;
