import React, { useState } from 'react';
import { connect } from 'react-redux';
import { useConfirm } from 'material-ui-confirm';
import { Button, LinearProgress } from '@mui/material';
import { DateRange } from '@mui/icons-material';
import { dateTime } from '../../../utils/parsing';
import { dataNotificationAction } from '../../../redux/actions/rtemFiles';

const DataSubmitButton = ({ dir, submitted, sendEmail }) => {
  const [success, setSuccess] = useState(null);
  const [loading, setLoading] = useState(false);
  const confirm = useConfirm();
  if (!dir.files.length) return null;
  if (success != null)
    return (
      <div className="dir-actions button">
        <span>Submitted on {dateTime(success, null, 'at')}, thank you</span>
      </div>
    );
  if (loading)
    return (
      <div className="dir-actions button">
        <LinearProgress />
      </div>
    );
  return (
    <div className="dir-actions button">
      <Button
        variant="outlined"
        onClick={() =>
          confirm({
            title: `Submit '${dir.name}'`,
            description: `This action will send an email notifying RTEM that the data is ready to be reviewed`,
            confirmationText: 'Send notification',
          })
            .then(() => {
              setLoading(true);
              return sendEmail();
            })
            .then(() => {
              setSuccess(new Date());
              setLoading(false);
            })
            .catch(() => {})
        }
      >
        <DateRange className="left-icon" color="secondary" />
        Submit and Request Approval
        {submitted && ` (Last submitted on ${dateTime(submitted, null, 'at')})`}
      </Button>
    </div>
  );
};

export default connect(
  ({ rtemSubmissions: { statusTimes } }, { dir: { path } }) => ({
    submitted: statusTimes[path]?.SUBMITTED,
  }),
  (dispatch, { dir }) => ({
    sendEmail: () => dispatch(dataNotificationAction(dir.path)),
  })
)(DataSubmitButton);
