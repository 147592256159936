import {
  ADD_BUILDINGS,
  START_BUILDINGS_LOAD,
  INVALIDATE_BUILDINGS,
  INVALIDATE_BUILDING,
  DELETE_ALL_BUILDINGS,
} from './actiontypes';
import { getAll, patchBuilding, deleteBuilding, uploadBuildingImage } from '../../api/Api';

export function deleteAllBuildingsAction() {
  return {
    type: DELETE_ALL_BUILDINGS,
  };
}

const startBuildingsLoadingAction = () => ({
  type: START_BUILDINGS_LOAD,
});

// an action that describes which state to update when
// adding buildings
function addBuildingsAction(buildings) {
  return {
    type: ADD_BUILDINGS,
    buildings,
    receivedAt: Date.now(),
  };
}

export function invalidateBuildingsAction() {
  return {
    type: INVALIDATE_BUILDINGS,
  };
}

export function invalidateBuildingDataAction(buildingId) {
  return {
    type: INVALIDATE_BUILDING,
    buildingId,
  };
}

// an action creator which handles making the GET buildings network request
// and dispatching an action once network response/error returns
export default function fetchBuildingsActionCreator() {
  // Redux Thunk allows this, see its docs for more detail
  return (dispatch, getState) => {
    const state = getState();

    // if current buildings already fetched and  not invalidated, abort fetch
    if (
      (!state.entities.buildings.meta.isInvalid && state.entities.buildings.meta.receivedAt) ||
      state.entities.buildings.meta.isLoading
    ) {
      return Promise.resolve();
    }
    dispatch(startBuildingsLoadingAction());

    return getAll(state.user.jwt, 'buildings').then(
      (response) => {
        dispatch(addBuildingsAction(response));
      },
      // eslint-disable-next-line no-console
      (error) => console.log(error)
    );
  };
}

export const patchBuildingActionCreator = (buildingId, patch) => (dispatch, getState) => {
  const {
    user: { jwt },
  } = getState();

  return (
    patchBuilding(buildingId, patch, jwt)
      // eslint-disable-next-line no-console
      .catch((e) => console.error(`Error patching building ${e}`))
      .finally(() => {
        dispatch(invalidateBuildingsAction());
        dispatch(fetchBuildingsActionCreator());
      })
  );
};

export const deleteBuildingActionCreator = (buildingId) => (dispatch, getState) => {
  const {
    user: { jwt },
  } = getState();

  return (
    deleteBuilding(buildingId, jwt)
      // eslint-disable-next-line no-console
      .catch((e) => console.error(`Error deleting building ${e}`))
      .finally(() => {
        dispatch(deleteAllBuildingsAction());
        dispatch(fetchBuildingsActionCreator());
      })
  );
};

export const uploadBuildingImageActionCreator = (buildingId, image) => (dispatch, getState) => {
  const {
    user: { jwt },
  } = getState();
  return (
    uploadBuildingImage(buildingId, image, jwt)
      // eslint-disable-next-line no-console
      .catch((e) => console.error(`Error uploading building image ${e}`))
      .finally(() => {
        dispatch(invalidateBuildingsAction());
        dispatch(fetchBuildingsActionCreator());
      })
  );
};
