import ReactGA from 'react-ga';
import { UPDATE_USER_INFO, CLEAR_USER_INFO, SET_FORCED_LOGOUT, PARTIAL_LOGIN } from './actiontypes';
import { logout } from '../../api/Api';
import { clearLoginCookie } from '../../api/loginCookie';
import { deleteAllPointsAction } from './points';
import { deleteAllEquipmentAction } from './equipment';
import { deleteAllBuildingsAction } from './buildings';

function parseJwt(jwt) {
  const base64Url = jwt.split('.')[1];
  const base64 = base64Url.replace('-', '+').replace('_', '/');
  return JSON.parse(window.atob(base64));
}

// an action that updates user info after a completed login
export function updateUserInfoAction(userInfo, authLevel) {
  const jwtBody = parseJwt(userInfo.token);
  ReactGA.set({ userId: jwtBody.sub });
  ReactGA.event({
    category: 'User',
    action: 'Logged In',
    label: jwtBody.sub.toString(),
  });
  return {
    type: UPDATE_USER_INFO,
    jwt: userInfo.token,
    jwtIssuedAt: jwtBody.iat,
    userName: userInfo.name,
    userFullName: userInfo.full_name,
    orgShortName: userInfo.org_short_name,
    orgLogoUrl: userInfo.logo_url,
    orgId: userInfo.org_id,
    userID: jwtBody.sub,
    userAuthLevel: authLevel ?? jwtBody.auth_level,
    mfaRequired: userInfo.mfa_required,
  };
}

// the token we receive after a partial login (MFA required) only allows us
// to access the MFA verification endpoints, so save it separately
export const partialUserLogin = (response) => ({
  type: PARTIAL_LOGIN,
  partialLoginToken: response.access_token,
});

export function setForcedLogoutAction(isForced) {
  return {
    type: SET_FORCED_LOGOUT,
    forcedLogout: isForced,
  };
}

// an action that resets all user info
export function clearUserInfoAction() {
  clearLoginCookie();
  return {
    type: CLEAR_USER_INFO,
  };
}

export function clearAllDataActionCreator() {
  return (dispatch) => {
    dispatch(deleteAllPointsAction());
    dispatch(deleteAllEquipmentAction());
    dispatch(deleteAllBuildingsAction());
  };
}

export function logoutUserActionCreator(forced = false) {
  return (dispatch, getState) => {
    const {
      user: { jwt },
    } = getState();
    logout(jwt);
    clearLoginCookie();
    dispatch(setForcedLogoutAction(forced));
    dispatch(clearAllDataActionCreator());
    dispatch(clearUserInfoAction());
  };
}
