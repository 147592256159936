import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { MenuItem, FormControl, Select } from '@mui/material';
import getBuildingsOrderedByName from '../../redux/selectors/buildings';
import BuildingsLoader from '../loaders/BuildingsLoader';

/**
 * A controlled dropdown component that selects a building.
 */

const NO_SELECTION = [{ id: -1, name: '-- Select a building --' }];

const BuildingSelector = ({
  selectedBuildingId,
  buildings,
  handleSelection,
  disabled,
  filter = () => true,
}) => (
  <FormControl style={{ minWidth: '300px' }}>
    <BuildingsLoader />
    <Select
      variant="standard"
      value={selectedBuildingId}
      onChange={handleSelection}
      disabled={disabled}
    >
      {buildings.filter(filter).map((building) => (
        <MenuItem key={building.id} value={building.id}>
          {building.name}
        </MenuItem>
      ))}
    </Select>
  </FormControl>
);

BuildingSelector.propTypes = {
  selectedBuildingId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  handleSelection: PropTypes.func.isRequired,
};

export default connect((state, { orgId }) => ({
  buildings: NO_SELECTION.concat(
    getBuildingsOrderedByName(state).filter((b) => orgId == null || b.orgId === orgId)
  ),
}))(BuildingSelector);
