import React from 'react';
import Root from './levels/Root';
import RootClass from './levels/RootClass';
import EquipmentType from './levels/EquipmentType';
import EquipmentSubType from './levels/EquipmentSubType';
import PointList from './levels/PointList';
import PointType from './levels/PointType';
import Measurement from './levels/Measurement';
import Parent from './levels/Parent';
import Child from './levels/Child';
import Location from './levels/Location';

const getObj = (path, model) => {
  let found = model;
  for (let i = 0; i < path.length; i += 1) {
    const nextPath = decodeURIComponent(path[i]);
    if (!nextPath) break;
    const next = found?.children?.find((c) => c.link === nextPath);
    if (!next) break;
    found = next;
  }
  return found;
};

const renderObject = (path, model, base, queryParams) => {
  const [rootType, first, second] = path;
  if (rootType == null) return <Root />;

  const isTopLevel = first == null && second == null;
  if (isTopLevel && rootType !== 'points') return <RootClass type={rootType} />;

  const modelObj = getObj(path, model);

  if (rootType === 'equipment') {
    if (second != null) return <EquipmentSubType subType={modelObj} />;
    if (first != null) return <EquipmentType equipment={modelObj} />;
  }
  if (rootType === 'points') {
    if (first != null && second != null) {
      return <PointType base={base} ptName={second} />;
    }
    return <PointList base={base} queryParams={queryParams} />;
  }
  if (rootType === 'measurements' && first != null) {
    return <Measurement measurement={modelObj} base={base} />;
  }
  if (rootType === 'relationships') {
    if (first === 'parent') return <Parent base={base} />;
    if (first === 'child') return <Child base={base} />;
  }
  if (rootType === 'location') {
    return <Location attr={first} />;
  }

  return 'Something went wrong, please try again in a minute';
};

const ClassViewer = ({ path, model, base, queryParams }) => (
  <div className="class-viewer root">
    <div className={`obj ${path.join(' ')}`}>{renderObject(path, model, base, queryParams)}</div>
  </div>
);

export default ClassViewer;
