export const ls = (path) => ({
  operation: 'ls',
  path,
});

export const rm = (path) => ({ operation: 'rm', path });

export const mv = (path, destination) => ({ operation: 'mv', path, destination });

export const mkdir = (path) => ({ operation: 'mkdir', path });

export const putStart = (path) => ({ operation: 'put', path });

export const putFinish = (path) => ({ operation: 'put_finish', path });
