import React, { useState } from 'react';
import { connect } from 'react-redux';
import { Button, LinearProgress, IconButton, Tooltip, Typography } from '@mui/material';
import { CloudUpload, Clear } from '@mui/icons-material';
import { uploadFileAction } from '../../../redux/actions/rtemFiles';

import './upload-button.css';

const wrap = (inner) => <div className="dir-actions button">{inner}</div>;

const UploadButton = ({ uploadFile }) => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [{ filename, file }, setFile] = useState({});

  const onFileSelection = (e) => {
    e.preventDefault();
    if (e.target.files.length !== 1) return;
    if (e.target.files[0].size > 5_368_709_120) {
      setError('File must be smaller than 5GB');
      return;
    }
    const selectedFile = e.target.files[0];
    setFile({ file: selectedFile, filename: selectedFile.name });
  };
  const onSubmit = () => {
    setLoading(true);
    uploadFile(filename, file).then(() => {
      setLoading(false);
      setFile({});
    });
  };

  if (loading) {
    return wrap(<LinearProgress className="upload-loading" color="secondary" />);
  }

  if (filename && file) {
    return wrap(
      <>
        <Button variant="outlined" onClick={onSubmit} aria-label="upload-send">
          <CloudUpload className="left-icon" color="secondary" />
          Upload {filename}
        </Button>
        <Tooltip title="Cancel">
          <IconButton aria-label="cancel" onClick={() => setFile({})} size="large">
            <Clear className="icon" />
          </IconButton>
        </Tooltip>
      </>
    );
  }

  return wrap(
    <>
      <Typography color="error">{error}</Typography>
      <Button variant="outlined" aria-label="upload">
        <input
          className="hidden-input"
          name="file-picker"
          type="file"
          onChange={onFileSelection}
          title=" "
        />
        <CloudUpload className="left-icon" color="secondary" />
        Upload a File
      </Button>
      <div>File Limit 5GB</div>
    </>
  );
};

export default connect(null, (dispatch, { path }) => ({
  uploadFile: (filename, file) => dispatch(uploadFileAction(path, filename, file)),
}))(UploadButton);
