import { ADD_MULTIPLE_EQUIP_TYPES } from './actiontypes';
import { getAll } from '../../api/Api';

function addMultipleEquipTypesAction(equipTypes) {
  return {
    type: ADD_MULTIPLE_EQUIP_TYPES,
    equipTypes,
  };
}

function fetchAllEquipTypesActionCreator() {
  return (dispatch, getState) => {
    const state = getState();
    const equipTypeIds = state.entities.equipTypes.allIds;
    const { jwt } = state.user;

    // if the equiptypes were loaded recently, abort fetch
    if (equipTypeIds && equipTypeIds.length > 0) {
      return Promise.resolve();
    }

    return getAll(jwt, 'equiptype').then(
      (response) => dispatch(addMultipleEquipTypesAction(response)),
      // eslint-disable-next-line no-console
      (error) => console.log(error)
    );
  };
}

export default fetchAllEquipTypesActionCreator;
