import { ADD_MULTIPLE_POINTS, DELETE_ALL_POINTS } from './actiontypes';

export function deleteAllPointsAction() {
  return {
    type: DELETE_ALL_POINTS,
  };
}

export function addMultiplePointsAction(points) {
  return {
    type: ADD_MULTIPLE_POINTS,
    points,
  };
}
