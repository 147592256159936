import React from 'react';
import { parseStringToInt } from '../../utils/parsing';
import Collapsible from '../util/Collapsible';
import Indent from '../util/Indent';
import EquipSingleLink from '../explore/EquipSingle/EquipSingleLink';

/**
 * A component to display a JSON dictionary with indentation and collapsible keys
 */
const ResultsViewer = ({ results, pointIdKeys = false }) => {
  const KeyOrLink = ({ id }) => {
    if (pointIdKeys) {
      const idInt = parseStringToInt(id);
      return idInt ? <EquipSingleLink pointId={idInt} /> : id;
    }
    return id;
  };

  const renderDict = (dict, expanded = false) =>
    Object.keys(dict).map((key, i) => (
      // eslint-disable-next-line no-use-before-define
      <Indent key={key}>{renderKey(key, dict[key], expanded || i === 0)}</Indent>
    ));

  // leaf dictionaries aren't indented and render on one wrapped line to increase data density
  const renderLeafDict = (dict) => (
    <span className="leaf-dict">
      {'{ '}
      {Object.keys(dict).map((key, i) => (
        <React.Fragment key={key}>
          {i > 0 && ', '}
          <KeyOrLink id={key} /> = {dict[key]}
        </React.Fragment>
      ))}
      {' }'}
    </span>
  );

  const renderKey = (key, value, expanded) => {
    if (value == null) return null;
    // look ahead to decide whether to indent another level
    const hasChildren = Object.values(value)
      .filter((v) => v != null)
      .map((v) => typeof v)
      .some((c) => c === 'object');
    // there are dictionary children, keep recursing deeper
    if (hasChildren) {
      return (
        <Collapsible defaultOpen={expanded} headerContent={<KeyOrLink id={key} />}>
          {renderDict(value)}
        </Collapsible>
      );
    }
    // show 'key = { a: 1, b: 2 }' type values (no nested children) inline to save visual space
    const isDict = typeof value === 'object' && !Array.isArray(value);
    return (
      <div key={key}>
        <KeyOrLink id={key} /> = {isDict ? renderLeafDict(value) : `${value}`}
      </div>
    );
  };

  return renderDict(results || {});
};

export default ResultsViewer;
