import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { Grid, Typography } from '@mui/material';
import AttrGrid from './Grid';
import { getPointTypeLink } from '../../models';

export const PointTypesViewerUnconnected = ({ pointTypes, base, emptyComponent = null }) => (
  <AttrGrid>
    <Grid item xs={12}>
      <Typography variant="h6">Point types:</Typography>
    </Grid>
    {pointTypes.map((pt) => (
      <Grid item xs={5} key={pt.id}>
        <Link to={getPointTypeLink(pt, base)}>{pt.name}</Link>
      </Grid>
    ))}
    {pointTypes.length === 0 ? emptyComponent : null}
  </AttrGrid>
);

export default connect(
  (
    {
      entities: {
        pointTypes: { byId },
      },
    },
    { measurementId }
  ) => ({
    pointTypes: Object.values(byId).filter((pt) => pt.measurementId === measurementId),
  })
)(PointTypesViewerUnconnected);
