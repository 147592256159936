import React from 'react';
import { connect } from 'react-redux';
import { Typography } from '@mui/material';
import TagsViewer from './attrs/TagsViewer';

const EquipmentSubType = ({ equipment, subType }) =>
  equipment ? (
    <div className="leaf equip-sub-type">
      <Typography variant="h5" className="name">
        {equipment.nameLong} - {subType.name}
      </Typography>
      <TagsViewer tags={[equipment.tagName, subType.tagName].concat(equipment.tags)} />
    </div>
  ) : null;

export default connect(
  (
    {
      entities: {
        equipTypes: { byId },
      },
    },
    { subType: { equipId } }
  ) => ({
    equipment: byId[equipId],
  })
)(EquipmentSubType);
