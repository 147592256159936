import React from 'react';
import { useMatch, useLocation } from 'react-router-dom';
import { getSearchParams } from '../../utils/url';
import ModelExplorer from './ModelExplorer';

const decode = (path) => (path == null ? null : decodeURIComponent(path));

/**
 * Use react router to parse path parameters and pass them onto the model explorer component
 * This basically just extracts levels of hierarchy from the URL in order to use in the Class* components
 */
const ModelRouter = ({ base }) => {
  const firstOnly = useMatch(`${base}/:first`);
  const firstSecond = useMatch(`${base}/:first/:second`);
  const all = useMatch(`${base}/:first/:second/:third`);
  const match = all ?? firstSecond ?? firstOnly;
  const { first, second, third } = match?.params ?? {};
  const path = [first, second, third].map(decode);
  const location = useLocation();
  const queryParams = getSearchParams(location.search);
  return <ModelExplorer base={base} path={path} queryParams={queryParams} />;
};

export default ModelRouter;
