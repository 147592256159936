import { compareByNumericProp, compareByProps } from '../../utils/comparators';

// categories (the lower case are tag values)
const POINT_CATEGORIES = {
  alarm: 'Alarm',
  cmd: 'Command',
  sensor: 'Sensor',
  sp: 'Setpoint',
  limit: 'Parameter',
  virtual: 'Virtual',
};

export const noSpaces = (text) => text.replace(/ /g, '_');

export const restorePtName = (noSpacesPt) => noSpacesPt.replace(/_/g, ' ');

export const getPointTypeLink = (pointType, base) => {
  const category = pointType.tags.find((c) => POINT_CATEGORIES[c] != null);
  return `${base}/points/${category}/${noSpaces(pointType.name)}`;
};

const buildPointCategories = (pointTypesById, tagCategories, measurementsById) => {
  const makeCategory = (cat) => ({
    type: 'multiselect',
    param: 'tag',
    name: cat,
    children:
      tagCategories[cat]?.map((tag) => ({
        link: tag,
        name: tag,
      })) ?? [],
  });

  const measurements = Object.values(measurementsById);
  const measureChildren = measurements.map((m) => ({
    link: `${m.id}`,
    name: m.name,
  }));
  measureChildren.sort(compareByProps(['name']));

  const categorySections = [
    { ...makeCategory('Point Class'), closeAfterFirst: true },
    {
      type: 'multiselect',
      param: 'measurement',
      name: 'Quantity',
      children: measureChildren,
      closeAfterFirst: true,
    },
    makeCategory('Quantity Modifier'),
    makeCategory('Direction'),
    makeCategory('Medium Property'),
  ];

  return categorySections;
};

const buildEquipmentCategories = (equipTypes) => {
  const equipCategories = Object.values(equipTypes || {}).map(
    ({ id, tagName, nameLong, subTypes }) => ({
      link: tagName,
      name: nameLong,
      id,
      children: subTypes.map(({ id: stId, tag_name: subTagName, name_long: name }) => ({
        id: stId,
        equipId: id,
        link: subTagName,
        tagName: subTagName,
        name,
      })),
    })
  );
  equipCategories.sort(compareByNumericProp('name'));
  return equipCategories;
};

const buildMeasurements = (measurements) => {
  const measurementModels = Object.values(measurements || {}).map(({ id, name }) => ({
    name,
    id,
    link: noSpaces(name),
  }));
  measurementModels.sort(compareByNumericProp('name'));
  return measurementModels;
};

const buildLocations = () => [
  { link: 'area-served-desc', name: 'Area Served Description' },
  { link: 'floor-num-physical', name: 'Floor Number Physical' },
  { link: 'floor-num-served', name: 'Floor Number Served' },
];

const buildRelationships = () => [
  { link: 'parent', name: 'Parent Equipment' },
  { link: 'child', name: 'Child Equipment' },
];

const buildDataModel = (tags, tagCategories, equipTypes, pointTypes, measurements) => ({
  name: 'Classes',
  children: [
    { link: 'equipment', name: 'Equipment', children: buildEquipmentCategories(equipTypes) },
    {
      link: 'points',
      name: 'Points',
      children: buildPointCategories(pointTypes, tagCategories, measurements),
    },
    { link: 'measurements', name: 'Measurements', children: buildMeasurements(measurements) },
    { link: 'location', name: 'Location', children: buildLocations() },
    { link: 'relationships', name: 'Relationships', children: buildRelationships() },
  ],
});

export default buildDataModel;
