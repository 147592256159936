import React from 'react';
import { connect } from 'react-redux';
import { Typography } from '@mui/material';
import TagsViewer from './attrs/TagsViewer';

const EquipmentType = ({ equipment }) =>
  equipment ? (
    <div className="leaf equip-type">
      <Typography variant="h5" className="name">
        {equipment.nameLong}
      </Typography>
      <TagsViewer tags={[equipment.tagName].concat(equipment.tags)} />
    </div>
  ) : null;

export default connect(
  (
    {
      entities: {
        equipTypes: { byId },
      },
    },
    { equipment: { id } }
  ) => ({
    equipment: byId[id],
  })
)(EquipmentType);
