import React, { useState } from 'react';
import { connect } from 'react-redux';
import { useConfirm } from 'material-ui-confirm';
import { useDrag } from 'react-dnd';
import { IconButton, Tooltip } from '@mui/material';
import { InsertDriveFile, Delete } from '@mui/icons-material';
import { sendCommandAction } from '../../../redux/actions/rtemFiles';
import { rm } from './commands';
import Filename from './Filename';

/**
 * Display a file along with an inline menu of actions when the file is selected
 */
const File = ({ file, isSelected, selectHandler, command }) => {
  const [isRenaming, setRenaming] = useState(false);
  const [{ isDragging }, drag] = useDrag({
    type: 'file',
    item: { id: file.path, name: file.name, parent: file.parents },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
    canDrag: () => !isRenaming,
  });
  const confirm = useConfirm();
  return (
    <div
      ref={drag}
      key={file.path}
      className={`selectable file ${isSelected ? 'selected' : ''}`}
      style={{ opacity: isDragging ? 0.4 : 1 }}
      onClick={selectHandler}
      onKeyPress={selectHandler}
      role="menuitem"
      tabIndex={0}
      data-filename={file.name}
    >
      <InsertDriveFile className="icon" />
      <Filename
        file={file}
        isSelected={isSelected}
        selectHandler={selectHandler}
        setParentRenaming={setRenaming}
      />
      {isSelected && (
        <Tooltip title="Delete">
          <span>
            <IconButton
              aria-label="delete"
              disabled={isRenaming}
              onClick={() =>
                confirm({
                  title: `Delete file '${file.path}' ?`,
                  description: 'This action will permanently delete this file and cannot be undone',
                  confirmationText: 'Delete file',
                })
                  .then(() => command(rm(file.path)))
                  .catch(() => {})
              }
              size="large"
            >
              <Delete className="icon" />
            </IconButton>
          </span>
        </Tooltip>
      )}
    </div>
  );
};

export default connect(null, (dispatch) => ({
  command: (cmd) => dispatch(sendCommandAction(cmd)),
}))(File);
