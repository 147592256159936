import { combineReducers } from 'redux';
import { SET_DATA_REQUESTS } from '../actions/actiontypes';
import { compareByNumericProp } from '../../utils/comparators';
import { groupByProperty } from '../../utils/collections';

function all(state = [], action) {
  switch (action.type) {
    case SET_DATA_REQUESTS: {
      action.data.sort(compareByNumericProp('last_modified', 'desc'));
      return action.data;
    }
    default:
      return state;
  }
}

const loaded = (state = false, action) => {
  if (action.type !== SET_DATA_REQUESTS) return state;
  return true;
};

const byId = (state = {}, action) => {
  if (action.type !== SET_DATA_REQUESTS) return state;
  return groupByProperty('id', action.data);
};

const dataRequestsReducer = combineReducers({
  all,
  loaded,
  byId,
});

export default dataRequestsReducer;
