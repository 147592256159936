import { combineReducers } from 'redux';
import { ADD_MULTIPLE_POINT_TYPES } from '../actions/actiontypes';
import { alphabetical } from '../../utils/collections';

function addOrUpdatePointTypes(state, pointTypes) {
  // return the state with the multiple point types added/replaced
  const newState = { ...state };
  for (let i = 0; i < pointTypes.length; i += 1) {
    const pt = pointTypes[i];
    newState[pt.id] = {
      id: pt.id,
      name: pt.tag_name,
      tagSetIds: pt.tag_set_ids,
      tags: pt.tags,
      defaultUnitId: pt.default_unit_id,
      measurementId: pt.measurement_id,
    };
  }
  return newState;
}

function pointTypesById(state = {}, action) {
  switch (action.type) {
    case ADD_MULTIPLE_POINT_TYPES:
      return addOrUpdatePointTypes(state, action.pointTypes);
    default:
      return state;
  }
}

function allPointTypeIds(state = [], action) {
  switch (action.type) {
    case ADD_MULTIPLE_POINT_TYPES:
      return Array.from(new Set(state.concat(action.pointTypes.map((pt) => pt.id))));
    default:
      return state;
  }
}

function alphabeticalPointTypeIds(state = [], action) {
  switch (action.type) {
    case ADD_MULTIPLE_POINT_TYPES:
      return alphabetical('tag_name', action.pointTypes).map((p) => p.id);
    default:
      return state;
  }
}

const idByName = (state = {}, action) => {
  if (action.type !== ADD_MULTIPLE_POINT_TYPES) {
    return state;
  }
  return action.pointTypes.reduce((o, pt) => {
    // eslint-disable-next-line no-param-reassign
    o[pt.tag_name] = pt.id;
    return o;
  }, {});
};

const pointTypeReducer = combineReducers({
  byId: pointTypesById,
  idByName,
  allIds: allPointTypeIds,
  alphabeticalIds: alphabeticalPointTypeIds,
});

export default pointTypeReducer;
