import { combineReducers } from 'redux';
import { ADD_MULTIPLE_EQUIP_TYPES } from '../actions/actiontypes';

const subTypeObject = (et) => ({
  id: et.id,
  tagName: et.tag_name,
  nameLong: et.name_long,
  nameAbbr: et.name_abbr,
  equipmentTypeId: et.equipment_type_id,
});

function addOrUpdateEquipTypes(state, equipTypes) {
  // return the state with the multiple equipt types added/replaced
  const newState = { ...state };
  equipTypes.forEach((et) => {
    newState[et.id] = subTypeObject(et);
  });
  return newState;
}

const getSubtypesFromEquipmentTypes = (equipTypes) => equipTypes.map((et) => et.sub_types).flat();

const byId = (state = {}, action) => {
  switch (action.type) {
    case ADD_MULTIPLE_EQUIP_TYPES:
      return addOrUpdateEquipTypes(state, getSubtypesFromEquipmentTypes(action.equipTypes));
    default:
      return state;
  }
};

const allIds = (state = [], action) => {
  switch (action.type) {
    case ADD_MULTIPLE_EQUIP_TYPES:
      return Array.from(
        new Set(state.concat(getSubtypesFromEquipmentTypes(action.equipTypes).map((et) => et.id)))
      );
    default:
      return state;
  }
};

const byParent = (state = {}, action) => {
  if (action.type !== ADD_MULTIPLE_EQUIP_TYPES) return state;
  const subByParentId = action.equipTypes.reduce((obj, equipType) => {
    // eslint-disable-next-line no-param-reassign
    obj[equipType.id] = equipType.sub_types.map(subTypeObject);
    return obj;
  }, {});
  // eslint-disable-next-line dot-notation
  subByParentId[null] = getSubtypesFromEquipmentTypes(action.equipTypes).map(subTypeObject);
  return subByParentId;
};

export default combineReducers({
  byId,
  allIds,
  byParent,
});
