import React from 'react';
import { Grid } from '@mui/material';
import ApiKeyLoader from './ApiKeyLoader';
import KeyCreator from './KeyCreator';
import UserApiKeys from './UserApiKeys';
import OrganizationsLoader from '../loaders/OrganizationsLoader';

import './api-mgmt.css';

const ApiManagement = () => (
  <div className="api-mgmt">
    <ApiKeyLoader />
    <OrganizationsLoader />
    <Grid container spacing={8} direction="row" justifyContent="space-around" alignItems="center">
      <Grid item xs={12}>
        <UserApiKeys />
      </Grid>
      <Grid item xs={12}>
        <KeyCreator />
      </Grid>
    </Grid>
  </div>
);

export default ApiManagement;
