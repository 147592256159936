import React from 'react';
import { connect } from 'react-redux';
import Selector from '../util/Selector';
import { setQueryActionCreator } from '../../redux/actions/actionLog';

const ActionSelector = ({ actionTypes, selected, onChange }) => (
  <Selector
    options={actionTypes.map((a) => ({
      id: a,
      key: a,
      display: a,
    }))}
    selected={selected}
    onChange={onChange}
    showNullOption
  />
);

export default connect(
  ({
    actionLog: {
      actionTypes,
      query: { action },
    },
  }) => ({ actionTypes, selected: action }),
  (dispatch) => ({ onChange: (e) => dispatch(setQueryActionCreator({ action: e.target.value })) })
)(ActionSelector);
