import React, { useState } from 'react';
import { connect } from 'react-redux';
import { Checkbox, FormControlLabel, LinearProgress, Table, Typography } from '@mui/material';
import UnitsLoader from '../../loaders/UnitsLoader';
import MeasurementsLoader from '../../loaders/MeasurementsLoader';
import UnitPreferencesLoader from '../../loaders/UnitPreferencesLoader';
import OrgUnitPrefs from './OrgUnitPrefs';
import UserUnitPrefs from './UserUnitPrefs';
import MeasurementDefaultPrefs from './MeasurementDefaultPrefs';

export const UnitPrefLoader = () => (
  <>
    <MeasurementsLoader />
    <UnitsLoader />
    <UnitPreferencesLoader />
  </>
);

const UnitPreferences = ({ loaded }) => {
  const [displayInactive, setDisplayInactive] = useState(false);
  const handleCheck = () => setDisplayInactive(!displayInactive);
  return (
    <>
      <UnitPrefLoader />

      <div className="full">
        <Typography variant="h6">
          Unit preferences control how continuous-value sensor data are displayed on the site and
          returned by the timeseries query API
        </Typography>
        <br />
      </div>
      {loaded ? (
        <>
          <div className="full">
            <FormControlLabel
              control={<Checkbox onChange={() => handleCheck()} />}
              label="Show inactive preferences"
            />
          </div>
          <div className="full">
            <Table>
              <UserUnitPrefs />
              <OrgUnitPrefs displayInactive={displayInactive} />
              <MeasurementDefaultPrefs displayInactive={displayInactive} />
            </Table>
          </div>
        </>
      ) : (
        <div className="full">
          <LinearProgress color="secondary" />
        </div>
      )}
    </>
  );
};

export default connect(
  ({
    entities: {
      unitPrefs: { loaded },
    },
  }) => ({ loaded })
)(UnitPreferences);
