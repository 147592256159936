import React from 'react';
import { connect } from 'react-redux';

import Selector from '../util/Selector';

const OrganizationSelector = ({
  orgs,
  selected,
  onChange,
  onValueChange,
  allowNulls,
  disabled,
}) => (
  <Selector
    options={orgs.map((org) => ({
      id: org.id,
      key: `${org.id}`,
      display: org.name,
    }))}
    showNullOption={allowNulls}
    selected={`${selected}`}
    onChange={(e) => {
      if (onChange) onChange(e);
      if (onValueChange) onValueChange(e.target.value);
    }}
    disabled={disabled}
  />
);

OrganizationSelector.defaultProps = {
  allowNulls: false,
};

export default connect(({ users: { organizations } }) => ({
  orgs: organizations,
}))(OrganizationSelector);
