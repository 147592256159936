import React from 'react';
import PropTypes from 'prop-types';

const Truncated = ({ value, length }) => (
  <span>{value?.length > length ? `${value.slice(0, length)}...` : value}</span>
);

Truncated.propTypes = {
  value: PropTypes.string.isRequired,
  length: PropTypes.number,
};

Truncated.defaultProps = {
  length: 10,
};

export default Truncated;
