import {
  LOGIN_POST_SUCCESS,
  LOGIN_POST_REQUEST,
  LOGIN_POST_ERROR,
  LOGIN_RESET_ERROR,
} from '../actions/actiontypes';

// A reducer function which describes how the application state tree
// should be updated in response to a login action (for the login 'slice'
// of the tree)
export default function login(
  state = {
    isFetching: false,
    errMessage: undefined,
    networkError: undefined,
  },
  action
) {
  switch (action.type) {
    case LOGIN_POST_REQUEST:
      return { ...state, isFetching: true, errMessage: undefined, networkError: undefined };
    case LOGIN_POST_ERROR:
    case LOGIN_POST_SUCCESS:
      return {
        ...state,
        isFetching: false,
        errMessage: action.errMessage,
        networkError: action.networkError,
      };
    case LOGIN_RESET_ERROR:
      return { ...state, errMessage: undefined, networkError: undefined };
    default:
      return state;
  }
}
