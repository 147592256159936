import { connect } from 'react-redux';
import { capitalize } from '../../utils/strings';

/**
 * Component to render a target with extra context if available
 */
const Target = ({ userID, type, id, buildings, users }) => {
  if (id == null) return null;
  if ((type == null || type === 'user') && id === userID) return 'You';
  if (type === 'building' && buildings[id]) return `${buildings[id].name} (${id})`;
  if (type === 'user' && users[id]) return users[id].email;
  return `${capitalize(type)} ${id}`;
};

export default connect(
  ({
    user: { userID },
    users: { byId: users },
    entities: {
      buildings: { byId: buildings },
    },
  }) => ({
    userID,
    users,
    buildings,
  })
)(Target);
