import React, { useState } from 'react';
import { connect } from 'react-redux';
import Papa from 'papaparse';
import { Typography } from '@mui/material';
import { fetchHomeAction, sendCommandAction } from '../../../redux/actions/rtemFiles';
import { mkdir } from '../files/commands';
import { chunkArray } from '../../../utils/collections';
import FilesLoader from '../files/FilesLoader';
import UploadTarget from './UploadTarget';
import UploadProgress from './UploadProgress';

import './site-creator.css';

/* eslint-disable no-console */

// There's a 10 req/s limit, each folder has at least two service years
const CREATE_BATCH_SIZE = 5;

const processRow = (row) => {
  const vendor = row['S3 Name'];
  const folder = row['Project Folder Name'].replaceAll('/', '');
  return {
    ...row,
    vendor,
    folder,
    years: Math.min(parseInt(row['Service Years'], 10), 5),
    path: `${vendor}/${folder}`,
  };
};

export const folders = (base, year) => [
  `${base}/Service Year ${year} Report 1`,
  `${base}/Service Year ${year} Report 2`,
];

const SiteCreator = ({ filesystem, command, refresh }) => {
  const [file, setFile] = useState(null);
  const [errors, setErrors] = useState([]);
  const [rows, setRows] = useState([]);
  const [processing, setProcessing] = useState(new Set());

  const folderMissing = (path) => filesystem[`${path}/`] == null;

  const status = (row) => {
    const { years, path } = row;
    if (folderMissing(path)) return 'missing';
    if (processing.has(path)) return 'processing';
    for (let year = 1; year <= years; year += 1) {
      const [first, second] = folders(path, year);
      if (folderMissing(first) || folderMissing(second)) return 'processing';
    }
    return 'exists';
  };

  const makeServiceYear = (base, year) => {
    const [first, second] = folders(base, year);
    return command(mkdir(first))
      .then(() => command(mkdir(second)))
      .catch(() => {});
  };
  const createFolders = () => {
    const batches = chunkArray(
      rows.filter((r) => status(r) !== 'exists'),
      CREATE_BATCH_SIZE
    );
    const runBatch = (i) => {
      const batch = batches[i];
      if (batch == null) return Promise.resolve();
      const pSet = new Set();
      batch.forEach((r) => pSet.add(r.path));
      setProcessing(pSet);
      const commands = batch.map((r) => {
        const f = [];
        for (let y = 1; y <= r.years; y += 1) {
          f.push(makeServiceYear(r.path, y));
        }
        return Promise.all(f);
      });
      return Promise.all(commands)
        .then(refresh)
        .then(() => runBatch(i + 1));
    };
    runBatch(0).then(() => setProcessing(new Set()));
  };

  const parseFile = (f) => {
    Papa.parse(f, {
      header: true,
      skipEmptyLines: true,
      complete: (results) => {
        const { data, errors: parseErrors } = results;
        if (parseErrors.length) {
          console.warn(`Parse of ${f.name} failed with errors: ${JSON.stringify(parseErrors)}`);
          return setErrors(parseErrors);
        }
        console.log(`Parse of ${f.name} finished without errors - ${data.length} rows`);
        return setRows(data.map(processRow));
      },
    });
  };

  return (
    <div className="site-creator">
      <FilesLoader />
      <div>
        {errors.map((e) => (
          <>
            <Typography color="error">{JSON.stringify(e)}</Typography>
            <br />
          </>
        ))}
      </div>

      {file == null ? (
        <UploadTarget
          filename={file?.name}
          setFile={(f) => {
            setFile(f);
            parseFile(f);
          }}
        />
      ) : (
        <UploadProgress
          filename={file?.name}
          rows={rows.map((r) => ({ ...r, status: status(r) }))}
          isProcessing={processing.size > 0}
          onCreate={createFolders}
        />
      )}
    </div>
  );
};

export default connect(
  ({ rtemFiles: { filesystem } }) => ({ filesystem }),
  (dispatch) => ({
    command: (cmd) => dispatch(sendCommandAction(cmd)),
    refresh: () => dispatch(fetchHomeAction()),
  })
)(SiteCreator);
