import { combineReducers } from 'redux';
import { ADD_MULTIPLE_TAGS } from '../actions/actiontypes';

function addOrUpdateTags(state, tags, key = 'id') {
  // return the state with the multiple tags added/replaced
  const newState = { ...state };
  tags.forEach((tag) => {
    const { id, name, definition, category, def_source: defSource, def_url: defUrl } = tag;
    newState[tag[key]] = {
      id,
      name,
      definition,
      defSource,
      defUrl,
      category,
    };
  });
  return newState;
}

function tagsById(state = {}, action) {
  switch (action.type) {
    case ADD_MULTIPLE_TAGS:
      return addOrUpdateTags(state, action.tags, 'id');
    default:
      return state;
  }
}

function tagsByName(state = {}, action) {
  switch (action.type) {
    case ADD_MULTIPLE_TAGS:
      return addOrUpdateTags(state, action.tags, 'name');
    default:
      return state;
  }
}

const byCategory = (state = {}, action) => {
  if (action.type !== ADD_MULTIPLE_TAGS) return state;
  const newState = action.tags.reduce((o, tag) => {
    const { category, name } = tag;
    if (category) {
      const curr = o[category] ?? [];
      curr.push(name);
      // eslint-disable-next-line no-param-reassign
      o[category] = curr;
    }
    return o;
  }, {});
  Object.values(newState).forEach((tagList) => tagList.sort());
  return newState;
};

function allTagIds(state = [], action) {
  switch (action.type) {
    case ADD_MULTIPLE_TAGS:
      return Array.from(new Set(state.concat(action.tags.map((t) => t.id))));
    default:
      return state;
  }
}

const tagReducer = combineReducers({
  byId: tagsById,
  byName: tagsByName,
  allIds: allTagIds,
  byCategory,
});

export default tagReducer;
