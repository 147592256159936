import { combineReducers } from 'redux';
import { SET_SERIES } from '../actions/actiontypes';

const addMultipleSeries = (state, series) => {
  const newState = { ...state };
  for (let i = 0; i < series.length; i += 1) {
    const s = series[i];
    newState[s.pointId] = s;
  }
  return newState;
};

function seriesById(state = {}, action) {
  switch (action.type) {
    case SET_SERIES:
      return addMultipleSeries(state, action.series);
    default:
      return state;
  }
}

function allSeriesIds(state = [], action) {
  switch (action.type) {
    case SET_SERIES:
      return Array.from(new Set(state.concat(action.series.map((s) => s.pointId))));
    default:
      return state;
  }
}

const seriesReducer = combineReducers({
  byId: seriesById,
  allIds: allSeriesIds,
});

export default seriesReducer;
