// eslint-disable-next-line import/prefer-default-export
export const downloadBlob = (blob, filename = 'export', immediately = true) => {
  // Create an object URL for the blob object
  const url = URL.createObjectURL(blob);
  const a = document.createElement('a');
  a.href = url;
  a.download = filename;
  const clickHandler = () => {
    setTimeout(() => {
      URL.revokeObjectURL(url);
      a.removeEventListener('click', clickHandler);
    }, 150);
  };
  a.addEventListener('click', clickHandler, false);
  if (immediately) a.click();
  return a;
};
