import React from 'react';
import PointTypeLoader from '../loaders/PointTypeLoader';
import EquipTypeLoader from '../loaders/EquipTypeLoader';
import TagsLoader from '../loaders/TagsLoader';
import UnitsLoader from '../loaders/UnitsLoader';
import MeasurementsLoader from '../loaders/MeasurementsLoader';
import ModelRouter from './ModelRouter';

/**
 * Outer page container for the data model explorer page - loads data and kicks off
 * subview routing
 */
const DataModelPage = ({ basePath }) => (
  <>
    <PointTypeLoader />
    <EquipTypeLoader />
    <TagsLoader />
    <UnitsLoader />
    <MeasurementsLoader />
    <ModelRouter base={basePath} />
  </>
);

export default DataModelPage;
