const isBuildingsView = (state) => state.ui.tabIndex === 0;

export const getEquipTypeFilter = (state) => state.ui.equipTypeFilter;
export const getDeviceNameFilter = (state) => state.ui.deviceNameFilter;
export const getLocDisplayOption = (state) => state.ui.byLocDisplayOption;

export function isBuildingLoading(state, bid) {
  return state.ui.buildingsLoadingData.has(bid);
}

export function isBuildingFetchingUploads(state) {
  return state.ui.buildingsFetchingUploads.has(state.ui.selectedBuildingId);
}

export function checkFeatureEnabled(state, featureName) {
  const {
    ui: { features },
  } = state;
  // eslint-disable-next-line no-prototype-builtins
  if (!features.hasOwnProperty(featureName)) return true;
  return features[featureName];
}

export default isBuildingsView;
