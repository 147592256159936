import Portal from './portal/App';
import GcpPortal from './gcpPortal/App';
import Nyserda from './nyserda/App';

/**
 * Select which app root is used by inspecing a build-time environment variable
 *
 * In theory this construct should allow dead-code-elim and tree-shaking to prune
 * the unused components.
 *
 * Unfortunately tree-shaking cannot work with a function doing if/else or switch/case so nesting ternary (gross) since that seems tree-shakable
 */
// eslint-disable-next-line no-nested-ternary
export default process.env.REACT_APP_WHICH === 'nyserda'
  ? Nyserda
  : process.env.REACT_APP_WHICH === 'gcp'
  ? GcpPortal
  : Portal;
