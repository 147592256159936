import { combineReducers } from 'redux';
import {
  ADD_MULTIPLE_POINTS,
  ADD_MULTIPLE_EQUIPMENT_AND_POINTS,
  DELETE_ALL_POINTS,
  ADD_POINT_UNITS,
} from '../actions/actiontypes';
import { roundIntsOnly } from '../../utils/parsing';
import { groupListByProperty, mapValues } from '../../utils/collections';

function addPointIdsFromEquipmentList(state, action) {
  const newState = new Set(state);
  action.equipment.forEach((equip) => equip.points?.forEach((p) => newState.add(p.id)));
  return Array.from(newState);
}

function getDisplayUnit(point) {
  return point.tagged_units ? point.tagged_units : point.units;
}

const getDataMapping = (point) =>
  point.state_text == null || typeof point.state_text !== 'object' ? null : point.state_text;

const getPointValue = ({ value }, dataMapping) => {
  if (dataMapping != null) {
    const parsed = roundIntsOnly(value);
    const mapped = dataMapping[parsed];
    if (mapped != null) return mapped;
  }
  return value || '';
};

const mapApiPointToApp = (point) => {
  const dataMapping = getDataMapping(point);
  const value = getPointValue(point, dataMapping);
  return {
    id: point.id,
    buildingId: point.building_id,
    equipId: point.equipId || point.equip_id,
    lastUpdatedTs: point.last_updated,
    device: point.device,
    networkDevice: point.network_device,
    objectId: point.objectId,
    name: point.name,
    rawUnitId: point.raw_unit_id,
    rawUnit: point.units,
    hash: point.datasource_hash,
    topic: point.topic,
    description: point.description,
    units: getDisplayUnit(point),
    value,
    pointTypeId: point.point_type_id,
    type: point.type || '',
    editedPointType: point.type || '',
    isUpdating: false,
    isUpdateError: false,
    dataMapping,
  };
};

function addPointsFromEquipmentList(state, action) {
  const newState = { ...state };
  for (let i = 0; i < action.equipment.length; i += 1) {
    const equip = action.equipment[i];
    if (equip.points) {
      for (let j = 0; j < equip.points.length; j += 1) {
        const point = equip.points[j];
        point.equipId = equip.id;
        newState[point.id] = mapApiPointToApp(point);
      }
    }
  }
  return newState;
}

function addOrUpdatePoints(state, points) {
  // return the state with the multiple points added/replaced
  const newState = { ...state };
  for (let i = 0; i < points.length; i += 1) {
    newState[points[i].id] = mapApiPointToApp(points[i]);
  }
  return newState;
}

function pointsById(state = {}, action) {
  switch (action.type) {
    case DELETE_ALL_POINTS:
      return {};
    case ADD_MULTIPLE_EQUIPMENT_AND_POINTS:
      return addPointsFromEquipmentList(state, action);
    case ADD_MULTIPLE_POINTS:
      return addOrUpdatePoints(state, action.points);
    default:
      return state;
  }
}

function allPointIds(state = [], action) {
  switch (action.type) {
    case DELETE_ALL_POINTS:
      return [];
    case ADD_MULTIPLE_EQUIPMENT_AND_POINTS:
      return addPointIdsFromEquipmentList(state, action);
    case ADD_MULTIPLE_POINTS:
      return Array.from(new Set(state.concat(action.points.map((p) => p.id))));
    default:
      return state;
  }
}

const byBuilding = (state = {}, action) => {
  let points;
  switch (action.type) {
    case DELETE_ALL_POINTS:
      return {};
    case ADD_MULTIPLE_EQUIPMENT_AND_POINTS: {
      points = action.equipment.map((equip) => equip.points).flat();
      break;
    }
    case ADD_MULTIPLE_POINTS: {
      points = action.points;
      break;
    }
    default:
      return state;
  }
  const updates = mapValues(groupListByProperty('building_id', points), (ps) =>
    ps.map((p) => p.id)
  );
  return { ...state, ...updates };
};

// points are stale iff they have not been updated in the past hour

function staleIdsByBuilding(state = {}, action) {
  let stalePoints;
  const staleTimestamp = new Date().getTime() - 60 * 60 * 1000;
  switch (action.type) {
    case DELETE_ALL_POINTS:
      return {};
    case ADD_MULTIPLE_EQUIPMENT_AND_POINTS: {
      stalePoints = action.equipment
        .map((equip) => equip.points)
        .flat()
        .filter((p) => p.last_updated < staleTimestamp);
      break;
    }
    case ADD_MULTIPLE_POINTS: {
      stalePoints = action.points.filter((p) => p.last_updated < staleTimestamp);
      break;
    }
    default:
      return state;
  }
  const update = mapValues(groupListByProperty('building_id', stalePoints), (ps) =>
    ps.map((p) => p.id)
  );
  return { ...state, ...update };
}

/**
 * A mapping from point id to the current value we're converting the point to
 * At the moment this is only populated by the timeseries data call
 */
const units = (state = {}, action) => {
  if (action.type !== ADD_POINT_UNITS) return state;
  return { ...state, ...action.data };
};

// A reducer function which describes how the application state tree
// should be updated in response to points actions
// (for the points 'slice'of the tree)
const pointsReducer = combineReducers({
  byId: pointsById,
  allIds: allPointIds,
  staleIdsByBuilding,
  units,
  byBuilding,
});

export default pointsReducer;
