import Cookies from 'js-cookie';

const LOGIN_COOKIE_NAME = 'obt';
const PROD = process.env.NODE_ENV === 'production';

export function clearLoginCookie() {
  Cookies.remove(LOGIN_COOKIE_NAME);
}

export function setLoginCookie(token) {
  if (token) {
    Cookies.set(LOGIN_COOKIE_NAME, token, { expires: 1 / 48, secure: PROD, sameSite: 'Strict' });
  }
}

export function getLoginCookie() {
  return Cookies.get(LOGIN_COOKIE_NAME);
}
