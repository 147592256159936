import { combineReducers } from 'redux';
import { SET_SUBMISSION_STATUS } from '../actions/actiontypes';

const ACTION_TO_STATUS = {
  's3file.submit': 'SUBMITTED',
  's3file.approve': 'APPROVED',
  's3file.reject': 'REJECTED',
  's3file.move': 'DELETED',
  's3file.delete': 'DELETED',
};

const ACTIONS = Object.keys(ACTION_TO_STATUS);

/**
 * This reducer is a helper for figuring out the state of a RTEM file submission.
 * Submissions are identified by their s3 path, and so we can establish the last time each of
 * the three status actions occured by going through all the action log items and keeping track
 * of the latest timestamps.
 */
const statusTimes = (state = {}, action) => {
  if (action.type !== SET_SUBMISSION_STATUS) return state;
  return {
    ...state,
    ...action.data
      .filter(({ action: a }) => ACTIONS.indexOf(a) !== -1)
      .reduce((o, logItem) => {
        let path = logItem.details?.path;
        if (path == null) return o;
        // delete and move actions are on files rather than folders, extract parent folder
        if (!path.endsWith('/')) {
          const parts = path.split('/');
          path = `${parts.slice(0, -1).join('/')}/`;
        }
        if (o[path] == null) {
          // eslint-disable-next-line no-param-reassign
          o[path] = {};
        }
        const status = ACTION_TO_STATUS[logItem.action];
        // eslint-disable-next-line no-param-reassign
        o[path][status] = Math.max(o[path][status] || -1, logItem.timestamp);
        return o;
      }, {}),
  };
};

// supports dead-code-elim in the portal app
export default process.env.REACT_APP_WHICH === 'nyserda'
  ? combineReducers({
      statusTimes,
    })
  : (state = null) => state;
