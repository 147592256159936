import React from 'react';
import { connect } from 'react-redux';
import { useConfirm } from 'material-ui-confirm';
import { useDrop } from 'react-dnd';
import { IconButton } from '@mui/material';
import { Folder, FolderOpen, Add, Remove } from '@mui/icons-material';
import {
  openDirAction,
  selectAction,
  unselectAction,
  sendCommandAction,
} from '../../../redux/actions/rtemFiles';
import { mv } from './commands';
import Indent from '../../util/Indent';
import File from './File';
import DirectoryActions from './DirectoryActions';
import Filename from './Filename';

import './directory.css';

/**
 * Display a directory and its content - recursively renders child directories
 */
const UnconnectedDirectory = ({
  name,
  dir,
  selected,
  open,
  openDir,
  select,
  unselect,
  command,
}) => {
  const isSelected = (path, classes) =>
    `selectable ${classes} ${selected === path ? 'selected ' : ''}`;
  // Note: the selection/open events are a bit of a mess right now. We have to stop propagation in child components
  // even with the check in this handler :-(
  const selectHandler = (e, path) => {
    // we need to check the target here so that other events bubbling up don't toggle selection
    if (e.target && e.target.matches('.selectable')) {
      if (selected !== path) {
        select(path);
      } else {
        unselect();
      }
    }
  };
  const confirm = useConfirm();
  // each folder is a react-dnd drop target for files, to allow "move-to-folder" functionality
  const [{ isOver }, drop] = useDrop({
    accept: 'file',
    canDrop: (item) => item.parent !== dir.path, // disallow no-op moves
    drop: (item, monitor) => {
      const hasDroppedOnChild = monitor.didDrop();
      if (hasDroppedOnChild) return;
      confirm({
        title: `Move '${item.name}' ?`,
        description: `This action will move '${item.name}' to '${dir.path}'`,
        confirmationText: `Move ${item.name}`,
      })
        .then(() => command(mv(item.id, `${dir.path}${item.name}`)))
        .catch(() => {});
    },
    hover: () => open || openDir(name, true), // "spring-loaded folders" (they open on file hover)
    collect: (monitor) => ({
      // "shallow" means parent drop targets are non-greedy, so we drop into the child folder only
      isOver: monitor.isOver({ shallow: true }) && monitor.canDrop(),
    }),
  });
  const toggleOpen = () => openDir(name, !open);
  const dirClick = (e) => {
    selectHandler(e, dir.path);
    toggleOpen();
  };
  return (
    <div
      key={name}
      data-path={dir.path}
      className={`dir ${isOver ? 'file-overhead' : ''}`}
      ref={drop}
    >
      <div
        className={isSelected(dir.path, 'dir-name')}
        onClick={dirClick}
        onKeyPress={dirClick}
        role="menuitem"
        tabIndex={0}
      >
        <IconButton aria-label="expand" onClick={toggleOpen} size="large">
          {open ? <Remove /> : <Add />}
        </IconButton>
        {open ? <FolderOpen className="icon" /> : <Folder className="icon" />}
        <Filename
          file={dir}
          isSelected={selected === dir.path}
          selectHandler={dirClick}
          canRename={false}
        />
      </div>
      {open && (
        <Indent>
          {dir.childDirs.sort().map((d) => (
            <Directory key={d} name={d} />
          ))}
          {dir.files.map((f) => (
            <File
              key={f.path}
              file={f}
              isSelected={selected === f.path}
              selectHandler={(e) => selectHandler(e, f.path)}
            />
          ))}
          <DirectoryActions dir={dir} />
        </Indent>
      )}
    </div>
  );
};

const Directory = connect(
  ({ rtemFiles: { openDirs, filesystem, selected } }, { name }) => ({
    dir: filesystem[name],
    open: openDirs[name],
    selected,
  }),
  (dispatch) => ({
    openDir: (dir, isOpen) => dispatch(openDirAction(dir, isOpen)),
    select: (path) => dispatch(selectAction(path)),
    unselect: () => dispatch(unselectAction()),
    command: (cmd) => dispatch(sendCommandAction(cmd)),
  })
)(UnconnectedDirectory);

export default Directory;
