import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import UsersLoader from '../../loaders/UsersLoader';
import OragnizationsLoader from '../../loaders/OrganizationsLoader';
import UsersTable from './UsersTable';
import Editor from '../Editor';

import '../user-mgmt.css';

const VendorEdit = ({ scrollTop }) => (
  <>
    <OragnizationsLoader />
    <UsersLoader />

    <div className="row grid-container-lg user-mgmt">
      <div className="view">
        <UsersTable scrollTop={scrollTop} />
      </div>
      <div className="edit">
        <Editor />
      </div>
    </div>
  </>
);

VendorEdit.propTypes = {
  scrollTop: PropTypes.func.isRequired,
};

export default connect()(VendorEdit);
