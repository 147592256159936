import React from 'react';
import { connect } from 'react-redux';
import { Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material';
import { browserTime } from '../../utils/parsing';
import UserAgentDevice from '../util/UserAgentDevice';
import ResultsViewer from '../analyticsQa/ResultsViewer';
import Target from './Target';

import './action-table.css';

/* eslint-disable camelcase */

const ActionTable = ({ logItems, variant }) => (
  <div className="action-table">
    <Table>
      <TableHead>
        <TableRow>
          <TableCell>Time</TableCell>
          <TableCell>Device</TableCell>
          <TableCell>Action</TableCell>
          <TableCell>User</TableCell>
          {variant !== 'USER' ? <TableCell>Target</TableCell> : null}
          <TableCell>Details</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {logItems.map((action) => (
          <TableRow key={action.id} hover>
            <TableCell>{browserTime(action.timestamp)}</TableCell>
            <TableCell>
              <UserAgentDevice userAgent={action.user_agent} ipAddress={action.ip_address} />
            </TableCell>
            <TableCell>
              <span className="code">{action.action}</span>
            </TableCell>
            <TableCell>
              <Target type="user" id={action.user_id} />
            </TableCell>
            {variant !== 'USER' ? (
              <TableCell>
                <Target type={action.target} id={action.target_id} />
              </TableCell>
            ) : null}
            <TableCell>
              <ResultsViewer results={action.details} />
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  </div>
);

ActionTable.defaultProps = {
  variant: 'USER',
};

export default connect(({ actionLog: { logItems } }) => ({
  logItems,
}))(ActionTable);
