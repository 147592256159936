import {
  SET_LOGIN_SCREEN,
  SET_BUILDING_IS_LOADING,
  SET_BUILDING_NOT_LOADING,
  SET_UPLOAD_STATE,
  SET_BACK_BUTTON_LABEL,
  SET_LOC_DISPLAY_OPTION,
  SET_RESULTS_VIEW_TYPE,
  SET_EQUIP_DISPLAY_OPTION,
  SET_BACK_BUTTON_LINK,
  RESET_UI,
  SET_FEATURES,
} from './actiontypes';

export function resetUIAction() {
  return {
    type: RESET_UI,
  };
}

export function setLoginScreenAction(isLoginScreen) {
  return {
    type: SET_LOGIN_SCREEN,
    isLoginScreen,
  };
}

export function setBuildingLoading(buildingId) {
  return {
    type: SET_BUILDING_IS_LOADING,
    buildingId,
  };
}

export function setBuildingNotLoading(buildingId) {
  return {
    type: SET_BUILDING_NOT_LOADING,
    buildingId,
  };
}

export function setUploadState(uploadState) {
  // sets the ui state on the upload view
  return {
    type: SET_UPLOAD_STATE,
    uploadState,
  };
}

export function setBackButtonLabel(label) {
  return {
    type: SET_BACK_BUTTON_LABEL,
    label,
  };
}

export function setBackButtonLinkAction(link) {
  return {
    type: SET_BACK_BUTTON_LINK,
    link,
  };
}

export function setLocationDisplayOption(option) {
  const validOptions = ['flow', 'physlocation', 'areaserved'];
  if (!validOptions.includes(option)) {
    const error = `Location display option '${option}' is not valid!`;
    throw error;
  }
  return {
    type: SET_LOC_DISPLAY_OPTION,
    byLocDisplayOption: option,
  };
}

export function setResultsViewType(resultsViewType) {
  return {
    type: SET_RESULTS_VIEW_TYPE,
    resultsViewType,
  };
}

export function setEquipDisplayOption(option) {
  const validOptions = ['name', 'sensor'];
  if (!validOptions.includes(option)) {
    const error = `Equip display option '${option}' is not valid!`;
    throw error;
  }
  return {
    type: SET_EQUIP_DISPLAY_OPTION,
    byEquipDisplayOption: option,
  };
}

export function setFeatures(features) {
  return {
    type: SET_FEATURES,
    features,
  };
}
