import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Button, Typography, LinearProgress } from '@mui/material';
import UserEdit from '../userMgmt/UserEdit';
import {
  getUsersAndRolesAction,
  beginEditingUser,
  resetEditorAction,
  updateEditorAction,
  saveEditorAction,
} from '../../redux/actions/users';

import './account.css';

/**
 * Controlled component that allows for editing of first, last & password
 */
const ProfileEdit = ({ currentUserId, editorError, update, revert, save, load, editUser }) => {
  const [saving, setSaving] = useState(false);
  const [loaded, setLoaded] = useState(false);

  const loader = () => {
    load()
      .then(() => editUser(currentUserId))
      .then(() => setLoaded(true));
  };

  useEffect(loader, [currentUserId]);

  const saveHandler = () => {
    setSaving(true);
    save().then(() => {
      setSaving(false);
      setLoaded(false);
      loader();
    });
  };
  const revertHandler = () => revert() && loader();

  if (!loaded) {
    return <LinearProgress color="secondary" className="loading" />;
  }

  return (
    <div className="row grid-container-lg account" key={currentUserId}>
      <div className="row">
        <Typography variant="h4">Your Profile</Typography>
      </div>
      <div className="row">
        <UserEdit variant="SELF" onChange={(data) => update(currentUserId, data)} />
        {editorError && (
          <Typography variant="h6" color="error">
            {editorError}
          </Typography>
        )}
      </div>

      <div className="row submit">
        <Button color="primary" variant="contained" onClick={saveHandler} disabled={saving}>
          Save changes
        </Button>
        <Button onClick={revertHandler} color="secondary" variant="contained" disabled={saving}>
          Revert
        </Button>
      </div>
    </div>
  );
};

export default connect(
  ({
    user: { userID },
    users: {
      users,
      editing: { error },
    },
  }) => ({
    currentUserId: userID,
    info: users.filter(({ id }) => id === userID)[0],
    editorError: error,
  }),
  (dispatch) => ({
    load: () => dispatch(getUsersAndRolesAction()),
    editUser: (id) => dispatch(beginEditingUser(id)),
    update: (id, data) => dispatch(updateEditorAction('USER', id, data)),
    revert: () => dispatch(resetEditorAction()),
    save: () => dispatch(saveEditorAction()),
  })
)(ProfileEdit);
