import { SET_UNITS } from './actiontypes';
import { getUnits } from '../../api/Api';

// eslint-disable-next-line import/prefer-default-export
export const getUnitsAction = () => (dispatch, getState) => {
  const {
    user: { jwt },
    entities: { units: all },
  } = getState();

  if (all.length) return;

  getUnits(jwt).then((data) => dispatch({ type: SET_UNITS, data }));
};
