import {
  SET_USERS,
  SET_ROLES,
  SET_ROLE_TYPES,
  SET_ORGS,
  SET_EDIT,
  RESET_EDIT,
  SET_EDITOR_ERROR,
} from './actiontypes';
import {
  getUsers,
  getRoles,
  getRoleTypes,
  getOrganizations,
  createNewUser,
  editUser,
  createNewRole,
  editRole,
  createNewOrg,
  editOrg,
  beginPasswordReset,
} from '../../api/Api';

const sendData = (type, data) => ({
  type,
  data,
});

export const getUsersAndRolesAction = () => (dispatch, getState) => {
  const {
    user: { jwt },
  } = getState();

  return Promise.all([
    getUsers(jwt).then(({ data }) => dispatch(sendData(SET_USERS, data))),
    getRoles(jwt).then(({ data }) => dispatch(sendData(SET_ROLES, data))),
    getRoleTypes(jwt).then(({ data }) => dispatch(sendData(SET_ROLE_TYPES, data))),
    getOrganizations(jwt).then(({ data }) => dispatch(sendData(SET_ORGS, data))),
  ]);
};

export const fetchUsersAction = () => (dispatch, getState) => {
  const {
    user: { jwt },
  } = getState();
  return getUsers(jwt).then(({ data }) => dispatch(sendData(SET_USERS, data)));
};

export const getOrganizationsAction = () => (dispatch, getState) => {
  const {
    user: { jwt },
  } = getState();
  getOrganizations(jwt).then(({ data }) => dispatch(sendData(SET_ORGS, data)));
};

export const resetEditorAction = () => ({ type: RESET_EDIT });

const setEditorErrorAction = (message) => ({
  type: SET_EDITOR_ERROR,
  message,
});

export const updateEditorAction = (editType, id, data) => (dispatch, getState) => {
  dispatch({ type: SET_EDIT, edit: { editType, data, id } });
  const {
    users: {
      editing: { error },
    },
  } = getState();
  if (error !== '') dispatch(setEditorErrorAction(''));
};

/* eslint-disable camelcase */

const fail = (m) => Promise.reject(m);

const validateUserChange = (data) => {
  const { first_name, last_name, email, org_id, roles, new_password, username } = data;
  if (!first_name) return fail('First name is required');
  if (!last_name) return fail('Last name is required');
  if (!email) return fail('Email is required');
  if (org_id == null || org_id <= 0) return fail('Every user must be part of an organization');
  if (!roles || !roles.length) return fail('Every user must have at least one role');
  // not required to set a new password every time you submit the form, but if you do...
  if (new_password && new_password.length < 8) return fail('An 8+ character password is required');
  if (username && (username.length < 3 || username.length > 32))
    return fail('Username must be between 3 and 32 chars');

  return Promise.resolve('ok');
};

const validateRole = (data) => {
  const { role_type_id, name, description } = data;
  if (!name) return fail('Name is required');
  if (!description) return fail('Description is required');
  if (role_type_id == null || role_type_id <= 0) return fail('Role type is required');

  return Promise.resolve('ok');
};

const validateOrg = (data) => {
  const { name } = data;
  if (!name) return fail('Name is required');

  return Promise.resolve('ok');
};

/* eslint-enable camelcase */

const handleSave = (promise, dispatch) =>
  promise
    .then(() => {
      dispatch(resetEditorAction());
      dispatch(getUsersAndRolesAction());
    })
    .catch((msg) => dispatch(setEditorErrorAction(msg)));

/**
 * Attempt to save the contents of the editor to the server. If successful, reset the editor
 */
export const saveEditorAction = () => (dispatch, getState) => {
  const {
    users: {
      editing: { editType, data, id },
    },
    user: { jwt },
  } = getState();

  dispatch(setEditorErrorAction(''));

  let action;

  switch (editType) {
    case 'USER': {
      action = validateUserChange(data).then(() =>
        id < 0 ? createNewUser(data, jwt) : editUser(data, id, jwt)
      );
      break;
    }
    case 'ROLE': {
      action = validateRole(data).then(() =>
        id < 0 ? createNewRole(data, jwt) : editRole(id, data, jwt)
      );
      break;
    }
    case 'ORG': {
      action = validateOrg(data).then(() =>
        id < 0 ? createNewOrg(data, jwt) : editOrg(id, data, jwt)
      );
      break;
    }
    case 'NONE':
    default:
      // the save button should be hidden so that this is hard to do, but for completeness...
      dispatch(setEditorErrorAction('Only user editing and role creation is supported so far'));
      return Promise.reject(Error('unimplemented'));
  }

  return handleSave(action, dispatch);
};

const NEW_USER_DATA = {
  id: -1,
  first_name: '',
  last_name: '',
  email: '',
  active: true,
  new_password: '',
  roles: [],
  send_welcome: true,
  username: '',
  attrs: process.env.REACT_APP_WHICH === 'nyserda' ? { s3_homedir: '', affiliation: '' } : {},
  welcome_variant: process.env.REACT_APP_WHICH === 'nyserda' ? 'nyserda' : 'portal',
};

export const beginEditingUser = (userId) => (dispatch, getState) => {
  const {
    users: { users },
  } = getState();
  const user = users.filter((u) => u.id === userId)[0] || {};
  const userEditData = { ...user };
  // don't let components switch between un/controlled
  Object.keys(NEW_USER_DATA).forEach((key) => {
    userEditData[key] = userEditData[key] || NEW_USER_DATA[key];
  });
  userEditData.active = !!user.active;
  dispatch(updateEditorAction('USER', userId, userEditData));
};

export const beginNewUser = () => (dispatch, getState) => {
  const {
    user: { userID },
    users: { users },
  } = getState();
  const currentUser = users.filter((u) => u.id === userID)[0] || {};
  // default the new user's org_id to the current user's
  const initialEditData = { ...NEW_USER_DATA, org_id: currentUser.org_id };
  dispatch(updateEditorAction('USER', NEW_USER_DATA.id, initialEditData));
};

export const sendWelcomeEmail = (variant) => (dispatch, getState) => {
  const {
    users: {
      editing: {
        data: { email },
      },
    },
  } = getState();
  return email
    ? beginPasswordReset(email, 'WELCOME', variant)
    : Promise.reject(new Error('No email'));
};

const NEW_ROLE_DATA = {
  id: -1,
  role_type_id: null,
  org_id: null,
  name: '',
  description: '',
};

export const beginNewRole = () => (dispatch) =>
  dispatch(updateEditorAction('ROLE', NEW_ROLE_DATA.id, { ...NEW_ROLE_DATA }));

export const beginEditingRole = (roleId) => (dispatch, getState) => {
  const {
    users: { roles },
  } = getState();
  const role = roles.filter((r) => r.id === roleId)[0] || {};
  dispatch(updateEditorAction('ROLE', roleId, role));
};

const NEW_ORG_DATA = {
  id: -1,
  name: '',
  short_name: '',
  name_abbr: '',
  primary_color: '',
  secondary_color: '',
  logo_url: '',
  mfa_required: false,
  ingest_alerts: true,
};

export const beginNewOrg = () => (dispatch) =>
  dispatch(updateEditorAction('ORG', NEW_ORG_DATA.id, { ...NEW_ORG_DATA }));

export const beginEditingOrg = (orgId) => (dispatch, getState) => {
  const {
    users: { orgsById },
  } = getState();
  const org = orgsById[orgId] || {};
  const orgEdit = { ...org };
  // don't want the edit components switching between uncontrolled/controlled
  Object.keys(NEW_ORG_DATA).forEach((key) => {
    orgEdit[key] = orgEdit[key] ?? NEW_ORG_DATA[key];
  });
  dispatch(updateEditorAction('ORG', orgId, orgEdit));
};
