function getEnv() {
  if (process.env.REACT_APP_API_ENV != null) return process.env.REACT_APP_API_ENV;
  const host = (window && window.location && window.location.host) || '';
  if (host.indexOf('localhost') !== -1) {
    return 'LOCAL';
  }
  if (host.indexOf('devportal.onboarddata.io') !== -1) {
    return 'DEV';
  }
  if (host.indexOf('dbo-rews-dev.onboarddata.io') !== -1) {
    return 'GCP';
  }
  // default to production in case something goes wrong
  return 'PROD';
}

const URLS = {
  api: {
    LOCAL: 'http://localhost:6543',
    DEV: 'https://devapi.onboarddata.io',
    PROD: 'https://api.onboarddata.io',
    GCP: 'https://dbo-rews-dev.onboarddata.io/api/',
  },
  analytics: {
    LOCAL: 'http://localhost:5000',
    PROD: 'https://analytics.onboarddata.io',
  },
};

const CLIENT_IDS = {
  GCP: '163054488095-86bfjigk8j7n9rfjrcpgor35jm5olilu.apps.googleusercontent.com',
};

const getApiBaseUrl = () => URLS.api[getEnv()];

export const getGcpClientId = () => CLIENT_IDS[getEnv()];

export const getAnalyticsUrl = () => URLS.analytics[getEnv()];

export default getApiBaseUrl;
