import React from 'react';
import { Tabs, Tab } from '@mui/material';
import { useMatch } from 'react-router-dom';
import { Page, Row } from '../util/Page';
import DataModelPage from '../dataModel/DataModelPage';
import SwaggerUi from './AuthorizedSwaggerUi';
import Clients from './Clients';
import GettingStarted from './GettingStarted';
import RtemGettingStarted from './RtemGettingStarted';
import './api-docs.css';

const IS_RTEM = process.env.REACT_APP_WHICH === 'nyserda';

const content = (tab) => {
  switch (tab) {
    case 'data-model':
      return <DataModelPage basePath="/docs/data-model" />;
    case 'clients':
      return <Clients />;
    case 'getting-started':
      return IS_RTEM ? <RtemGettingStarted /> : <GettingStarted />;
    case 'swagger':
    default:
      return <SwaggerUi />;
  }
};

const TABS = ['getting-started', 'swagger', 'data-model', 'clients'];
const NAMES = {
  swagger: 'API Documentation',
  'data-model': 'Data Model',
  clients: 'API Clients',
  'getting-started': 'Getting started',
};

const ApiAndDocs = ({ navigate }) => {
  const match = useMatch('/docs/:tab/*');
  const { tab } = match?.params ?? {};
  const tabIndex = Math.max(TABS.indexOf(tab), 0);
  const resolvedTab = TABS[tabIndex];
  return (
    <Page name="api-docs">
      <Row>
        <Tabs
          indicatorColor="secondary"
          textColor="inherit"
          value={tabIndex}
          onChange={(e, val) => navigate(`/docs/${TABS[val]}`)}
        >
          {TABS.filter((t) => !IS_RTEM || t !== 'clients').map((t) => (
            <Tab key={t} label={NAMES[t]} />
          ))}
        </Tabs>
      </Row>
      <Row>{content(resolvedTab)}</Row>
    </Page>
  );
};

export default ApiAndDocs;
