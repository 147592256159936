import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { setLoginCookie } from '../../api/loginCookie';
import { restoreLoginActionCreator } from '../../redux/actions/login';

class LoginPersistence extends React.Component {
  state = {
    interval: null,
    restored: false,
  };

  componentDidMount() {
    this.restoreLogin();
    const interval = setInterval(() => this.saveTokenToCookie(), 60 * 1000);
    // eslint-disable-next-line react/no-did-mount-set-state
    this.setState({ interval });
  }

  componentWillUnmount() {
    const { interval } = this.state;
    clearInterval(interval);
  }

  saveTokenToCookie() {
    const { token } = this.props;
    setLoginCookie(token);
  }

  restoreLogin() {
    const { restored } = this.state;
    if (!restored) {
      this.setState({ restored: true }, this.props.restoreLogin);
    }
  }

  render() {
    return null;
  }
}

LoginPersistence.propTypes = {
  token: PropTypes.string,
  restoreLogin: PropTypes.func.isRequired,
};

LoginPersistence.defaultProps = {
  token: null,
};

export default connect(
  ({ user: { jwt: token } }) => ({ token }),
  (dispatch) => ({ restoreLogin: () => dispatch(restoreLoginActionCreator()) })
)(LoginPersistence);
