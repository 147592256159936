import React from 'react';
import { connect } from 'react-redux';
import { Typography } from '@mui/material';
import {
  startFido2LoginActionCreator,
  completeFido2LoginActionCreator,
} from '../../../redux/actions/login';
import {
  transformCredentialRequestOptions,
  transformAssertionForServer,
  getPublicKey,
} from '../../account/fido2';

class FidoLogin extends React.PureComponent {
  state = {
    error: '',
  };

  componentDidMount() {
    this.props
      .startFido2()
      .then(transformCredentialRequestOptions)
      .then(getPublicKey)
      .then(transformAssertionForServer)
      .then(this.props.completeFido2)
      .catch((e) => this.setState({ error: `${e}` }));
  }

  render() {
    return (
      <div>
        <Typography variant="h4">Use your FIDO2 device to finish logging in</Typography>
        <Typography variant="h6" color="error">
          {this.state.error}
        </Typography>
      </div>
    );
  }
}

export default connect(null, (dispatch) => ({
  startFido2: () => dispatch(startFido2LoginActionCreator()),
  completeFido2: (data) => dispatch(completeFido2LoginActionCreator(data)),
}))(FidoLogin);
