import React from 'react';
import { connect } from 'react-redux';
import { permissionToAssign } from '../../../redux/selectors/users';
import Selector from '../../util/Selector';

const GenericSelector = ({ roles, selected, onChange, disabled = false }) => (
  <Selector
    options={roles.map((role) => ({
      id: role.id,
      key: `${role.id}`,
      display: `${role.name} - ${role.description}`,
    }))}
    disabled={disabled}
    selected={selected}
    onChange={onChange}
  />
);

export const RoleTypeSelector = connect(({ users: { roleTypes } }) => ({
  roles: roleTypes,
}))(GenericSelector);

class RoleSelectorUnconnected extends React.PureComponent {
  componentDidMount() {
    // If there's only one role available, auto-select it when creating a new user
    const { roles, selected, onChange } = this.props;
    if (roles?.length === 1 && !selected && onChange) {
      onChange({ target: { value: roles[0].id } });
    }
  }

  render() {
    const { roles, selected, onChange } = this.props;
    return <GenericSelector roles={roles} selected={selected} onChange={onChange} />;
  }
}

export const RoleSelector = connect(({ users: { roles, roleTypes }, user: { userAuthLevel } }) => ({
  roles: permissionToAssign(roles, roleTypes, userAuthLevel),
}))(RoleSelectorUnconnected);
