import React from 'react';
import { connect } from 'react-redux';
import { AUTH_LEVELS } from '../../auth/Authorized';
import SiteCreator from './SiteCreator';
import SiteEditor from './SiteEditor';
import DataSubmitButton from './DataSubmitButton';
import DataFeedbackButtons from './DataFeedbackButtons';
import UploadButton from './UploadButton';

const DirectoryActions = ({ dir, showSiteTools }) => {
  switch (dir.level) {
    case 1: // top level (user home directory)
      return showSiteTools ? <SiteCreator path={dir.path} /> : null;
    case 2: // Site
      return showSiteTools ? <SiteEditor path={dir.path} /> : null;
    case 3: // service report e.g. "Service Year 1 Report 1"
      return (
        <>
          <UploadButton path={dir.path} />
          <DataSubmitButton dir={dir} />
          {showSiteTools && <DataFeedbackButtons dir={dir} />}
        </>
      );
    default:
      return null;
  }
};

export default connect(({ user: { userAuthLevel } }) => ({
  showSiteTools: userAuthLevel <= AUTH_LEVELS.ORG_ADMIN,
}))(DirectoryActions);
