import React from 'react';
import { ThemeProvider, StyledEngineProvider } from '@mui/material';
import CssBaseline from '@mui/material/CssBaseline';
import { DEFAULT_THEME } from '../../theme';

const ThemedComponent = ({ children }) => (
  <StyledEngineProvider injectFirst>
    <ThemeProvider theme={DEFAULT_THEME}>
      <CssBaseline />
      {children}
    </ThemeProvider>
  </StyledEngineProvider>
);

export default ThemedComponent;
