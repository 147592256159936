import { ADD_MULTIPLE_TAGS } from './actiontypes';
import { UPLOAD_STATES } from '../reducers/ui';
import {
  getAll,
  uploadTagDefinitions,
  uploadTagPointTypeAssociations,
  uploadTagEquipmentTypeAssociations,
} from '../../api/Api';
import { setUploadState } from './ui';

function addMultipleTagsAction(tags) {
  return {
    type: ADD_MULTIPLE_TAGS,
    tags,
  };
}

function fetchAllTagsActionCreator() {
  return (dispatch, getState) => {
    const state = getState();
    const tags = state.entities.tags.allIds;
    const { jwt } = state.user;

    // if the equiptypes were loaded recently, abort fetch
    if (tags && tags.length > 0) {
      return Promise.resolve();
    }

    const request = getAll(jwt, 'tags').then(
      (response) => {
        dispatch(addMultipleTagsAction(response));
      },
      // eslint-disable-next-line no-console
      (error) => console.log(error)
    );
    return request;
  };
}

export const uploadTagsAction = (file, allowNew) => (dispatch, getState) => {
  const {
    user: { jwt },
  } = getState();
  // let UI know we're processing request
  dispatch(setUploadState(UPLOAD_STATES.PROCESSING));
  return uploadTagDefinitions(allowNew)(file, jwt).then(
    () => {
      dispatch(setUploadState(UPLOAD_STATES.SUCCESS));
      return Promise.resolve('ok');
    },
    (error) => {
      dispatch(setUploadState(UPLOAD_STATES.FAIL));
      return Promise.reject(error);
    }
  );
};

export const uploadTagPointTypeAssociationAction = (file) => (dispatch, getState) => {
  const {
    user: { jwt },
  } = getState();
  // let UI know we're processing request
  dispatch(setUploadState(UPLOAD_STATES.PROCESSING));
  return uploadTagPointTypeAssociations(file, jwt).then(
    () => {
      dispatch(setUploadState(UPLOAD_STATES.SUCCESS));
      return Promise.resolve('ok');
    },
    (error) => {
      dispatch(setUploadState(UPLOAD_STATES.FAIL));
      return Promise.reject(error);
    }
  );
};

export const uploadTagEquipmentTypeAssociationAction = (file) => (dispatch, getState) => {
  const {
    user: { jwt },
  } = getState();
  // let UI know we're processing request
  dispatch(setUploadState(UPLOAD_STATES.PROCESSING));
  return uploadTagEquipmentTypeAssociations(file, jwt).then(
    () => {
      dispatch(setUploadState(UPLOAD_STATES.SUCCESS));
      return Promise.resolve('ok');
    },
    (error) => {
      dispatch(setUploadState(UPLOAD_STATES.FAIL));
      return Promise.reject(error);
    }
  );
};

export default fetchAllTagsActionCreator;
