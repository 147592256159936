import {
  RTEM_FILES_FETCH_START,
  RTEM_FILES_FETCH_END,
  RTEM_FILES_FETCH_ERROR,
  RTEM_FILES_OPEN_DIR,
  RTEM_FILES_SELECT,
  SET_RTEM_NOTIFICATIONS,
  SET_SUBMISSION_STATUS,
} from './actiontypes';
import {
  fetchHomeDirectory,
  sendRtemFilesCommand,
  submitDataNotification,
  uploadPresignedRequest,
  getNotificationPrefs,
  changeNotificationPref,
  unsubscribeAllNotifications,
  resubscribeAllNotifications,
  approveDenyDataSubmission,
  fetchSubmissionStatus,
} from '../../api/Api';
import { putStart, putFinish } from '../../components/nyserda/files/commands';
import { queryActionsActionCreator } from './actionLog';

export const fetchHomeAction = () => (dispatch, getState) => {
  const {
    user: { jwt },
  } = getState();
  dispatch({ type: RTEM_FILES_FETCH_START });
  return fetchHomeDirectory(jwt)
    .then((data) => dispatch({ type: RTEM_FILES_FETCH_END, data }))
    .catch((e) => dispatch({ type: RTEM_FILES_FETCH_ERROR, error: `${e}` }));
};

export const sendCommandAction =
  (command, refetch = true) =>
  (dispatch, getState) => {
    const {
      user: { jwt },
    } = getState();
    dispatch({ type: RTEM_FILES_FETCH_START });
    return sendRtemFilesCommand(command, jwt)
      .then(() => (refetch ? dispatch(fetchHomeAction()) : Promise.resolve('ok')))
      .catch((e) => dispatch({ type: RTEM_FILES_FETCH_ERROR, error: `${e}` }));
  };

export const openDirAction = (dir, isOpen) => (dispatch) =>
  dispatch({ type: RTEM_FILES_OPEN_DIR, dir, isOpen });

export const selectAction = (path) => (dispatch) =>
  dispatch({ type: RTEM_FILES_SELECT, target: path });

export const unselectAction = () => (dispatch) => dispatch({ type: RTEM_FILES_SELECT, target: '' });

// Ensures the directory and all its parents are open, selects the directory
export const focusDirAction = (dir) => (dispatch) => {
  const splits = dir.split('/');
  for (let i = 1; i <= splits.length; i += 1) {
    const parent = `${splits.slice(0, i).join('/')}/`;
    dispatch(openDirAction(parent, true));
  }
  dispatch(selectAction(dir + (dir.endsWith('/') ? '' : '/')));
};

export const dataNotificationAction = (path) => (dispatch, getState) => {
  const {
    user: { jwt },
  } = getState();
  return submitDataNotification(path, jwt)
    .then(() => dispatch(queryActionsActionCreator({ force: true })))
    .catch((e) =>
      dispatch({ type: RTEM_FILES_FETCH_ERROR, error: `Error sending notification: ${e}` })
    );
};

export const fetchSubmissionStatusActionCreator = () => (dispatch, getState) => {
  const {
    user: { jwt },
  } = getState();
  return fetchSubmissionStatus(jwt)
    .then((data) => dispatch({ type: SET_SUBMISSION_STATUS, data }))
    .catch((e) =>
      dispatch({ type: RTEM_FILES_FETCH_ERROR, error: `Error fetching submission status: ${e}` })
    );
};

export const approveDenySubmissionAction = (path, status) => (dispatch, getState) => {
  const {
    user: { jwt },
  } = getState();
  return approveDenyDataSubmission(path, status, jwt)
    .then(() => dispatch(queryActionsActionCreator({ force: true })))
    .catch((e) =>
      dispatch({ type: RTEM_FILES_FETCH_ERROR, error: `Error approving data submission ${e}` })
    );
};

export const uploadFileAction = (dir, filename, file) => (dispatch, getState) => {
  const {
    user: { jwt },
  } = getState();
  const key = `${dir}/${filename}`.replace('//', '/');
  return sendRtemFilesCommand(putStart(key), jwt)
    .then(({ results: { url, fields } }) => uploadPresignedRequest(url, fields, file))
    .then(() =>
      Promise.all([sendRtemFilesCommand(putFinish(key), jwt), dispatch(fetchHomeAction())])
    )
    .catch((e) =>
      dispatch({ type: RTEM_FILES_FETCH_ERROR, error: `Error uploading '${filename}': ${e}` })
    );
};

// Admin notification preferences stuff

export const getNotificationPrefsAction = () => (dispatch, getState) => {
  const {
    user: { jwt },
  } = getState();
  return getNotificationPrefs(jwt).then((data) => dispatch({ type: SET_RTEM_NOTIFICATIONS, data }));
};

export const changeNotificationPrefAction = (affiliation, willReceive) => (dispatch, getState) => {
  const {
    user: { jwt },
  } = getState();
  return changeNotificationPref({ affiliation, will_receive: willReceive }, jwt).then(() =>
    dispatch(getNotificationPrefsAction())
  );
};

export const subscribeAllAction = () => (dispatch, getState) => {
  const {
    user: { jwt },
  } = getState();
  return resubscribeAllNotifications(jwt).then(() => dispatch(getNotificationPrefsAction()));
};

export const unsubscribeAllAction = () => (dispatch, getState) => {
  const {
    user: { jwt },
  } = getState();
  return unsubscribeAllNotifications(jwt).then(() => dispatch(getNotificationPrefsAction()));
};
