import { SET_UNITS } from '../actions/actiontypes';
import { alphabetical, groupByProperty } from '../../utils/collections';

const unitReducer = (state = { all: [], byId: {}, byName: {} }, action) => {
  switch (action.type) {
    case SET_UNITS: {
      const units = action.data || [];
      return {
        all: alphabetical('name_long', units),
        byId: groupByProperty('id', units),
        byName: groupByProperty('name_abbr', units),
      };
    }
    default:
      return state;
  }
};

export default unitReducer;
