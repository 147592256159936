import React from 'react';
import { connect } from 'react-redux';
import { Typography } from '@mui/material';
import { TotpLoader, Fido2Loader } from '../loaders/mfa';
import TotpLogin from './types/TotpLogin';
import FidoLogin from './types/FidoLogin';
import './LoginView.css';

/**
 * Component to handle secondary authentication methods during the login process
 */
const MfaLogin = ({ hasTotp, hasFido2, error }) => (
  <div className="LoginView-center mfa-login">
    <TotpLoader />
    <Fido2Loader />
    <div className="LoginView-col">
      {hasFido2 && <FidoLogin />}
      {hasTotp && hasFido2 && (
        <>
          <br />
          <Typography variant="h5">Or</Typography>
          <br />
        </>
      )}
      {hasTotp && <TotpLogin />}
      {error && (
        <div>
          <Typography variant="h4" color="error">
            {error}
          </Typography>
        </div>
      )}
    </div>
  </div>
);

export default connect(
  ({
    login: { errMessage },
    user: {
      mfa: { totp, fido2 },
    },
  }) => ({
    error: errMessage,
    hasTotp: totp.length > 0,
    hasFido2: fido2.length > 0,
  })
)(MfaLogin);
