import { ADD_MULTIPLE_EQUIPMENT_AND_POINTS, DELETE_ALL_EQUIPMENT } from './actiontypes';
import { getAllEquipmentForBuilding } from '../../api/Api';
import { setBuildingLoading, setBuildingNotLoading } from './ui';
import { getOrgsBuildings } from '../selectors/buildings';

export function deleteAllEquipmentAction() {
  return {
    type: DELETE_ALL_EQUIPMENT,
  };
}

function addMultipleEquipmentAndPointsAction(equipment) {
  return {
    type: ADD_MULTIPLE_EQUIPMENT_AND_POINTS,
    equipment,
  };
}

export function fetchEquipForBuildingActionCreator(
  buildingId,
  jwt = null,
  getPoints = true,
  force = false
) {
  return (dispatch, getState) => {
    // if the building already has up to date equipment, abort fetch
    const state = getState();
    if (!jwt) {
      // easier to do this than to rework all usages of the action creator
      // eslint-disable-next-line no-param-reassign
      jwt = state.user.jwt;
    }
    const equip = state.entities.buildings.equipment[buildingId];
    if (!force && equip && equip.receivedAt && !equip.isInvalid) {
      return Promise.resolve();
    }

    // let the UI know we're loading the building
    dispatch(setBuildingLoading(buildingId));

    return getAllEquipmentForBuilding(buildingId, jwt, getPoints)
      .then((response) => {
        // map only equip data (ignore any points data for now)
        // TODO: replace with normalizr
        const equipment = response.map((e) => ({
          id: e.id,
          buildingId: e.building_id,
          suffix: e.suffix,
          typeName: e.equip_type_name,
          typeId: e.equip_type_id,
          subTypeId: e.equip_subtype_id,
          typeAbbr: e.equip_type_abbr,
          flowOrder: e.flow_order,
          points: e.points,
          floorNumPhys: e.floor_num_physical,
          floorNumServed: e.floor_num_served,
          parents: e.parent_equip,
          children: e.child_equip,
          tags: e.tags,
        }));

        // dispatch action to add equip
        dispatch(addMultipleEquipmentAndPointsAction(equipment));
      })
      .finally(() => dispatch(setBuildingNotLoading(buildingId)));
  };
}

export function fetchEquipForAllBuildingsActionCreator(org, getPoints = true) {
  return (dispatch, getState) => {
    const state = getState();
    const {
      user: { jwt },
    } = state;
    const buildingIds = getOrgsBuildings(state, org).map((b) => b.id);
    buildingIds.forEach((bid) => dispatch(fetchEquipForBuildingActionCreator(bid, jwt, getPoints)));
  };
}
