import { AUTH_LEVELS } from '../../components/auth/Authorized';

const byId = (list) => {
  const map = {};
  list.forEach((l) => {
    map[l.id] = l;
  });
  return map;
};

// Super admins can assign any role
// Org admins can't make more org admins
export const permissionToAssign = (roles, roleTypes, authLevel) => {
  if (authLevel === AUTH_LEVELS.SUPER_ADMIN) {
    return roles;
  }
  const roleTypesById = byId(roleTypes);
  return roles.filter((r) => {
    const type = roleTypesById[r.role_type_id];
    return type && type.level > AUTH_LEVELS.ORG_ADMIN;
  });
};

/**
 * Joins role and role type
 */
export const getDisplayRoles = (roles, roleTypes, roleIds) => {
  const rolesById = byId(roles);
  const roleTypesById = byId(roleTypes);
  return (roleIds || []).map((rId) => {
    const role = rolesById[rId] || {};
    const roleTypeId = role.role_type_id;
    return {
      ...role,
      type: roleTypesById[roleTypeId] || {},
    };
  });
};

export const getAllDisplayRoles = (roles, roleTypes, orgs) => {
  const roleTypesById = byId(roleTypes);
  const orgsById = byId(orgs);
  return roles.map((role) => ({
    ...role,
    type: roleTypesById[role.role_type_id] || {},
    org: orgsById[role.org_id] || {},
  }));
};
