import React from 'react';
import { connect } from 'react-redux';
import { Navigate, useMatch } from 'react-router-dom';
import BeginReset from './BeginReset';
import ChangePwViaReset from './ChangePasswordViaReset';

/**
 * Top level of password reset - decides where we are in the process based on the url params
 */
const PasswordReset = ({ loggedIn }) => {
  const match = useMatch('/password-reset/:email/:token');
  if (loggedIn) {
    return <Navigate to="/explore/buildings" replace />;
  }
  const { email, token } = match?.params ?? {};
  if (email && token) {
    return <ChangePwViaReset email={email} token={token} />;
  }
  return <BeginReset />;
};

export default connect(({ user: { jwt } }) => ({
  loggedIn: jwt !== undefined,
}))(PasswordReset);
