const MIN_IN_MS = 60 * 1000;
const DAY_IN_MS = 24 * 60 * MIN_IN_MS;

export const parseStringToInt = (str) => {
  try {
    const int = parseInt(str, 10);
    // eslint-disable-next-line no-restricted-globals
    return isNaN(int) ? null : int;
  } catch (err) {
    return null;
  }
};

export const parseStringToFloat = (str) => {
  try {
    return parseFloat(str, 10);
  } catch (err) {
    return null;
  }
};

/**
 * Removes decimal places from integers (3, 3.00, '3.0'), leaves everything
 * else alone
 */
export const roundIntsOnly = (num) => {
  const parsed = parseStringToFloat(num);
  return Number.isInteger(parsed) ? parsed : num;
};

export const dateTime = (timestamp, timeZone = 'America/New_York', sep = '-') => {
  if (timestamp == null) {
    return '';
  }
  const d = new Date(timestamp);
  if (timeZone) {
    try {
      return `${d.toLocaleDateString('en-US', { timeZone })} ${sep} ${d.toLocaleTimeString(
        'en-US',
        {
          timeZone,
        }
      )}`;
    } catch (e) {
      // this throws on older IE, not worth tracking down
    }
  }
  return `${d.toLocaleDateString()} ${sep} ${d.toLocaleTimeString()}`;
};

export const date = (timestamp) => dateTime(timestamp).split(' - ')[0];

export const zeroPad = (i) => (i < 10 ? `0${i}` : `${i}`);

export const displayDuration = (duration, inUnit = 'minutes') => {
  let val = duration;
  let unit = inUnit;
  if (val > 1000 && unit === 'millis') {
    val = (val / 1000).toFixed(1);
    unit = 'seconds';
  }
  if (val > 120 && unit === 'seconds') {
    val = (val / 60).toFixed(1);
    unit = 'minutes';
  }
  if (val > 120 && unit === 'minutes') {
    val = (val / 60).toFixed(1);
    unit = 'hours';
  }
  if (val > 48 && unit === 'hours') {
    val = (val / 24).toFixed(1);
    unit = 'days';
  }
  if (val > 14 && unit === 'days') {
    val = (val / 7).toFixed(1);
    unit = 'weeks';
  }
  return { val, unit };
};

export const browserTime = (timestamp) => dateTime(timestamp, null);

export const timestampOrDate = (ts) => (ts instanceof Date ? ts : new Date(ts));

/**
 * show the date only if the timestamp was > 24 hours ago
 */
export const browserShortTime = (timestamp) => {
  const ts = timestampOrDate(timestamp);
  const now = new Date().getTime();
  if (now - ts > DAY_IN_MS) {
    return date(timestamp);
  }
  return browserTime(timestamp);
};

export const graphDateString = (timestamp) =>
  `${timestampOrDate(timestamp).toISOString().split('.')[0]}Z`;

/**
 * Return a short localized timezone name given an IANA timezone city
 * examples:
 * 'America/New_York' -> "EST"
 * 'America/Denver' -> "MST"
 */
export const timezoneAbbreviation = (timeZone) =>
  new Date()
    .toLocaleDateString('en-US', {
      day: '2-digit',
      timeZoneName: 'short',
      timeZone,
    })
    .slice(4);

export const roundDateToHour = (ts) => {
  const jsDate = timestampOrDate(ts);
  jsDate.setMinutes(0, 0, 0); // also sets seconds and millis
  return jsDate;
};

export const roundFloatStr = (value, decimals = 2) => {
  try {
    const floatVal = parseFloat(value);
    if (Number.isNaN(floatVal)) return value;
    return `${floatVal.toFixed(decimals)}`;
  } catch (e) {
    return value;
  }
};

export const percent = (val, decimals = 1) => `${(100.0 * val).toFixed(decimals)}%`;
