import React from 'react';
import { connect } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { MenuItem } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import { logoutUserActionCreator } from '../../../redux/actions/user';
import { RtemRoleLevels } from './roles';

import HeaderMenu from './HeaderMenu';

const SettingsMenu = ({ logout, showSupport }) => {
  const navigate = useNavigate();
  return (
    <HeaderMenu title={<MenuIcon color="secondary" />}>
      <MenuItem onClick={() => navigate('/account')}>Account</MenuItem>
      {showSupport ? <MenuItem onClick={() => navigate('/support')}>Support</MenuItem> : null}
      <MenuItem aria-label="logout" onClick={logout}>
        Logout
      </MenuItem>
    </HeaderMenu>
  );
};

export default connect(
  ({ user: { userAuthLevel } }) => ({
    showSupport:
      userAuthLevel !== RtemRoleLevels.ApiUser && userAuthLevel !== RtemRoleLevels.HackathonUser,
  }),
  (dispatch) => ({
    logout: () => dispatch(logoutUserActionCreator()),
  })
)(SettingsMenu);
