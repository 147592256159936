import React from 'react';
import { connect } from 'react-redux';

import './footer.css';

/**
 * Shows a 'powered by' footer on the login page
 */
const Footer = ({ userLoggedIn }) =>
  userLoggedIn ? null : (
    <div className="App-footer">Powered by Onboard Data Inc © 2018-{new Date().getFullYear()}</div>
  );

export default connect(({ user: { jwt } }) => ({ userLoggedIn: jwt !== undefined }))(Footer);
