import { combineReducers } from 'redux';
import login from './login';
import user from './user';
import buildings from './buildings';
import equipment from './equipment';
import points from './points';
import ui from './ui';
import equipTypes from './equiptypes';
import equipSubTypes from './equipSubTypes';
import pointTypes from './pointtypes';
import tags from './tags';
import series from './timeseries';
import attachments from './attachments';
import users from './users';
import units from './units';
import apiKeys from './apiKeys';
import ingestStats from './ingeststats';
import alerts from './alerts';
import analytics from './analytics';
import actionLog from './actionLog';
import appVariant from './variant';
import rtemFiles from './rtemFiles';
import rtemSubmissions from './rtemSubmissions';
import measurements from './measurements';
import notificationPreferences from './notificationPreferences';
import buildingChangelogs from './buildingChangelogs';
import dataRequests from './dataRequests';
import unitPrefs from './unitPrefs';
import deployments from './deployments';
import staging from './staging';
import tsExports from './exports';
import dataAlerts from './dataAlerts';

const entities = combineReducers({
  buildings,
  equipment,
  equipTypes,
  equipSubTypes,
  points,
  pointTypes,
  tags,
  series,
  attachments,
  units,
  ingestStats,
  alerts,
  measurements,
  notificationPreferences,
  buildingChangelogs,
  dataRequests,
  unitPrefs,
  deployments,
  staging,
  tsExports,
  dataAlerts,
});

// the root reducer- essentially the state tree for the entire app
const rootReducer = combineReducers({
  login,
  user,
  entities,
  ui,
  users,
  apiKeys,
  analytics,
  actionLog,
  rtemFiles,
  rtemSubmissions,
  appVariant,
});

export default rootReducer;
