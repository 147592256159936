import React from 'react';
import { Typography } from '@mui/material';
import { capitalize } from '../../../utils/strings';
import Root from './Root';

const CLASS_TEXTS = {
  points: 'A point type defines a sensor reading on a piece of equipment',
  equipment: 'An equipment type categorizes pieces of equipment with similar properties',
  measurements:
    'A measurement defines possible units and controls standardization for equipment sensor readings',
  location: 'Location defines where a piece of equipment is located',
  relationships: 'Relationships model physical correspondences between two pieces of equipment',
};

const RootClass = ({ type }) => {
  const text = CLASS_TEXTS[type];
  if (text) {
    return (
      <div className="leaf root-class">
        <Typography variant="h5" className="name">
          {capitalize(type)}
        </Typography>
        <p>{text}</p>
      </div>
    );
  }

  return <Root />;
};

export default RootClass;
