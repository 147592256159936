import React from 'react';
import { connect } from 'react-redux';
import { Button, TextField } from '@mui/material';
import { Search, CloudDownload } from '@mui/icons-material';
import {
  queryActionsActionCreator,
  exportActionsActionCreator,
  setQueryActionCreator,
} from '../../redux/actions/actionLog';
import { AUTH_LEVELS } from '../auth/Authorized';
import TargetSelector from './TargetSelector';
import ActionSelector from './ActionSelector';
import OrganizationSelector from '../userMgmt/OrganizationSelector';

/* eslint-disable camelcase, jsx-a11y/label-has-for, jsx-a11y/label-has-associated-control */

const ActionQueryOptions = ({
  query,
  hasResults,
  userAuthLevel,
  onSearch,
  onExport,
  onTargetId,
  onTargetType,
  onActorId,
  onOrgId,
  onLimit,
}) => (
  <div className="query-options">
    <label>
      <span className="name">Action</span>
      <ActionSelector />
    </label>
    <label>
      <span className="name">Target Type</span>
      <TargetSelector selected={query.target_type} onChange={onTargetType} />
    </label>
    <label>
      <span className="name">Target Id</span>
      <TextField
        style={{ width: '80px' }}
        type="number"
        value={query.target_id || ''}
        onChange={onTargetId}
      />
    </label>
    <label>
      <span className="name">Actor</span>
      <TextField
        style={{ width: '80px' }}
        type="number"
        value={query.actor || ''}
        onChange={onActorId}
      />
    </label>
    {userAuthLevel === AUTH_LEVELS.SUPER_ADMIN ? (
      <label>
        <span className="name">Organization</span>
        <OrganizationSelector selected={query.org_id || ''} onChange={onOrgId} allowNulls />
      </label>
    ) : null}
    <label>
      <span className="name">Number of Results</span>
      <TextField
        style={{ width: '60px' }}
        type="number"
        inputProps={{ min: 1 }}
        value={query.limit || ''}
        onChange={onLimit}
      />
    </label>
    <Button startIcon={<Search />} color="secondary" variant="contained" onClick={onSearch}>
      Search
    </Button>
    &nbsp;
    <Button
      startIcon={<CloudDownload />}
      color="primary"
      variant="contained"
      onClick={onExport}
      disabled={!hasResults}
    >
      Export to CSV
    </Button>
  </div>
);

export default connect(
  ({ actionLog: { query, logItems }, user: { userAuthLevel } }) => ({
    query,
    hasResults: !!logItems.length,
    userAuthLevel,
  }),
  (dispatch) => ({
    onSearch: () => dispatch(queryActionsActionCreator()),
    onExport: () => dispatch(exportActionsActionCreator()),
    onTargetId: (e) => dispatch(setQueryActionCreator({ target_id: e.target.value })),
    onTargetType: (e) => dispatch(setQueryActionCreator({ target_type: e.target.value })),
    onActorId: (e) => dispatch(setQueryActionCreator({ actor: e.target.value })),
    onOrgId: (e) => dispatch(setQueryActionCreator({ org_id: e.target.value })),
    onLimit: (e) => dispatch(setQueryActionCreator({ limit: e.target.value })),
  })
)(ActionQueryOptions);
