import React from 'react';
import { connect } from 'react-redux';
import { Grid, Typography } from '@mui/material';
import AttrGrid from './Grid';

const TagsViewer = ({ tagObjects }) => (
  <AttrGrid>
    <Grid item xs={2}>
      <Typography variant="h6">Tags:</Typography>
    </Grid>
    <Grid item xs={6}>
      <Typography variant="h6">Definition:</Typography>
    </Grid>
    <Grid item xs={4}>
      <Typography variant="h6">External reference:</Typography>
    </Grid>
    {Array.from(new Set(tagObjects)).map(({ id, name, definition, defSource, defUrl }) => (
      <React.Fragment key={id}>
        <Grid item xs={2}>
          {name}
        </Grid>
        <Grid item xs={6}>
          {definition}
        </Grid>
        <Grid item xs={4}>
          {defSource === 'haystack' && !defUrl && (
            <a
              href={`https://project-haystack.org/tag/${name}`}
              target="_blank"
              rel="noopener noreferrer"
            >{`https://project-haystack.org/tag/${name}`}</a>
          )}
          {defUrl && (
            <a href={defUrl} target="_blank" rel="noopener noreferrer">
              {defUrl}
            </a>
          )}
        </Grid>
      </React.Fragment>
    ))}
  </AttrGrid>
);

export default connect(
  (
    {
      entities: {
        tags: { byName },
      },
    },
    { tags }
  ) => ({
    tagObjects: tags.map((n) => byName[n]).filter((t) => t != null),
  })
)(TagsViewer);
