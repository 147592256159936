import { SET_RTEM_NOTIFICATIONS } from '../actions/actiontypes';

export default function notificationPreferences(state = {}, action) {
  switch (action.type) {
    case SET_RTEM_NOTIFICATIONS:
      return {
        ...action.data,
      };
    default:
      return state;
  }
}
