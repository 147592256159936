import React from 'react';
import { connect } from 'react-redux';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import LoginContainer from '../../login/LoginContainer';
import LoginPersistence from '../../login/LoginPersistence';
import FeaturesLoader from '../../loaders/FeaturesLoader';
import PasswordReset from '../../login/PasswordReset';
import Authorized, { AUTH_LEVELS } from '../../auth/Authorized';
import AppHeader from './AppHeader';
import Account from '../../account/Account';
import Support from '../../nyserda/Support';
import Files from '../../nyserda/Files';
import ActionLogAudit from '../../actionLog/ActionLogAudit';
import Footer from '../../nyserda/Footer';
import Submissions from '../../nyserda/submissions/Submissions';
import VendorEdit from '../../userMgmt/nyserda/NyserdaVendorEdit';
import SiteCreator from '../../nyserda/siteCreator/SiteCreator';
import ApiAndDocs from '../../docs/ApiAndDocs';

import { RtemRoleLevels } from './roles';

import './App.css';

const LoginComponent = () => <LoginContainer successRedirect="/explore/files" />;
const { ALL_USER, ORG_ADMIN, SUPER_ADMIN } = AUTH_LEVELS;

class App extends React.PureComponent {
  scrollTop = () => {
    const { scrollNode } = this;
    if (scrollNode) scrollNode.scrollTop = 0;
  };

  render() {
    const { scrollTop } = this;
    const { onlyShowApiDocs } = this.props;
    const Auth = Authorized(LoginComponent);
    return (
      <Router>
        <div className="App">
          <div className="row App-Header">
            <AppHeader />
          </div>
          <div
            className="grid-container-lg App-body"
            ref={(d) => {
              this.scrollNode = d;
            }}
          >
            <Routes>
              <Route path="/login" element={<LoginComponent />} />
              <Route path="/password-reset" element={<PasswordReset />} />
              <Route path="/password-reset/:email/:token" element={<PasswordReset />} />
              <Route path="/account" element={<Auth child={Account} level={ALL_USER} />} />
              <Route path="/docs" element={<Auth child={ApiAndDocs} level={ALL_USER} />} />
              <Route path="/docs/:tab/*" element={<Auth child={ApiAndDocs} level={ALL_USER} />} />
              {/* block hackathon user access to rest of pages with a catch-all route */}
              {onlyShowApiDocs ? (
                <Route path="*" element={<Auth child={ApiAndDocs} level={ALL_USER} />} />
              ) : (
                <Route
                  path="*"
                  element={<Auth child={Files} level={ALL_USER} componentProps={{ scrollTop }} />}
                />
              )}
              <Route path="/submissions" element={<Auth child={Submissions} level={ALL_USER} />} />
              <Route path="/support" element={<Support />} />
              <Route
                path="/actions-audit"
                element={<Auth child={ActionLogAudit} level={ORG_ADMIN} />}
              />
              <Route
                path="/vendors"
                element={
                  <Auth child={VendorEdit} level={SUPER_ADMIN} componentProps={{ scrollTop }} />
                }
              />
              <Route
                path="/site-creator"
                element={<Auth child={SiteCreator} level={SUPER_ADMIN} />}
              />
              <Route
                path="/explore/files"
                element={<Auth child={Files} level={ALL_USER} componentProps={{ scrollTop }} />}
              />
            </Routes>
          </div>
          <LoginPersistence />
          <FeaturesLoader />
        </div>
        <Footer />
      </Router>
    );
  }
}

export default connect(({ user: { userAuthLevel } }) => ({
  onlyShowApiDocs:
    userAuthLevel === RtemRoleLevels.ApiUser || userAuthLevel === RtemRoleLevels.HackathonUser,
}))(App);
