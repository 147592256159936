import { combineReducers } from 'redux';
import { SET_CHANGELOG, SET_BULK_CHANGELOGS } from '../actions/actiontypes';

const loaded = (state = new Set(), action) => {
  switch (action.type) {
    case SET_CHANGELOG: {
      const newState = new Set(state);
      newState.add(action.buildingId);
      return newState;
    }
    case SET_BULK_CHANGELOGS:
      return new Set(action.buildingIds);
    default:
      return state;
  }
};

const logs = (state = {}, action) => {
  switch (action.type) {
    case SET_CHANGELOG:
      return {
        [action.buildingId]: action.data,
        ...state,
      };
    case SET_BULK_CHANGELOGS: {
      const newState = {};
      action.data.forEach((log) => {
        if (!newState[log.target_id]) {
          newState[log.target_id] = [log];
        } else {
          newState[log.target_id].push(log);
        }
      });
      return newState;
    }
    default:
      return state;
  }
};

const buildingChangelogs = combineReducers({
  loaded,
  logs,
});

export default buildingChangelogs;
