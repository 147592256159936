import React from 'react';
import { Tooltip } from '@mui/material';
import { sentenceCase } from '../../../utils/strings';
import './submission-status.css';

const TOOLTIP = {
  SUBMITTED: 'This submission has been received and is awaiting approval by an RTEM administrator',
  APPROVED: 'An RTEM administrator has approved this data submission',
  REJECTED: 'An RTEM administrator has indicated that there is a problem with this data submission',
  DELETED: 'One or more files associated with this submission have been deleted or moved',
};

const SubmissionStatus = ({ status }) => (
  <Tooltip title={TOOLTIP[status]}>
    <span className={`submission-text ${status}`}>
      {status === 'DELETED' ? 'Files Deleted' : sentenceCase(status)}
    </span>
  </Tooltip>
);

export default SubmissionStatus;
