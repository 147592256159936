import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { Grid, Typography } from '@mui/material';
import { noSpaces } from '../../models';
import AttrGrid from './Grid';

const MeasurementViewer = ({ measurement, base }) =>
  measurement ? (
    <AttrGrid>
      <Grid item xs={5}>
        <Typography variant="h6">Measurement:</Typography>
      </Grid>

      <Grid item xs={5}>
        <Link to={`${base}/measurements/${encodeURIComponent(noSpaces(measurement.name))}`}>
          {measurement.name}
        </Link>
      </Grid>
    </AttrGrid>
  ) : null;

export default connect(
  (
    {
      entities: {
        measurements: { byId },
      },
    },
    { measurementId }
  ) => ({
    measurement: byId[measurementId],
  })
)(MeasurementViewer);
