import fetch from 'cross-fetch';
import getApiBaseUrl from './urls';
import { clearLoginCookie } from './loginCookie';

const isAuthError = (json) => {
  try {
    if (json.errors[0].name === 'missing auth token') return true;
  } catch (err) {
    // empty ok
  }
  return false;
};

const handleError = (json) => {
  if (isAuthError(json)) {
    clearLoginCookie();
    window.location.reload();
  } else {
    let message = json?.detail;
    if (!message) {
      const pydanticError = json?.errors?.[0];
      message = pydanticError && JSON.stringify(pydanticError);
    }
    if (!message) message = 'Error with api call';
    throw new Error(message);
  }
};

export const JSON_REQ = {
  'Content-Type': 'application/json',
  Accept: 'application/json',
};

/**
 * Sends caught exceptions up to the API so we can fix them
 */
export const logError =
  process.env.NODE_ENV !== 'production'
    ? () => {}
    : (details) =>
        fetch(`${getApiBaseUrl()}/ui-errors`, {
          method: 'PUT',
          headers: JSON_REQ,
          body: JSON.stringify(details),
        })
          // eslint-disable-next-line no-console
          .catch((err) => console.error(`Another error reporting JS error: ${err}`));

export const jsonAuth = (jwt, contentType = 'application/json') => {
  const headers = {
    Accept: 'application/json',
  };
  if (jwt) headers.Authorization = `Bearer ${jwt}`;
  if (contentType) headers['Content-Type'] = contentType;
  return headers;
};

// Need to let fetch set the content-type etc for us for these kinds of requests
export const uploadAuth = (jwt) => jsonAuth(jwt, null);

const handleResponse = (responseFuture) =>
  responseFuture
    .then((response) => Promise.all([response.ok, response.json()]))
    .then(([responseOk, responseBody]) => {
      if (responseOk) {
        return responseBody;
      }
      return handleError(responseBody);
    });

const simpleGet = (url, jwt, method = 'GET', body = undefined) =>
  handleResponse(
    fetch(url.indexOf('http') !== 0 ? `${getApiBaseUrl()}${url}` : url, {
      method,
      credentials: 'include',
      headers: jsonAuth(jwt),
      body: body ? JSON.stringify(body) : undefined,
    })
  );

export function getAllEquipmentForBuilding(buildingID, jwt, points) {
  const url = `${getApiBaseUrl()}/buildings/${buildingID}/equipment?points=${points}`;
  const request = fetch(url, {
    headers: jsonAuth(jwt),
  }).then((response) => {
    if (response.ok) {
      return response.json();
    }
    return response.json().then((json) => handleError(json));
  });
  return request;
}

export const deleteBuilding = (buildingId, jwt) =>
  simpleGet(`${getApiBaseUrl()}/buildings/${buildingId}`, jwt, 'DELETE');

export const patchBuilding = (buildingId, patch, jwt) =>
  handleResponse(
    fetch(`${getApiBaseUrl()}/buildings/${buildingId}`, {
      method: 'PATCH',
      body: JSON.stringify(patch),
      headers: jsonAuth(jwt),
    })
  );

export function getAll(jwt, resourceName) {
  const url = `${getApiBaseUrl()}/${resourceName}`;
  const request = fetch(url, {
    headers: jsonAuth(jwt),
  }).then((response) => {
    if (response.ok) {
      return response.json();
    }
    return response.json().then((json) => handleError(json));
  });
  return request;
}

const genericFileUploader = (url) => (file, jwt) => {
  const body = new FormData();
  body.append('file', file);
  return fetch(url, {
    method: 'POST',
    body,
    headers: uploadAuth(jwt),
  })
    .then((response) => Promise.all([response.ok, response.json()]))
    .then(([responseOk, responseBody]) => {
      if (responseOk) {
        return responseBody;
      }
      return handleError(responseBody);
    });
};

export const uploadTagDefinitions = (allowNew) =>
  genericFileUploader(`${getApiBaseUrl()}/tags/definitions?allow_new=${allowNew}`);

export const uploadTagPointTypeAssociations = genericFileUploader(
  `${getApiBaseUrl()}/tags/point-associations`
);

export const uploadTagEquipmentTypeAssociations = genericFileUploader(
  `${getApiBaseUrl()}/tags/equipment-type-associations`
);

export const uploadStagingCsv = (buildingId) =>
  genericFileUploader(`${getApiBaseUrl()}/staging/${buildingId}`);

export const uploadBuildingImage = (buildingId, image, jwt) => {
  const body = new FormData();
  body.append('file', image);
  body.append('filename', image.name);
  return handleResponse(
    fetch(`${getApiBaseUrl()}/buildings/${buildingId}/image`, {
      method: 'POST',
      body,
      headers: uploadAuth(jwt),
    })
  );
};

export function submitLogin(email, pw, remember) {
  const url = `${getApiBaseUrl()}/login`;
  const loginData = {
    login: email.trim(),
    password: pw,
    remember,
  };
  return fetch(url, {
    method: 'POST',
    credentials: 'include',
    headers: JSON_REQ,
    body: JSON.stringify(loginData),
  }).then((response) => response.json());
}

export const submitTotp = (password, partialToken) =>
  fetch(`${getApiBaseUrl()}/login/totp`, {
    method: 'POST',
    credentials: 'include',
    headers: jsonAuth(partialToken),
    body: JSON.stringify({
      otp: password,
    }),
  }).then((response) => response.json());

export const startFido2Login = (jwt) => simpleGet(`${getApiBaseUrl()}/login/fido2`, jwt, 'POST');

export const completeFido2Login = (formData, jwt) =>
  handleResponse(
    fetch(`${getApiBaseUrl()}/login/fido2`, {
      method: 'PUT',
      credentials: 'include',
      headers: jsonAuth(jwt),
      body: JSON.stringify(formData),
    })
  );

export function uploadAttachment(buildingId, file, jwt) {
  const body = new FormData();
  body.append('file', file);
  body.append('filename', file.name);
  const url = `${getApiBaseUrl()}/buildings/${buildingId}/attachments`;
  const request = fetch(url, {
    method: 'POST',
    body,
    headers: uploadAuth(jwt),
  })
    .then((response) => Promise.all([response.ok, response.json()]))
    .then(([responseOk, responseBody]) => {
      if (responseOk) {
        return responseBody;
      }
      return handleError(responseBody);
    });
  return request;
}

export const fetchAttachments = (buildingId, jwt) =>
  simpleGet(`${getApiBaseUrl()}/buildings/${buildingId}/attachments`, jwt);

export function restoreLogin(token) {
  const url = `${getApiBaseUrl()}/whoami`;
  return fetch(url, {
    method: 'GET',
    headers: {
      Accept: 'application/json',
      ...jsonAuth(token),
    },
  }).then((response) => {
    if (response.ok) {
      return response.json();
    }
    return response.json().then(handleError);
  });
}

export function logout(token) {
  return fetch(`${getApiBaseUrl()}/logout`, {
    method: 'POST',
    headers: jsonAuth(token),
  }).then((response) => {
    if (response.ok) {
      return response.json();
    }
    return response.json().then(handleError);
  });
}

export const queryTimeSeriesV2 = (jwt, req, resampleMins = 5) => {
  let url = '/query-v2';
  if (resampleMins != null && resampleMins > 1) {
    url += `?resample_mins=${resampleMins}`;
  }
  return simpleGet(url, jwt, 'POST', req);
};

export const getUsers = (jwt) => simpleGet('/users', jwt);

export const getRoles = (jwt) => simpleGet('/roles', jwt);

export const getRoleTypes = (jwt) => simpleGet('/roles/types', jwt);

export const getRolePermissions = (jwt, roleId) => simpleGet(`/roles/${roleId}/permissions`, jwt);

export const updateRolePermissions = (jwt, roleId, changes) =>
  simpleGet(`/roles/${roleId}/permissions`, jwt, 'POST', changes);

export const getOrganizations = (jwt) => simpleGet('/organizations', jwt);

export const getUnits = (jwt) => simpleGet('/unit', jwt);

export const getScopes = (jwt) => simpleGet('/scopes', jwt);

export const getBuildingIngestStats = (jwt, interval = '24h') =>
  simpleGet(`/ingest-stats?age=${interval}`, jwt);

export const getPointSampleRates = (jwt, buildingId, interval = '24h') =>
  simpleGet(`/ingest-stats/${buildingId}/sampling-rate?interval=${interval}`, jwt);

export const getApiKeys = (jwt) => simpleGet('/api-keys', jwt);

export const createApiKey = (name, scopes, jwt) => {
  const params = new URLSearchParams({ name });
  scopes.forEach((s) => params.append('scopes', s));
  const url = `${getApiBaseUrl()}/api-keys?${params.toString()}`;
  return fetch(url, {
    method: 'POST',
    headers: jsonAuth(jwt),
    body: '',
  })
    .then((response) => Promise.all([response.ok, response.json()]))
    .then(([responseOk, responseBody]) => {
      if (responseOk) {
        return responseBody;
      }
      return handleError(responseBody);
    });
};

// eslint-disable-next-line camelcase
export const editApiKey = (hash, name, scopes, active, api_version, jwt) => {
  const params = new URLSearchParams({ name, active, api_version });
  scopes.forEach((s) => params.append('scopes', s));
  const url = `${getApiBaseUrl()}/api-keys/${hash}?${params.toString()}`;
  return fetch(url, {
    method: 'PATCH',
    headers: jsonAuth(jwt),
  })
    .then((response) => Promise.all([response.ok, response.json()]))
    .then(([responseOk, responseBody]) => {
      if (responseOk) {
        return responseBody;
      }
      return handleError(responseBody);
    });
};

export const deleteApiKey = (hash, jwt) =>
  fetch(`${getApiBaseUrl()}/api-keys/${hash}`, {
    method: 'DELETE',
    headers: jsonAuth(jwt),
  })
    .then((response) => Promise.all([response.ok, response.json()]))
    .then(([responseOk, responseBody]) => {
      if (responseOk) {
        return responseBody;
      }
      return handleError(responseBody);
    });

// Create and edit user are handled the same server-side except for the url you post to
const userCreateEdit = (url) => (data, jwt) =>
  fetch(url, {
    method: 'POST',
    headers: jsonAuth(jwt),
    body: JSON.stringify(data),
  })
    .then((response) => Promise.all([response.ok, response.json()]))
    .then(([ok, body]) => {
      if (ok) return body;
      // eslint-disable-next-line prefer-promise-reject-errors
      return Promise.reject({ message: JSON.stringify(body.errors || body.detail) });
    })
    // eslint-disable-next-line prefer-promise-reject-errors
    .catch((e) => Promise.reject(`API error: ${e.message}`));

export const createNewUser = userCreateEdit(`${getApiBaseUrl()}/users`);

export const editUser = (data, id, jwt) =>
  userCreateEdit(`${getApiBaseUrl()}/users/${id}`)(data, jwt);

export const createNewRole = (data, jwt) => {
  // eslint-disable-next-line camelcase
  const { role_type_id, org_id, name, description } = data;
  return handleResponse(
    fetch(`${getApiBaseUrl()}/roles`, {
      method: 'POST',
      body: JSON.stringify({ role_type_id, org_id, name, description }),
      headers: jsonAuth(jwt),
    })
  );
};

export const editRole = (id, data, jwt) => {
  const { role_type_id, org_id, name, description } = data;
  return simpleGet(`/roles/${id}`, jwt, 'PATCH', { role_type_id, org_id, name, description });
};

const orgHelper = (data, jwt, method, url) => {
  // eslint-disable-next-line camelcase
  const {
    name,
    short_name,
    name_abbr,
    primary_color,
    secondary_color,
    logo_url,
    mfa_required,
    ingest_alerts,
  } = data;
  return handleResponse(
    fetch(`${getApiBaseUrl()}${url}`, {
      method,
      body: JSON.stringify({
        name,
        short_name,
        name_abbr,
        primary_color,
        secondary_color,
        logo_url,
        mfa_required,
        ingest_alerts,
      }),
      headers: jsonAuth(jwt),
    })
  );
};

export const createNewOrg = (data, jwt) => orgHelper(data, jwt, 'POST', '/organizations');

export const editOrg = (id, data, jwt) => orgHelper(data, jwt, 'PATCH', `/organizations/${id}`);

export const beginPasswordReset = (email, type = 'SELF', variant = 'portal') =>
  handleResponse(
    fetch(`${getApiBaseUrl()}/password-reset/${email.trim()}?type=${type}&variant=${variant}`, {
      method: 'POST',
      headers: JSON_REQ,
    })
  );

export const checkPasswordReset = (email, token) =>
  simpleGet(`${getApiBaseUrl()}/password-reset/${email.trim()}/${token}`, '');

export const updatePasswordViaReset = (email, token, newPassword) =>
  handleResponse(
    fetch(`${getApiBaseUrl()}/password-reset/${email.trim()}/${token}`, {
      method: 'PUT',
      headers: JSON_REQ,
      body: JSON.stringify({ password: newPassword }),
    })
  );

export const getAlerts = (jwt) => simpleGet(`${getApiBaseUrl()}/alerts`, jwt);

export const muteAlert = (key, timestamp, mute, jwt) =>
  handleResponse(
    fetch(`${getApiBaseUrl()}/alerts`, {
      method: 'POST',
      headers: jsonAuth(jwt),
      body: JSON.stringify({ key, timestamp, mute }),
    })
  );

export const fetchTotp = (jwt) => simpleGet('/mfa/totp', jwt);

export const createTotp = (name, jwt) => simpleGet(`/mfa/totp/${name}`, jwt, 'POST');

export const deleteTotp = (name, jwt) => simpleGet(`/mfa/totp/${name}`, jwt, 'DELETE');

export const confirmTotp = (name, password, jwt) =>
  handleResponse(
    fetch(`${getApiBaseUrl()}/mfa/totp/${name}`, {
      method: 'PUT',
      headers: jsonAuth(jwt),
      body: JSON.stringify({ otp: password }),
    })
  );

export const fetchFido2 = (jwt) => simpleGet('/mfa/fido2', jwt);

export const deleteFido2 = (key, jwt) => simpleGet(`/mfa/fido2/${key}`, jwt, 'DELETE');

export const startFido2Setup = (jwt) => simpleGet('/mfa/fido2', jwt, 'POST');

export const completeFido2Setup = (formData, jwt) =>
  handleResponse(
    fetch(`${getApiBaseUrl()}/mfa/fido2`, {
      method: 'PUT',
      credentials: 'include',
      headers: jsonAuth(jwt),
      body: JSON.stringify(formData),
    })
  );

export const deleteUserFido2 = (userId, key, jwt) =>
  simpleGet(`/mfa/fido2/${userId}/${key}`, jwt, 'DELETE');

export const deleteUserTotp = (userId, name, jwt) =>
  simpleGet(`/mfa/totp/${userId}/${name}`, jwt, 'DELETE');

export const fetchUserActions = (jwt) => simpleGet('/account-actions', jwt);

export const fetchActionTypes = (jwt) => simpleGet('/account-actions/types', jwt);

export const queryActions = (query, jwt) => simpleGet('/account-actions/query', jwt, 'POST', query);

export const exportActions = (query, jwt) =>
  fetch(`${getApiBaseUrl()}/account-actions/query`, {
    method: 'POST',
    headers: {
      ...jsonAuth(jwt),
      accept: 'text/csv',
    },
    body: JSON.stringify(query),
  })
    .then((response) => Promise.all([response.ok, response.blob()]))
    .then(([responseOk, responseBlob]) => {
      if (responseOk) {
        return responseBlob;
      }
      return handleError(responseBlob);
    });

export const getSshKeys = (jwt) => simpleGet('/ssh-keys', jwt);

export const createSshKey = (name, pubkey, jwt) =>
  handleResponse(
    fetch(`${getApiBaseUrl()}/ssh-keys`, {
      method: 'POST',
      headers: jsonAuth(jwt),
      body: JSON.stringify({ name, pubkey }),
    })
  );

export const deleteSshKey = (name, jwt) => simpleGet(`/ssh-keys/${name}`, jwt, 'DELETE');

export const fetchHomeDirectory = (jwt) => simpleGet('/rtem/files', jwt);

export const fetchSubmissionStatus = (jwt) => simpleGet('/rtem/files/submissions', jwt);

export const sendRtemFilesCommand = (command, jwt) =>
  handleResponse(
    fetch(`${getApiBaseUrl()}/rtem/files`, {
      method: 'POST',
      headers: jsonAuth(jwt),
      body: JSON.stringify(command),
    })
  );

export const submitDataNotification = (path, jwt) =>
  handleResponse(
    fetch(`${getApiBaseUrl()}/rtem/files/submit`, {
      method: 'POST',
      headers: jsonAuth(jwt),
      body: JSON.stringify({ path }),
    })
  );

export const approveDenyDataSubmission = (path, status, jwt) =>
  handleResponse(
    fetch(`${getApiBaseUrl()}/rtem/files/submit`, {
      method: 'PUT',
      headers: jsonAuth(jwt),
      body: JSON.stringify({ path, status }),
    })
  );

export const uploadPresignedRequest = (url, fields, file) => {
  const formData = new FormData();
  Object.entries(fields).forEach(([key, val]) => formData.append(key, val));
  formData.append('file', file);
  return fetch(url, {
    method: 'POST',
    body: formData,
  })
    .then((response) => Promise.all([response.ok, response.blob()]))
    .then(([ok, responseBody]) => (ok ? responseBody : handleError(responseBody)));
};

export const getMeasurements = (jwt) => simpleGet('/measurements', jwt);

export const getNotificationPrefs = (jwt) => simpleGet('/rtem/subscriptions', jwt);

export const changeNotificationPref = (subReq, jwt) =>
  simpleGet('/rtem/subscriptions', jwt, 'POST', subReq);

export const unsubscribeAllNotifications = (jwt) =>
  simpleGet('/rtem/subscriptions/all', jwt, 'DELETE');

export const resubscribeAllNotifications = (jwt) =>
  simpleGet('/rtem/subscriptions/all', jwt, 'POST');

export const getBuildingChangelog = (buildingId, jwt) =>
  simpleGet(`/buildings/${buildingId}/changelog`, jwt);

export const getBuildingChangelogs = (buildingIds, jwt) => {
  const params = new URLSearchParams();
  buildingIds.forEach((id) => params.append('bid', id));
  return simpleGet(`/buildings/changelogs?${params.toString()}`, jwt);
};

export const getDataRequests = (jwt, selection) =>
  simpleGet(`/data-requests?selection=${selection}`, jwt);

export const createDataRequest = (req, jwt) => simpleGet('/data-requests', jwt, 'POST', req);

export const saveDataRequest = (requestId, jwt, req) =>
  simpleGet(`/data-requests/${requestId}`, jwt, 'PATCH', req);

export const deleteDataRequest = (requestId, jwt) =>
  simpleGet(`/data-requests/${requestId}`, jwt, 'DELETE');

export const addDataRequestAttachment = (file, requestId, label, jwt) => {
  const body = new FormData();
  body.append('file', file);
  body.append('filename', file.name);
  const url = `${getApiBaseUrl()}/data-requests/${requestId}/attachments?label=${label}`;
  return fetch(url, {
    method: 'POST',
    body,
    headers: uploadAuth(jwt),
  })
    .then((response) => Promise.all([response.ok, response.json()]))
    .then(([responseOk, responseBody]) => {
      if (responseOk) {
        return responseBody;
      }
      return handleError(responseBody);
    });
};

export const notifyOfDataRequest = (requestId, jwt) =>
  simpleGet(`/data-requests/${requestId}/notify`, jwt, 'POST');

export const getUnitPreferences = (jwt) => simpleGet('/unit-preferences', jwt);

export const setUnitPreference = (req, jwt) => simpleGet('/unit-preferences', jwt, 'POST', req);

export const deleteUnitPreference = (req, jwt) =>
  simpleGet('/unit-preferences', jwt, 'DELETE', req);

export const getDeployments = (jwt) => simpleGet('/deployment', jwt);

export const upsertDeployment = (jwt, config) => simpleGet('/deployment', jwt, 'POST', config);

export const getDeploymentConfig = (jwt, site) =>
  simpleGet(`/deployment/config?site=${encodeURIComponent(site)}`, jwt);

export const upsertDeploymentConfig = (jwt, site, config) =>
  simpleGet(`/deployment/config?site=${encodeURIComponent(site)}`, jwt, 'POST', config);

export const associateBuildings = (jwt, site, buildings) =>
  simpleGet('/deployment/buildings', jwt, 'POST', { site, buildings });

export const getAllHeartbeats = (jwt, age) => simpleGet(`/deployment/heartbeat?age=${age}`, jwt);

export const getSiteHeartbeats = (jwt, site, age) =>
  simpleGet(`/deployment/heartbeat?${encodeURIComponent(site)}&age=${age}`, jwt);

export const getBuildingStaging = (jwt, buildingId) => simpleGet(`/staging/${buildingId}`, jwt);

export const getEquipmentStaging = (jwt, buildingId, equipId) =>
  simpleGet(`/staging/${buildingId}/equip/${equipId}`, jwt);

export const getBuildingStagingCsv = (jwt, buildingId, params) => {
  const { cardinality, excludeFields } = params;
  const queryParams = new URLSearchParams({ cardinality });
  excludeFields.forEach((f) => queryParams.append('ef', f));
  return fetch(`${getApiBaseUrl()}/staging/${buildingId}?${queryParams.toString()}`, {
    headers: { Authorization: jsonAuth(jwt).Authorization, Accept: 'text/csv' },
  })
    .then((response) => Promise.all([response.ok, response.blob()]))
    .then(([responseOk, responseBlob]) => {
      if (responseOk) {
        return responseBlob;
      }
      return handleError(responseBlob);
    });
};

export const postStagingJson = (jwt, buildingId, data) =>
  simpleGet(`/staging/${buildingId}`, jwt, 'POST', data);

export const promoteStagingEquipment = (jwt, buildingId, equipIds, topics, allowedDeletes) =>
  simpleGet(`/staging/${buildingId}/apply`, jwt, 'POST', {
    equip_ids: equipIds,
    topics,
    allowed_topic_deletes: allowedDeletes,
  });

export const validateStaging = (jwt, buildingId) =>
  simpleGet(`/staging/${buildingId}/validate`, jwt);

export const getStagingFieldGroups = (jwt, buildingId) =>
  simpleGet(`/staging/${buildingId}/fields`, jwt);

export const createEmptyBuilding = (jwt, buildingName, orgId) =>
  simpleGet(`/buildings`, jwt, 'POST', { name: buildingName, org_id: orgId });

export const getApiVersions = (jwt) => simpleGet('/api-versions', jwt);

export const getExports = (jwt) => simpleGet('/timeseries/exports', jwt);

/**
 * Export create and edit both take a TimeseriesQuery in the body, any extra values
 * are sent as query parameters
 */
export const createExport = (jwt, exportData) => {
  const { name, description, sampling_mins, start, end, selector, point_ids, units, aggregations } =
    exportData;
  const params = new URLSearchParams({ name, sampling_mins, desc: description });
  const url = `/timeseries/exports?${params.toString()}`;
  return simpleGet(url, jwt, 'POST', { start, end, selector, point_ids, units, aggregations });
};

export const patchExport = (jwt, exportId, exportData) => {
  const { name, description, sampling_mins, start, end, selector, point_ids, units, aggregations } =
    exportData;
  const params = new URLSearchParams({ name, sampling_mins, desc: description });
  const url = `/timeseries/exports/${exportId}?${params.toString()}`;
  return simpleGet(url, jwt, 'PATCH', { start, end, selector, point_ids, units, aggregations });
};

export const deleteExport = (jwt, exportId) =>
  simpleGet(`/timeseries/exports/${exportId}`, jwt, 'DELETE');

export const refreshExportLinks = (jwt, exportId) =>
  simpleGet(`/timeseries/exports/${exportId}`, jwt, 'POST');

export const getExportDownloadLink = (token, format = 'csv') =>
  `${getApiBaseUrl()}/timeseries/export/${token}?format=${format}`;

export const selectPoints = (jwt, selector) => simpleGet('/points/select', jwt, 'POST', selector);

export const fetchDataAlerts = (jwt) => simpleGet('/data-alerts', jwt);

export const createDataAlert = (jwt, alert) => simpleGet('/data-alerts', jwt, 'POST', alert);

export const updateDataAlert = (jwt, alertId, alert) =>
  simpleGet(`/data-alerts/${alertId}`, jwt, 'PUT', alert);

export const removeAlert = (jwt, alertId) => simpleGet(`/data-alerts/${alertId}`, jwt, 'DELETE');

export const createDataAlertAction = (jwt, alertId, action) =>
  simpleGet(`/data-alerts/${alertId}/actions`, jwt, 'POST', action);

export const updateDataAlertAction = (jwt, alertId, actionId, action) =>
  simpleGet(`/data-alerts/${alertId}/actions/${actionId}`, jwt, 'PUT', action);

export const removeDataAlertAction = (jwt, alertId, actionId) =>
  simpleGet(`/data-alerts/${alertId}/actions/${actionId}`, jwt, 'DELETE');
