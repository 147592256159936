import React from 'react';
import FilePicker from '../../upload/FilePicker';

const UploadTarget = ({ filename, setFile }) => (
  <div>
    <FilePicker
      buttonText="Ingest a Sites CSV"
      selectedFileName={filename || ''}
      onFileSelection={(e) => {
        e.preventDefault();
        if (e.target.files.length !== 1) return;
        setFile(e.target.files[0]);
      }}
    />
  </div>
);

export default UploadTarget;
