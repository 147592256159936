import React, { useState } from 'react';
import { connect } from 'react-redux';
import { LinearProgress, IconButton, TableBody, TableCell, TableRow } from '@mui/material';
import { Add, Delete } from '@mui/icons-material';
import {
  setUnitPreferenceAction,
  deleteUnitPreferenceAction,
} from '../../../redux/actions/unitPrefs';

import UnitSelector from '../../ingestQa/UnitSelector';
import MeasurementSelector from './MeasurementSelector';

import './unit-prefs.css';

/* eslint-disable camelcase */

// we can only set preferences for measurements that are continuous + convertible
// also make sure to exclude dimensionless measurements like CO2 (ppm) and elevator floor (Floor)
export const measurementFilter =
  (units) =>
  ({ units_convertible, default_unit_id }) => {
    const unit = units[default_unit_id];
    const dataType = unit?.data_type;
    const unitKind = unit?.unit_type || '';
    return (
      units_convertible && dataType === 'Continuous' && unitKind.indexOf('Dimensionless') === -1
    );
  };

/**
 * A table of unit preferences for convertible measurements. We can convert between any units that share the
 * same 'unit_type' field (from QUDT). This table is used to show org or user-level preferences.
 */
const UnitPrefsTable = ({
  preferences,
  measurements,
  units,
  headerText = '',
  editable,
  setPreference: setPrefRedux,
  deletePreference: deletePrefRedux,
  parentPreferences = [],
  displayInactive,
  callbacks,
}) => {
  const [newUnit, setUnit] = useState();
  const [newMeasurement, setMeasurement] = useState();
  const [loading, setLoading] = useState(false);
  // only show unit options which are convertible from the default unit
  const measurementUnitFilter = (measurementId) => {
    const defaultUnit = measurements[measurementId]?.default_unit_id;
    const unitType = units[defaultUnit]?.unit_type;
    return (unit) => !unitType || unit.unit_type === unitType;
  };
  const parPrefIds = new Set(parentPreferences.map((pref) => pref.measurement_id));
  // callbacks prop takes preference over the mapped dispatch functions
  const setPreference = callbacks?.setPreference ?? setPrefRedux;
  const deletePreference = callbacks?.deletePreference ?? deletePrefRedux;
  return (
    <TableBody>
      {headerText ? (
        <TableRow>
          <th colSpan={4} style={{ textAlign: 'center', height: '80px' }}>
            {headerText}
          </th>
        </TableRow>
      ) : null}
      <TableRow className="prefs-header">
        <TableCell aria-label="Add" />
        <TableCell>Measurement Type</TableCell>
        <TableCell>Default Unit</TableCell>
        <TableCell>Preferred Unit</TableCell>
      </TableRow>
      {!preferences.length && !editable && (
        <TableRow>
          <th colSpan={4} style={{ textAlign: 'center', height: '80px' }}>
            None configured yet
          </th>
        </TableRow>
      )}
      {editable && (
        <TableRow>
          <TableCell>
            {loading === 'NEW' && <LinearProgress color="secondary" />}
            <IconButton
              color="secondary"
              disabled={loading || !newMeasurement || !newUnit}
              onClick={() => {
                setLoading('NEW');
                setPreference(newMeasurement, newUnit)
                  .then(() => {
                    setMeasurement(null);
                    setUnit(null);
                  })
                  .finally(() => setLoading(false));
              }}
              size="large"
            >
              <Add />
            </IconButton>
          </TableCell>
          <TableCell>
            <MeasurementSelector
              selected={newMeasurement}
              filter={measurementFilter(units)}
              onChange={(measId) => {
                setMeasurement(measId);
                setUnit(null);
              }}
            />
          </TableCell>
          <TableCell>
            {newMeasurement && units[measurements[newMeasurement].default_unit_id]?.name_long}
          </TableCell>
          <TableCell>
            {newMeasurement && (
              <UnitSelector
                selected={newUnit}
                filter={measurementUnitFilter(newMeasurement)}
                onChange={setUnit}
              />
            )}
          </TableCell>
        </TableRow>
      )}
      {preferences
        .filter(({ measurement_id }) => !parPrefIds.has(measurement_id) || displayInactive)
        .map(({ measurement_id, unit_id }) => (
          <TableRow
            key={measurement_id}
            className={parPrefIds.has(measurement_id) ? 'overridden' : ''}
          >
            <TableCell>
              {editable && (
                <IconButton
                  disabled={loading}
                  onClick={() => {
                    setLoading(measurement_id);
                    deletePreference(measurement_id).finally(() => setLoading(false));
                  }}
                  size="large"
                >
                  <Delete />
                </IconButton>
              )}
            </TableCell>
            <TableCell>{measurements[measurement_id]?.name}</TableCell>
            <TableCell>{units[measurements[measurement_id]?.default_unit_id]?.name_long}</TableCell>
            <TableCell>
              {loading === measurement_id ? (
                <LinearProgress color="secondary" />
              ) : (
                <UnitSelector
                  selected={unit_id}
                  disabled={!editable || loading}
                  filter={measurementUnitFilter(measurement_id)}
                  onChange={(unitId) => {
                    setLoading(measurement_id);
                    setPreference(measurement_id, unitId).finally(() => setLoading(false));
                  }}
                />
              )}
            </TableCell>
          </TableRow>
        ))}
    </TableBody>
  );
};

export default connect(
  ({
    entities: {
      measurements: { byId },
      units: { byId: units },
    },
  }) => ({ measurements: byId, units }),
  (dispatch, { type }) => ({
    setPreference: (measurement, unit) =>
      dispatch(setUnitPreferenceAction(measurement, unit, type)),
    deletePreference: (measurement) => dispatch(deleteUnitPreferenceAction(measurement, type)),
  })
)(UnitPrefsTable);
