import React, { useState } from 'react';
import { connect } from 'react-redux';
import { Button, LinearProgress } from '@mui/material';
import { DoneOutline, Report } from '@mui/icons-material';
import { dateTime } from '../../../utils/parsing';
import { approveDenySubmissionAction } from '../../../redux/actions/rtemFiles';

const DataFeedbackButtons = ({ dir, status, approve, reject }) => {
  const [{ time, successType }, setSuccess] = useState({});
  const [loading, setLoading] = useState(false);
  if (!dir.files.length) return null;
  if (successType != null)
    return (
      <div className="dir-actions button">
        <span>
          {successType} on {dateTime(time, null, 'at')}, thank you
        </span>
      </div>
    );
  if (loading)
    return (
      <div className="dir-actions button">
        <LinearProgress />
      </div>
    );
  const { SUBMITTED: submitted, APPROVED: approved, REJECTED: rejected } = status;
  if (submitted == null) {
    return null; // can't approve or reject until the user has submitted
  }
  return (
    <div className="dir-actions button">
      <Button
        variant="outlined"
        onClick={() => {
          setLoading(true);
          return reject()
            .then(() => {
              setSuccess({ successType: 'Rejected', time: new Date() });
              setLoading(false);
            })
            .catch(() => {});
        }}
      >
        <Report className="left-icon" color="secondary" />
        Reject Submission
        {rejected &&
          (approved == null || rejected > approved) &&
          ` (Rejected on ${dateTime(rejected, null, 'at')})`}
      </Button>
      <Button
        variant="outlined"
        onClick={() => {
          setLoading(true);
          return approve()
            .then(() => {
              setSuccess({ successType: 'Approved', time: new Date() });
              setLoading(false);
            })
            .catch(() => {});
        }}
      >
        <DoneOutline className="left-icon" color="secondary" />
        Approve Submission
        {approved &&
          (rejected == null || approved > rejected) &&
          ` (Approved on ${dateTime(approved, null, 'at')})`}
      </Button>
    </div>
  );
};

export default connect(
  ({ rtemSubmissions: { statusTimes } }, { dir: { path } }) => ({
    status: statusTimes[path] || {},
  }),
  (dispatch, { dir }) => ({
    approve: () => dispatch(approveDenySubmissionAction(dir.path, 's3file.approve')),
    reject: () => dispatch(approveDenySubmissionAction(dir.path, 's3file.reject')),
  })
)(DataFeedbackButtons);
