import React from 'react';
import { Link } from 'react-router-dom';

const Clients = () => (
  <div>
    <p>
      We currently provide API bindings for Python 3, which you can view on{' '}
      <a href="https://github.com/onboard-data/client-py" target="_blank" rel="noopener noreferrer">
        GitHub
      </a>{' '}
      and{' '}
      <a href="https://pypi.org/project/onboard.client/" target="_blank" rel="noopener noreferrer">
        PyPi
      </a>{' '}
      or install with pip:
    </p>

    <p>
      <span className="code">$ pip install onboard.client</span>
    </p>

    <p>
      We also provide API bindings for R, which you can view on{' '}
      <a href="https://github.com/onboard-data/client-R" target="_blank" rel="noopener noreferrer">
        GitHub
      </a>{' '}
      and{' '}
      <a
        href="https://cran.r-project.org/package=OnboardClient"
        target="_blank"
        rel="noopener noreferrer"
      >
        CRAN
      </a>{' '}
      or install from CRAN with:
    </p>

    <p>
      <span className="code">install.packages(&apos;OnboardClient&apos;)</span>
    </p>

    <p>
      The client works best with an API Key, which you can set up on your{' '}
      <Link to="/account?tab=api">Account Page</Link>
    </p>
  </div>
);

export default Clients;
