import React from 'react';
import { connect } from 'react-redux';

import Selector from '../util/Selector';

/* eslint-disable camelcase */

const unitDisplayName = (long, short, noAbbreviation) => {
  if (long === short || noAbbreviation) return long;
  return `${long} - ${short}`;
};

const UnitSelector = ({
  units,
  selected,
  onChange,
  disabled,
  noAbbreviations,
  showNullOption,
  filter = () => true,
}) => (
  <Selector
    options={units.filter(filter).map(({ id, name_abbr, name_long }) => ({
      id,
      key: `${id}`,
      display: unitDisplayName(name_long, name_abbr, noAbbreviations),
    }))}
    selected={selected ? `${selected}` : ''}
    onChange={({ target: { value } }) => onChange(value)}
    disabled={disabled}
    showNullOption={showNullOption}
  />
);

export default connect(
  ({
    entities: {
      units: { all },
    },
  }) => ({ units: all })
)(UnitSelector);
