import { connect } from 'react-redux';
import GenericLoader, { buildLoader } from '../util/GenericLoader';
import fetchBuildingsActionCreator from '../../redux/actions/buildings';
import {
  fetchChangelogAction,
  fetchChangelogsAction,
} from '../../redux/actions/buildingChangelogs';

export const ChangelogsLoader = connect(null, (dispatch, { buildingId, buildingIds }) => ({
  fetchFunction: () => {
    if (buildingId) dispatch(fetchChangelogAction(buildingId));
    if (buildingIds?.length) dispatch(fetchChangelogsAction(buildingIds));
  },
}))(GenericLoader);

export default buildLoader(fetchBuildingsActionCreator);
