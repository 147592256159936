import { combineReducers } from 'redux';
import { SET_DATA_ALERTS } from '../actions/actiontypes';
import { compareByNumericProp } from '../../utils/comparators';
import { groupListByProperty } from '../../utils/collections';

function alerts(state = [], action) {
  switch (action.type) {
    case SET_DATA_ALERTS: {
      const incoming = action.data.alerts.slice();
      incoming.sort(compareByNumericProp('id', 'desc'));
      return incoming;
    }
    default:
      return state;
  }
}

function actionsByAlert(state = {}, action) {
  switch (action.type) {
    case SET_DATA_ALERTS:
      return groupListByProperty(
        'alert_id',
        action.data.actions,
        compareByNumericProp('id', 'desc')
      );
    default:
      return state;
  }
}

const loaded = (state = false, action) => {
  if (action.type !== SET_DATA_ALERTS) return state;
  return true;
};

const dataAlertsReducer = combineReducers({
  alerts,
  actionsByAlert,
  loaded,
});

export default dataAlertsReducer;
