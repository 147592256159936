import React, { forwardRef } from 'react';

import './page.css';

const PageComp = ({ name = '', start, end, grow, children }, ref) => {
  const style = {};
  if (start != null) style.gridColumnStart = start;
  if (end != null) style.gridColumnEnd = end;
  if (grow != null) style.flexGrow = grow;
  return (
    <div ref={ref} className={`row grid-container-lg grid-content ${name}`} style={style}>
      {children}
    </div>
  );
};
export const Page = forwardRef(PageComp);

export const Row = ({ children, className = '' }) => (
  <div className={`row row-helper ${className}`}>{children}</div>
);

// Fills empty space because it has a high flex-grow number
export const Fill = () => <div className="fill" />;
