import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';

import { Provider } from 'react-redux';
import { createRoot } from 'react-dom/client';
import ReactGA from 'react-ga';
import getGAID from './tracking/ga';
import Entrypoint from './components/app/Entrypoint';
import store from './store';

const googleID = getGAID();
if (googleID) {
  ReactGA.initialize(googleID);
  ReactGA.pageview(window.location.pathname);
}

const container = document.getElementById('root');
if (container) {
  const root = createRoot(container);
  root.render(
    <Provider store={store}>
      <Entrypoint />
    </Provider>
  );
}

function unregisterServiceWorker() {
  try {
    if ('serviceWorker' in navigator) {
      navigator.serviceWorker.ready.then((registration) => {
        registration.unregister();
      });
    }
  } catch (e) {
    // nothing here
  }
}

unregisterServiceWorker();
