import React from 'react';
import { Grid, Typography } from '@mui/material';
import { Link } from 'react-router-dom';

const Equip = ({ base, tag, name }) => <Link to={`${base}/equipment/${tag}`}>{name}</Link>;

const Parent = ({ base }) => (
  <div className="leaf parent">
    <Typography variant="h5" className="name">
      Parent Equipment
    </Typography>
    <Grid item xs={5}>
      <Typography variant="h6">Value:</Typography>
    </Grid>
    <Grid item xs={5} className="static-attr">
      List of parent equipment ids
    </Grid>

    <Grid item xs={2}>
      <Typography variant="h6">Description:</Typography>
    </Grid>
    <Grid item xs={8} className="static-attr">
      <p>
        This attribute models the parent relationship for a given piece of equipment. A parent
        equipment could be used to model “containment” where a{' '}
        <Equip base={base} tag="pump" name="Pump" /> is contained within a{' '}
        <Equip base={base} tag="chilledWaterPlant" name="Chilled Water Plant" />, or a{' '}
        <Equip base={base} tag="ahu" name="Fan" /> is contained within an{' '}
        <Equip base={base} tag="ahu" name="Air Handling Unit" />. A parent equipment could also
        model air flow that is fed from an upstream equipment such as an Air Handling Unit being the
        parent of a Variable Air Volume equipment.
      </p>
    </Grid>
  </div>
);

export default Parent;
