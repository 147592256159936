import { SET_CHANGELOG, SET_BULK_CHANGELOGS } from './actiontypes';
import { getBuildingChangelog, getBuildingChangelogs } from '../../api/Api';

/* eslint-disable import/prefer-default-export */

export const fetchChangelogAction = (buildingId) => (dispatch, getState) => {
  const {
    user: { jwt },
  } = getState();
  if (!buildingId) return Promise.resolve();
  return getBuildingChangelog(buildingId, jwt).then((data) =>
    dispatch({ type: SET_CHANGELOG, buildingId, data })
  );
};

export const fetchChangelogsAction = (buildingIds) => (dispatch, getState) => {
  const {
    user: { jwt },
  } = getState();
  if (!buildingIds?.length) return Promise.resolve();
  return getBuildingChangelogs(buildingIds, jwt).then((data) =>
    dispatch({ type: SET_BULK_CHANGELOGS, buildingIds, data })
  );
};
