import {
  SET_LOGIN_SCREEN,
  SET_BUILDING_IS_LOADING,
  SET_BUILDING_NOT_LOADING,
  SET_UPLOAD_STATE,
  SET_BACK_BUTTON_LABEL,
  SET_LOC_DISPLAY_OPTION,
  SET_RESULTS_VIEW_TYPE,
  SET_EQUIP_DISPLAY_OPTION,
  SET_BACK_BUTTON_LINK,
  RESET_UI,
  SET_FEATURES,
} from '../actions/actiontypes';

// valid states of the upload page
export const UPLOAD_STATES = {
  NEW: 'NEW',
  PROCESSING: 'PROCESSING',
  SUCCESS: 'SUCCESS',
  FAIL: 'FAIL',
};

function updateBuildingLoadingData(buildingsLoadingData, buildingId, action) {
  const buildingsLoadingDataCopy = buildingsLoadingData;
  if (action === 'ADD') {
    buildingsLoadingDataCopy.add(buildingId);
  } else {
    buildingsLoadingDataCopy.delete(buildingId);
  }
  return buildingsLoadingDataCopy;
}

// eslint-disable-next-line no-unused-vars
function updateExpandedEquipPanels(state, action) {
  const newState = { ...state };
  const { equipId, isExpanded } = action;
  if (isExpanded) {
    newState.expandedEquipmentPanels.add(equipId);
  } else {
    newState.expandedEquipmentPanels.delete(equipId);
  }
  return newState;
}

function getFeatureDict(features) {
  const featureDict = {};
  for (let i = 0; i < features.length; i += 1) {
    const { name, enabled } = features[i];
    featureDict[name] = enabled;
  }
  return featureDict;
}

const OB_PALETTE = {
  primary: {
    main: '#e7e7e7',
  },
  secondary: {
    main: '#FF0000',
  },
};

const NYSERDA_PALETTE = {
  primary: {
    main: '#e7e7e7',
  },
  secondary: {
    main: '#3b5998',
  },
};

export const DEFAULT_PALETTE =
  process.env.REACT_APP_WHICH === 'nyserda' ? NYSERDA_PALETTE : OB_PALETTE;

const initialUIState = {
  backButtonLabel: null,
  backButtonLink: null,
  isLoginScreen: false,
  buildingsLoadingData: new Set(),
  buildingsFetchingUploads: new Set(),
  uploadState: UPLOAD_STATES.NEW, // the state of upload page
  byLocDisplayOption: 'flow', // show equip by flow, phys loc, or area served
  byEquipDisplayOption: 'name', // show equip by name or sensor
  resultsViewType: '', // type of results being displayed, helps determine filters
  features: {},
  // This controls UI elements across the app e.g. buttons and links
  palette: DEFAULT_PALETTE,
};

export default function ui(state = initialUIState, action) {
  switch (action.type) {
    case RESET_UI:
      return initialUIState;
    case SET_LOGIN_SCREEN:
      return { ...state, isLoginScreen: action.isLoginScreen };
    case SET_BUILDING_IS_LOADING:
      return {
        ...state,
        buildingsLoadingData: updateBuildingLoadingData(
          state.buildingsLoadingData,
          action.buildingId,
          'ADD'
        ),
      };
    case SET_BUILDING_NOT_LOADING:
      return {
        ...state,
        buildingsLoadingData: updateBuildingLoadingData(
          state.buildingsLoadingData,
          action.buildingId,
          'DELETE'
        ),
      };
    case SET_UPLOAD_STATE:
      return { ...state, uploadState: action.uploadState };
    case SET_BACK_BUTTON_LABEL:
      return { ...state, backButtonLabel: action.label };
    case SET_BACK_BUTTON_LINK:
      return { ...state, backButtonLink: action.link };
    case SET_LOC_DISPLAY_OPTION:
      return { ...state, byLocDisplayOption: action.byLocDisplayOption };
    case SET_EQUIP_DISPLAY_OPTION:
      return { ...state, byEquipDisplayOption: action.byEquipDisplayOption };
    case SET_RESULTS_VIEW_TYPE:
      return { ...state, resultsViewType: action.resultsViewType };
    case SET_FEATURES: {
      const features = getFeatureDict(action.features);
      return { ...state, features };
    }
    default:
      return state;
  }
}
