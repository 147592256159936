import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Button } from '@mui/material';
import { AUTH_LEVELS } from '../../auth/Authorized';

const CenterMenu = ({ userAuthLevel }) => {
  const navigate = useNavigate();
  return (
    <>
      <div>
        <Button color="secondary" onClick={() => navigate('/explore/files')}>
          Files
        </Button>
      </div>
      <div>
        <Button color="secondary" onClick={() => navigate('/submissions')}>
          Submissions
        </Button>
      </div>
      {userAuthLevel <= AUTH_LEVELS.ORG_ADMIN && (
        <>
          <div className="desktop-only-block-display">
            <Button
              color="secondary"
              className="desktop-only-block-display"
              onClick={() => navigate('/actions-audit')}
            >
              Action Log Audit
            </Button>
          </div>
          <div className="desktop-only-block-display">
            <Button
              color="secondary"
              className="desktop-only-block-display"
              onClick={() => navigate('/docs/')}
            >
              API & Docs
            </Button>
          </div>
        </>
      )}
      {userAuthLevel <= AUTH_LEVELS.SUPER_ADMIN && (
        <>
          <div className="desktop-only-block-display">
            <Button
              color="secondary"
              className="desktop-only-block-display"
              onClick={() => navigate('/vendors')}
            >
              Vendors
            </Button>
          </div>
          <div className="desktop-only-block-display">
            <Button
              color="secondary"
              className="desktop-only-block-display"
              onClick={() => navigate('/site-creator')}
            >
              Site Creator
            </Button>
          </div>
        </>
      )}
    </>
  );
};

CenterMenu.defaultProps = {
  userAuthLevel: AUTH_LEVELS.ALL_USER,
};

CenterMenu.propTypes = {
  userAuthLevel: PropTypes.number,
};

export default connect(({ user: { userAuthLevel } }) => ({ userAuthLevel }))(CenterMenu);
