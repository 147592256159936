import React from 'react';
import { Tooltip } from '@mui/material';
import { DoneOutline, CloudUpload, MoreHoriz } from '@mui/icons-material';

const icon = (status) => {
  switch (status) {
    case 'exists':
      return <DoneOutline style={{ color: 'green' }} />;
    case 'processing':
      return <CloudUpload style={{ color: 'yellow' }} />;
    case 'missing':
    default:
      return <MoreHoriz style={{ color: 'red' }} />;
  }
};

const RowStatus = ({ status }) => <Tooltip title={status}>{icon(status)}</Tooltip>;

export default RowStatus;
