import { combineReducers } from 'redux';
import { ADD_MULTIPLE_EQUIP_TYPES } from '../actions/actiontypes';
import { alphabetical } from '../../utils/collections';

function addOrUpdateEquipTypes(state, equipTypes) {
  // return the state with the multiple equipt types added/replaced
  const newState = { ...state };
  for (let i = 0; i < equipTypes.length; i += 1) {
    const et = equipTypes[i];
    newState[et.id] = {
      id: et.id,
      tagName: et.tag_name,
      nameLong: et.name_long,
      nameAbbr: et.name_abbr,
      active: et.active,
      flowOrder: et.flow_order,
      critPoints: et.critical_point_types,
      subTypes: et.sub_types,
      tags: et.tags,
    };
  }
  return newState;
}

function equipTypesById(state = {}, action) {
  switch (action.type) {
    case ADD_MULTIPLE_EQUIP_TYPES:
      return addOrUpdateEquipTypes(state, action.equipTypes);
    default:
      return state;
  }
}

function allEquiptypeIds(state = [], action) {
  switch (action.type) {
    case ADD_MULTIPLE_EQUIP_TYPES:
      return Array.from(new Set(state.concat(action.equipTypes.map((et) => et.id))));
    default:
      return state;
  }
}

const alphabeticalIds = (state = [], action) => {
  switch (action.type) {
    case ADD_MULTIPLE_EQUIP_TYPES:
      return alphabetical('tag_name', action.equipTypes).map((et) => et.id);
    default:
      return state;
  }
};

const idByName = (state = {}, action) => {
  if (action.type !== ADD_MULTIPLE_EQUIP_TYPES) {
    return state;
  }
  return action.equipTypes.reduce((o, et) => {
    // eslint-disable-next-line no-param-reassign
    o[et.tag_name] = et.id;
    return o;
  }, {});
};

const equipTypeReducer = combineReducers({
  byId: equipTypesById,
  idByName,
  allIds: allEquiptypeIds,
  alphabeticalIds,
});

export default equipTypeReducer;
